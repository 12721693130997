/*
//The idea here is to:

//INPUTS
4 types of input:
1. Used to parse a time from the server YYYY-MM-DD HH:mm
	Datetime.createFromServerString('2019-10-10 10:10')

2. when using the same key format that is used to store slots YYYY-MM-DD--HH:mm
	Datetime.createFromStringTimestamp('2019-10-10--10:10')

3. Use when you only have the date YYYY-MM-DD
	Datetime.createFromStringDate('2019-10-10')

4. Add milliseconds since Epoch
	Datetime.createFromEpoch(123789789778)


//OUTPUTS:
there are 3 outputs for the 3 uses on this site I expect more to be added
formatToDateString          YYYY-MM-DD
formatToDatetimeString      YYYY-MM-DD HH:mm


//METHODS
There are many methods.

Some are very similar to the native Date API except that it will return itself so you can chain methods.
	Datetime.createFromServerString('2019-10-10 10:10')
		.addMinutes(30)
		.addHours(1)
		.formatToDateString();

The `clone()` method is helpful when you don't want to mutate an existing Datetime but still want to work from it.
	let startDate = Datetime.createFromServerString('2019-10-10 10:10');
	let currentDate = startDate.clone();
	for (var i = 0; i < 100; i++) currentDate.addMinutes(30);

Some are more computational and might not return a Datetime instance.

If you add any methods, make sure you do NOT expose this.#datetime;
*/



export default class Datetime {
	#datetime;

	static weekdays_short = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	static weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	static months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	static locale = 'en';

	static setLocale(locale = 'en') {
		if(locale==='en') locale = 'en-gb'; // hacky fix so that 24:00 displays as 00:00!
		
		Datetime.locale = locale;

		let date = Datetime.create(2020,1,1);
		Datetime.weekdays = [];
		for (let i = 0; i < 7; i++) {
			Datetime.weekdays.push(date.setDay(i).formatToLocaleString({weekday:'long'}));
		}

		Datetime.weekdays_short = [];
		for (let i = 0; i < 7; i++) {
			Datetime.weekdays_short.push(date.setDay(i).formatToLocaleString({weekday:'short'}));
		}

		Datetime.months = [];
		for (let i = 0; i < 12; i++) {
			Datetime.months.push(date.setMonth(i+1).formatToLocaleString({month: 'long'}));
		}
	}

	constructor(timeSinceEpoch) {
		this.#datetime = new Date(timeSinceEpoch);
	}



	/*
	/// INPUTS
	 */
	static createFromServerString(serverDatetime) {
		let year    = serverDatetime.substr(0,4),
			month   = Number(serverDatetime.substr(5,2)),
			date    = Number(serverDatetime.substr(8,2)),
			hour    = Number(serverDatetime.substr(11,2)),
			minute  = Number(serverDatetime.substr(14,2));

		return new Datetime(new Date(year, month -1,date,hour,minute).getTime());
	}

	static createFromStringTimestamp(timestsamp) {
		return Datetime.createFromServerString(timestsamp.replace('--', ' '));
	}

	static createFromDateString(dateString) {
		let year    = Number(dateString.substr(0,4)),
			month   = Number(dateString.substr(5,2)),
			date    = Number(dateString.substr(8,2));

		return new Datetime(new Date(year, month -1,date, 0,0,0,0).getTime());
	}

	static createFromEpoch(timeSinceEpoch) {
		return new Datetime(timeSinceEpoch.getTime());
	}

	static today() {
		let d = new Date();
		return Datetime.create(d.getFullYear(), d.getMonth(), d.getDate());
	}

	// make sure that the now time is set to the prayer room timezone.
	static now() {
		// hopefully config will be populated at this point. If not we fall back to zero.
		let offset = 0;
		if(window.store) offset = window.store.app.state.config.timezone_offset
		let d = new Date();
		
		return new Datetime(d)
			.addMinutes( d.getTimezoneOffset() )
			.addSeconds( offset );
	}

	static create(year = 0, month = 0, date = 0, hour = 0, minute = 0) {
		let d = new Date(year, month, date, hour, minute);
		return new Datetime(d.getTime());
	}

	clone() {
		return new Datetime(this.#datetime.getTime());
	}

	isValid() {
		return this.#datetime.getTime() !== undefined && typeof this.#datetime.getTime() === 'number';
	}


	/*
	/// BASIC METHODS - typically very similar to native api
	 */

	setMonth(month) {
		if(month===0) console.error('Month is zero and should be at least 1 for January');
		this.#datetime.setMonth(month -1);
		return this;
	}

	setDate(date) {
		this.#datetime.setDate(date);
		return this;
	}

	setDay(day) {
		var currentDay = this.#datetime.getDay();
		var distance = day - currentDay;
		this.#datetime.setDate(this.#datetime.getDate() + distance);

		return this;
	}
	
	setTime(time) {
		this.#datetime.setTime(time);
		return this;
	}

	setClock(hour = undefined, minute = undefined) {

		if(hour !== undefined && minute !== undefined) {
			this.#datetime.setHours(hour, minute);
			return this;
		}

		else if(hour !== undefined) {
			this.#datetime.setHours(hour);
			return this;
		}

		else if(minute !== undefined) this.#datetime.setMinutes(minute);

		return this;
	}

	setHours(hours) {
		this.#datetime.setHours(hours);
		return this;
	}
	
	setUTCHours(hours) {
		this.#datetime.setUTCHours(hours);
		return this;
	}

	setMinutes(minutes) {
		this.#datetime.setMinutes(minutes);
		return this;
	}

	setSeconds(minutes) {
		this.#datetime.setSeconds(minutes);
		return this;
	}

	getYear() {
		return this.#datetime.getFullYear();
	}

	getMonth() {
		return this.#datetime.getMonth() + 1;
	}

	getMonthAsName() {
		return Datetime.months[this.#datetime.getMonth()];
	}

	getDate() {
		return this.#datetime.getDate();
	}

	getOrdinalDate() {
		let n = this.getDate();
		return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
	}

	getDay() {
		return this.#datetime.getDay();
	}

	getDayLongName() {
		return Datetime.weekdays[this.#datetime.getDay()];
	}

	getDayShortName() {
		return Datetime.weekdays_short[this.#datetime.getDay()];
	}

	getTime() {
		return this.#datetime.getTime();
	}

	get value() {
		return this.#datetime.getTime();
	}

	getHours() {
		return this.#datetime.getHours();
	}

	getMinutes() {
		return this.#datetime.getMinutes();
	}



	/*
	/// COMPUTATIONAL / MATH METHODS
	 */
	isWeekend() {
		let day = this.#datetime.getDay();
		return (day === 6) || (day === 0);
	}

	addDays(number) {
		this.#datetime.setDate(this.#datetime.getDate() + number);
		return this;
	}

	addMonths(months) {
		this.setMonth(this.getMonth() + Number(months));
		return this;
	}

	addWeeks(number) {
		this.#datetime.setDate(this.#datetime.getDate() + (7 * number));
		return this;
	}

	addDate(date) {
		this.#datetime.setDate(this.#datetime.getDate() + Number(date));
		return this;
	}

	addHours(hours) {
		this.#datetime.setHours(this.#datetime.getHours() + Number(hours));
		return this;
	}

	addMinutes(minutes) {
		this.#datetime.setMinutes(this.#datetime.getMinutes() + Number(minutes));
		return this;
	}

	addSeconds(seconds) {
		this.#datetime.setSeconds(this.#datetime.getSeconds() + Number(seconds));
		return this;
	}

	diffDays(dateTimeInstance, precise = false) {
		let result = (dateTimeInstance.getTime() - this.getTime()) / (1000 * 60 * 60 * 24);
		if (precise) return result;
		return Math.floor(result);
	}

	diffHours(dateTimeInstance, precise = false) {
		let result = (dateTimeInstance.getTime() - this.getTime()) / (1000 * 60 * 60);
		if (precise) return result;
		return Math.floor(result);
	}

	diffMinutes(dateTimeInstance, precise = false) {
		let result = (dateTimeInstance.getTime() - this.getTime()) / (1000 * 60);
		if (precise) return result;
		return Math.floor(result);
	}

	diffMilliseconds(dateTimeInstance) {
		let result = (dateTimeInstance.getTime() - this.getTime());
		return result;
	}

	toNearestMinute(interval) {
		let roundedMinutes = Math.ceil(this.#datetime.getMinutes() / interval) * interval;
		return this.clone().setMinutes(roundedMinutes).setSeconds(0);
	}

	isBefore(datetime) {
		return this.getTime() < datetime.getTime();
	}

	isBeforeOrSame(datetime) {
		return this.getTime() <= datetime.getTime();
	}

	isAfter(datetime) {
		return this.getTime() > datetime.getTime();
	}

	isAfterOrSame(datetime) {
		return this.getTime() >= datetime.getTime();
	}

	isBetween(datetime1, datetime2) {
		return !this.isBeforeOrSame(datetime1) && !this.isAfterOrSame(datetime2);
	}

	isBetweenOrSame(datetime1, datetime2) {
		return !this.isBefore(datetime1) && !this.isAfter(datetime2);
	}

	isToday() {
		const today = Datetime.now();
		return this.#datetime.getDate() === today.getDate() &&
			this.#datetime.getMonth() === today.getMonth() &&
			this.#datetime.getFullYear() === today.getFullYear()
	}

	startOfWeek() {
		let date = this.#datetime.getDate();
		let dayOfWeek = this.#datetime.getDay();
		this.#datetime.setDate(date - dayOfWeek);

		return this;
	}

	startOfWeekISO() {
		let date = this.#datetime.getDate();
		let dayOfWeek = (this.#datetime.getDay() + 6) % 7;
		this.#datetime.setDate(date - dayOfWeek);

		return this;
	}
	
	startOfDay() {
		this.setHours(0);
		this.setMinutes(0);
		this.setSeconds(0);
		this.#datetime.setMilliseconds(0);
		
		return this;
	}
	
	endOfDay() {
		this.setHours(23);
		this.setMinutes(59);
		this.setSeconds(59);
		this.#datetime.setMilliseconds(59);
		
		return this;
	}


	/*
	/// OUTPUTS
	 */
	formatToLocaleString(options, locale = Datetime.locale) {
		return this.#datetime.toLocaleString(locale,options);
	}

	formatToTimeAMPM() {
		let hour    = this.padd(this.#datetime.getHours()),
			minute  = this.padd(this.#datetime.getMinutes()),
			ampm = hour > 12 ? "PM" : "AM";
		hour = hour % 12 || 12;

		return `${hour}:${minute} ${ampm}`;
	}

	formatToTime24Hour() {
		let hour    = this.padd(this.#datetime.getHours()),
			minute  = this.padd(this.#datetime.getMinutes());

		return `${hour}:${minute}`;
	}

	formatToTimestampString() {
		let year    = this.#datetime.getFullYear(),
			month   = this.padd(this.#datetime.getMonth()+1),
			date    = this.padd(this.#datetime.getDate()),
			hour    = this.padd(this.#datetime.getHours()),
			minute  = this.padd(this.#datetime.getMinutes());

		return `${year}-${month}-${date}--${hour}:${minute}`;
	}
	
	formatToUTCTimestampString() {
		let year    = this.#datetime.getFullYear(),
			month   = this.padd(this.#datetime.getMonth()+1),
			date    = this.padd(this.#datetime.getDate()),
			hour    = this.padd(this.#datetime.getUTCHours()),
			minute  = this.padd(this.#datetime.getMinutes());
		
		return `${year}-${month}-${date}--${hour}:${minute}`;
	}


	formatToDateString() {
		let year    = this.#datetime.getFullYear(),
			month   = this.padd(this.#datetime.getMonth()+1),
			date    = this.padd(this.#datetime.getDate());

		return `${year}-${month}-${date}`;
	}

	formatToDatetimeString() {
		let year    = this.#datetime.getFullYear(),
			month   = this.padd(this.#datetime.getMonth()+1),
			date    = this.padd(this.#datetime.getDate()),
			hour    = this.padd(this.#datetime.getHours()),
			minute  = this.padd(this.#datetime.getMinutes());

		return `${year}-${month}-${date} ${hour}:${minute}`;
	}

	formatToMonthAndYear() {
		let year    = this.#datetime.getFullYear(),
			month   = this.getMonthAsName();

		return `${month} ${year}`;
	}

	formatToShortMonthAndYear() {
		let year    = this.#datetime.getFullYear(),
			month   = this.getMonthAsName().slice(0,3).toUpperCase();

		return `${month} ${year}`;
	}

	formatToNumber() {
		let year    = this.#datetime.getFullYear(),
			month   = this.padd(this.#datetime.getMonth()+1),
			date    = this.padd(this.#datetime.getDate()),
			hour    = this.padd(this.#datetime.getHours()),
			minute  = this.padd(this.#datetime.getMinutes());

		return Number(`${year}${month}${date}${hour}${minute}`);
	}

	formatToLongDateString() {
		let year        = this.#datetime.getFullYear(),
			month       = this.#datetime.getMonth(),
			ordinalDate = this.getOrdinalDate(),
			day         = this.#datetime.getDay();


		return `${Datetime.weekdays[day]} ${ordinalDate} ${Datetime.months[month]} ${year}`;
	}

	formatToShortDateString() {
		let year        = this.#datetime.getFullYear(),
			month       = this.#datetime.getMonth(),
			date    = this.padd(this.#datetime.getDate());


		return `${Datetime.months[month].substring(0, 3)} ${date} ${year}`;
	}


	padd(num) {
		return ("00" + (num)).substr(-2,2);
	}

}
