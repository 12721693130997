<template>
	<modal-shell max="570">
		<template slot="toolbar">
			<router-link to="/" class="sm:!hidden">
				<svg-icon name="chevron-left" class="text-shade-0 block mr-1" size="1.8em"></svg-icon>
			</router-link>

			<div class="flex-none px-1">
				<h5 class="text-h5 m-0 p-o font-semibold font-heading">{{title}}</h5>
				<span class="font-bold text-base opacity-60" v-if="!isEditBooking">{{fromFormat}}</span>
			</div>
		</template>

		<template slot="toolbarRight">
			<template v-if="$stores.user.isAdmin">
				<router-link :to="{name: 'edit-calendar-slots', params:{date: from}}">
					<svg-icon name="gear" class="fill-shade-0 block mr-1" size="1.8em"></svg-icon>
				</router-link>
			</template>

			<router-link to="/" class="xs-hide">
				<svg-icon name="close" class="fill-shade-0 block mr-1" size="1.8em"></svg-icon>
			</router-link>
		</template>


		<div class="bg-shade-0 flex flex-col">
			<transition name="fade" mode="out-in">



				<!-- thank you messages -->
				<template v-if="bookingSuccess">
					<div v-if="bookingSuccess==='deleted'" class="py-6 px-6">
						<h3 class="text-h4 font-semibold font-heading text-center pb-2 text-shade-9" v-t="'modals.book_pray.deleted_success'"></h3>
					</div>

					<div v-else-if="bookingSuccess==='updated' || bookingSuccess==='created'" class="py-6 px-6 text-center">
						<h3 class="text-h4 font-semibold font-heading text-center pb-2 text-shade-8">
							<span v-if="bookingSuccess==='updated'" v-t="'modals.book_pray.updated_success'"></span>
							<span v-if="bookingSuccess==='created'" v-t="'modals.book_pray.created_success'"></span>
						</h3>

						<h5 class="form-label mt-2 pt-2 pb-1" v-t="'modals.book_pray.export_text'"></h5>
						<div class="flex justify-center items-center mt-1 mb-4">
							<a target="_blank" :href="booking.iCalLink" class="btn-grey -xxs" v-t="'modals.book_pray.export_btn'"></a>
						</div>

						<p class="pt-2 mb-1" v-t="'modals.book_pray.donate_text'"></p>
						<a target="_blank" :href="$stores.app.donateLink" class="btn-black -xs w-6/12 mt-4 mb-4" v-t="'modals.book_pray.donate_btn'"></a>
					</div>
				</template>




				<form v-else @submit.prevent="onSubmit">

					<!-- Repeat select view -->
					<template v-if="isRepeatView">
						<div class="px-3 pt-4 text-center">
							<h5 class="form-label pb-1" v-t="'modals.repeat_options.frequency'"></h5>
							<a-radio-list :options="$stores.calendar.frequencyOptions" v-model.number="frequency"></a-radio-list>

							<template v-if="booking.recur_type!==0">
								<h5 class="form-label pb-1" v-t="'modals.repeat_options.duration'"></h5>
								<a-number :min="0" v-model.number="booking.recur_count" :valueDisplay="valueDisplayRecurCount"></a-number>
							</template>
						</div>
					</template>



					<!-- hour select view -->
					<template v-else-if="isDurationView">
						<div class="px-3 pt-4 text-center">
							<h5 class="form-label pb-1" v-t="'modals.change_duration.label'"></h5>
							<a-number :min="$stores.app.state.slotlengthmins_number"
							          :max="60*24"
							          :valueDisplay="valueDisplay"
							          :step="$stores.app.state.slotlengthmins_number"
							          v-model="booking.duration">
							</a-number>
						</div>
					</template>



					<!-- delete booking view -->
					<template v-else-if="isDeleteView">
						<div class="px-3 pt-4 text-center">
							<h5 class="form-label pb-1" v-t="'modals.book_pray.delete_text'"></h5>
							<a-radio-list v-model="deleteSelected" :options="$stores.calendar.deleteOptions"></a-radio-list>
						</div>
					</template>




					<!-- MAIN view -->
					<template v-else>
						<div class="py-2 px-6 max-w-1 mx-auto">



							<!-- Sentence view -->
							<template v-if="!(isEditBooking && booking.isRecurring)">
								<div class="mx-auto text-center space-y-3">

									<h4 class="text-h4 font-medium font-base text-shade-9 mt-4 px-5">
										<span v-text="sentence()"></span>
									</h4>

									<div>
										<button v-if="$stores.user.isLoggedIn || guestProceed"
										     @click="isDurationView=!isDurationView"
										     class="btn-grey -xs"
										     v-t="'modals.change_duration.btn'">
										</button>

										<div class="mb-1"></div>

										<button v-if="(guestProceed || $stores.user.isLoggedIn) && !isEditBooking"
										        @click="isRepeatView=!isRepeatView"
										        class="btn-grey -xs mb-1"
										        v-text="frequencyDurationText">
										</button>
									</div>
								</div>
							</template>



							<!-- Guest view -->
							<template v-if="!guestProceed && !$stores.user.isLoggedIn">
								<p class="text-center pt-2 mb-2 text-base max-w-5 mx-auto" v-t="'modals.book_pray.login_intro'"></p>
								<button @click="$stores.app.redirectStore({name: 'login'})"
								        class="btn-primary -sm w-full mb-2"
								        v-t="'modals.book_pray.login_continue.btn'">
								</button>
								<button @click="$stores.app.redirectStore({name: 'create-login'})"
								        class="btn-primary -sm w-full mb-2"
								        v-t="'modals.book_pray.create_login.btn'">
								</button>

								<div v-if="!$stores.app.state.config.require_login">
									<p class="text-center pt-4 mb-2 text-base" v-t="'modals.book_pray.guest_intro'"></p>
									<button type="button"
									        @click="onGuestProceed"
									        class="btn-grey -sm w-full mb-4"
									        v-t="'modals.book_pray.guest.btn'">
									</button>
								</div>
							</template>


							<!-- Book or edit form -->
							<div class="py-6 space-y-3" v-if="guestProceed || $stores.user.isUserOrAdmin">

								<!-- we need to consider users with accounts that have missing information  -->
								<template v-if="$stores.user.isUserOrAdmin && !bookingForSomeoneElse && !isEditBooking && !guestProceed">
									<a-input v-if="$stores.user.fullName===''"
									         v-model="booking.name" name="name" :label="$t('modals.name')">
									</a-input>

									<a-input v-if="$stores.app.state.config.require_email && ($stores.user.email==='' || $stores.user.email.trim()==='')"
									         v-model="booking.email" name="email" :label="$t('modals.email')" :is-placeholder="true">
									</a-input>

									<a-input v-model="booking.mobile"
									         v-if="$stores.app.state.config.has_mobile"
									         name="mobile"
									         :label="$t('modals.mobile')"
									         :is-placeholder="true">
									</a-input>
								</template>


								<template v-if="guestProceed || bookingForSomeoneElse || isEditBooking">
									<a-input v-model="booking.name" name="name" :label="$t('modals.name')" :is-placeholder="true"></a-input>
									<a-input v-model="booking.email" name="email" :label="$t('modals.email')" :is-placeholder="true"></a-input>
									<a-input v-model="booking.mobile"
									         v-if="$stores.app.state.config.has_mobile"
									         type="tel"
									         name="mobile"
									         :label="$t('modals.mobile')"
									         :is-placeholder="true">
									</a-input>
								</template>


								<div>
									<a-checkbox v-if="$stores.user.isUserOrAdmin && !isEditBooking" class="pb-2"
									            name="is_someone_else" :label="$t('modals.book_pray.is_someone_else')"
									            v-model="bookingForSomeoneElse" @click="bookingForSomeoneElse=!bookingForSomeoneElse">
									</a-checkbox>


									<a-checkbox v-if="showExclusiveCheckbox" v-model="booking.is_exclusive" class="mb-2" name="is_exclusive" :label="$t('modals.book_pray.is_exclusive')"></a-checkbox>

									<template v-if="!$stores.app.state.config.is_anonymous">
										<a-checkbox v-model="booking.is_anonymous" name="is_anonymous" :label="$t('modals.book_pray.is_anonymous')"></a-checkbox>
										<p class="text-base" v-t="'modals.book_pray.is_anonymous_help'"></p>
									</template>
									<template v-else>
										<p class="pt-4 text-base" v-t="'modals.book_pray.is_anonymous_only_help'"></p>
									</template>
								</div>

								<template v-if="!$stores.app.hasGuestAgreedPrivacyPolicyOnce && !isEditBooking && !$stores.user.isUserOrAdmin">
									<a-checkbox v-model="is_guest_agrees_privacy_policy" name="is_guest_agrees_privacy_policy">
										<i18n path="modals.book_pray.privacy_policy.text" class='pt-4 text-base' tag="span">
											<a place="D1" :href='$stores.app.privacyPolicyLink' class="link" v-t="'modals.book_pray.privacy_policy.D1'"></a>
										</i18n>
									</a-checkbox>
								</template>
							</div>
						</div>
					</template>
				</form>
			</transition>
		</div>


		<template slot="footer">

			<!-- repeater / hour / delete select footer -->
			<div v-if="isDurationView || isRepeatView">
				<div @click="isDurationView = isRepeatView = false"
					 class="btn-black -sm w-full"
					 v-t="'modals.book_pray.ok_btn'">
				</div>
			</div>


			<!-- repeater / hour / delete select footer -->
			<div v-else-if="isDeleteView" class="flex space-x-2">
				<div @click="isDeleteView = false" class="btn-grey -sm w-full" v-t="'modals.back_btn'"></div>
				<div class="btn-primary -sm w-full" @click="onSubmitDelete" v-t="'modals.delete_btn'"></div>
			</div>


			<!-- success footer -->
			<div v-else-if="bookingSuccess">
				<div v-if="bookingSuccess==='deleted'">
					<router-link class="btn-primary -sm w-full" :to="{name: 'home'}" v-t="'modals.book_pray.finish_btn'"></router-link>
				</div>
				<div v-else>
					<router-link v-if="$stores.user.isLoggedIn" class="btn-primary -sm w-full mb-1" :to="{name: 'bookings'}" v-t="'modals.book_pray.view_booking_btn'">
					</router-link>
					<router-link class="btn-primary -sm w-full" :to="{name: 'home'}" v-t="'modals.book_pray.finish_btn'">
					</router-link>
				</div>
			</div>


			<!-- Updated footer -->
			<div v-else-if="isEditBooking" class="flex space-x-2">
				<button v-if="booking.isRecurring" class="btn-grey -sm w-full" @click="isDeleteView=true" v-text="$t('modals.delete_btn')+'...'"></button>
				<button v-else class="btn-grey -sm w-full" @click="onSubmitDelete" v-t="'modals.delete_btn'"></button>
				<button class="btn-primary -sm w-full" @click="onSubmitUpdate" v-t="'modals.book_pray.update_btn'"></button>
			</div>


			<!-- Save footer -->
			<div v-else>
				<div v-if="$stores.user.isAdmin || $stores.user.isLoggedIn" @click="onSubmitCreate" class="btn-primary -sm w-full" v-t="'modals.book_pray.book_btn'"></div>
				<div v-else-if="guestProceed" @click="onSubmitCreate" class="btn-primary -sm w-full" v-t="'modals.book_pray.book_guest_btn'"></div>
			</div>
		</template>
	</modal-shell>
</template>

<script>
	import SvgIcon from "../components/SvgIcon";
	import ACheckbox from "../components/inputs/ACheckbox";
	import ModalShell from "./ModalAShell";
	import AInput from "../components/inputs/AInput";
	import ANumber from "../components/inputs/ANumber";
	import {SlotBooking} from "../models/SlotBooking";
	import ARadioList from "../components/inputs/ARadioList";
	import Datetime from "../Datetime";

	export default {
		components: {
			ARadioList,
			ANumber,
			AInput,
			ModalShell,
			ACheckbox,
			SvgIcon,
		},
		name: 'modal-book-slot',
		props: ['from', 'to', 'date', 'id'],
		data() {
			return {
				isDeleteView: false,
				deleteSelected: 0,
				isRepeatView: false,
				isDurationView: false,

				booking: {},
				bookingForSomeoneElse: false,
				bookingSuccess: false,
				guestProceed: false,

				is_guest_agrees_privacy_policy: false
			}
		},


		created() {
			// Persist 'proceed as guest' for session.
			if(!this.$stores.app.state.config.require_login)
				if(window.app_ignore_login_request && !this.$stores.user.isUserOrAdmin)
					this.guestProceed = true;

			this.isEditBooking = !!(this.id && this.date);
			this.title = this.$t('modals.book_pray.title');

			if(this.isEditBooking) {
				// add existing booking details to booking
				let slot = this.$stores.slots.find(this.date);
				let booking = slot.bookings.find((booking)=>booking.id === this.id);

				// We don't want to make the current booking dirty so we fake up a new 'slotBooking' instance
				this.booking = new SlotBooking(
					booking.id,
					booking.start_time,
					booking.end_time,
					booking.is_owner,
					booking.name,
					booking.is_anonymous,
					booking.is_exclusive,
					booking.slot_id,
					booking.recur_type,
					booking.recur_count,
					booking.email,
					booking.mobile
				);

				this.booking.setDuration();
				this.guestProceed = this.booking.is_owner;

				// Set title
				this.title = this.booking.isRecurring
					? this.$t('modals.book_pray.title_edit_series')
					: this.$t('modals.book_pray.title_edit');
			}

			else {
				// set duration
				this.booking = SlotBooking.createEmpty();
				this.booking.duration = this.$stores.app.state.slotlengthmins_number;

				// Set start_date
				this.booking.start_time = this.fromDatetime.formatToDatetimeString();

				// Include mobile if config.has_mobile
				if(this.$stores.app.state.config.has_mobile) this.booking.mobile = "";
			}


			this.$watch('bookingForSomeoneElse', (val)=>{
				if(!this.$stores.user.isUserOrAdmin || this.isEditBooking) return;

				if(val) {
					this.booking.name = "";
					this.booking.email = "";
				} else {
					this.booking.name = this.$stores.user.fullName;
					this.booking.email = this.$stores.user.email;

				}
			}, {immediate: true})
		},


		methods: {
			sentence() {
				let is_plural = this.booking.durationAsHours!==1;
				let hour = is_plural?'hours':'hour';

				if(this.isEditBooking) {
					return this.$t('modals.book_pray.this_text_'+hour,{D1:this.fromTimeFormat, D2:this.booking.durationAsHours})
				}
				else if(this.bookingForSomeoneElse) {
					return this.$t('modals.book_pray.they_text_'+hour,{D1:this.fromTimeFormat, D2:this.booking.durationAsHours})
				}
				else {
					return this.$t('modals.book_pray.i_text_'+hour,{D1:this.fromTimeFormat, D2:this.booking.durationAsHours})
				}
			},

			onGuestProceed() {
				this.guestProceed=true;
				// Persist 'proceed as guest' for session.
				window.app_ignore_login_request=true;
			},

			valueDisplay(val) {
				let time = Datetime.createFromEpoch(this.fromDatetime).addHours(val/60).formatToTime24Hour();

				if(val/60===1) {
					return this.$t('modals.change_duration.duration_hour', {D1:val/60, D2:time});
				}else {
					return this.$t('modals.change_duration.duration_hours', {D1:val/60, D2:time});
				}
			},

			valueDisplayRecurCount() {
				return this.frequencyDurationText;
			},

			formChecks() {
				function isEmail(email) {
					var re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
					return re.test(String(email).toLowerCase());
				}

				if(!this.booking.name || this.booking.name.trim()==='') {
					this.$toast.error({body: this.$t('modals.errors.name_required')});
					return false;
				}

				if(this.bookingForSomeoneElse || this.isEditBooking || (!this.$stores.user.isUserOrAdmin)) {
					if(this.$stores.app.state.config.require_email) {
						if((this.booking.email===""||this.booking.email===undefined)) {
							this.$toast.error({body: this.$t('modals.errors.email_required')});
							return false;
						}
					}

					if(!isEmail(this.booking.email) && !(this.booking.email===""||this.booking.email===undefined)) {
						this.$toast.error({body: this.$t('modals.errors.email_invalid')});
						return false;
					}
				}

				if(this.$stores.app.state.config.has_mobile) {
					if(this.booking.mobile==="") {
						this.$toast.error({body: this.$t('modals.errors.mobile_required')});
						return false;
					}

					if(this.booking.mobile.length<4 || this.booking.mobile.length>13){
						this.$toast.error({body: this.$t('modals.errors.mobile_invalid')});
						return false;
					}
				}

				if(!this.$stores.app.hasGuestAgreedPrivacyPolicyOnce && !this.isEditBooking && !this.$stores.user.isUserOrAdmin) {
					if(!this.is_guest_agrees_privacy_policy) {
						this.$toast.error({body: this.$t('modals.errors.agree_policy')});
						return false;
					}
				}

				return true;
			},

			setExclusivable() {
				let slot = this.$stores.slots.find(this.from);
				if(slot.hasAttributes) if(!this.$stores.app.state.config.is_exclusivable && slot.slotattributes.is_exclusivable && slot.bookings.length === 0)
					this.booking.is_exclusive = true;
			},

			onSubmitCreate() {
				if(!this.formChecks()) return;

				this.setExclusivable();

				this.$stores.slots.bookingCreate(this.booking.toObjectForCreate).then((data)=>{
					this.$stores.app.hasGuestAgreedPrivacyPolicyOnce = true;
					this.booking.id = data[0].id;
					this.bookingSuccess = 'created';
				});
			},

			onSubmitUpdate() {
				if(!this.formChecks()) return;

				this.setExclusivable();

				this.$stores.slots.bookingUpdate(this.booking.toObjectForUpdate).then((data)=>{
					if(data.events.length===0){
						this.booking.id = data.events[0].id;
					} else {
						// TODO: work out if we can /export with recurring bookings if so we need to find slot_id or original recurring slot
						this.booking.id = data.events[0].id;
					}
					this.bookingSuccess = 'updated';
				});
			},

			onSubmitDelete() {
				let deleteType = this.$stores.calendar.deleteOptions[this.deleteSelected][0];

				this.$stores.slots.bookingDelete(this.booking.toObjectForDelete, deleteType).then(()=>{
					this.bookingSuccess = 'deleted';
					this.isDeleteView = false;
				});
			}

		},



		computed: {

			showExclusiveCheckbox() {
				// The order of IF statements is important here!
				if(this.$stores.user.isAdmin) return true;
				if(window.store.app.state.config.is_autoexclusive) return false;

				let slot = this.$stores.slots.findOrCreate(this.from);
				if(slot.isExclusiveWithNoBookings) return true;
				if(slot.hasOneExclusiveBooking && this.isEditBooking) return true;
				if(window.store.app.state.config.is_exclusivable && slot.bookings.length===0) return true;

				return false;
			},
			fromDatetime() {
				let from = Datetime.createFromStringTimestamp(this.from);
				console.log('from',from.formatToDatetimeString());
				return from;
			},
			toDatetime() {
				let to = Datetime.createFromStringTimestamp(this.to);
				return to;
			},

			fromTimeFormat() {
				const timeFormat = this.fromDatetime.formatToLocaleString({
					hour: 'numeric',
					minute: 'numeric',
					hour12: this.$stores.app.state.config.is_12hr
				});

				return timeFormat;
			},

			fromFormat(){
				const dateFormat = this.fromDatetime.formatToLocaleString({
						weekday: 'long',
						day: 'numeric'
					}),
					timeFormat = this.fromDatetime.formatToLocaleString({
						hour: 'numeric',
						minute: 'numeric',
						hour12: this.$stores.app.state.config.is_12hr
					});

				return this.$t('modals.book_pray.sub_title', {D1: dateFormat, D2: timeFormat});
			},

			hourOrHours() {
				if(this.$stores.app.state.config.slotlengthmins ==="30") return 'hours';
				return (this.booking.duration/60) === 1 ? 'hour': 'hours';
			},

			frequency: {
				get() {
					return this.booking.recur_type;
				},
				set(val) {
					if(val === 0) this.booking.recur_count = 0;
					this.booking.recur_type = val;
				}
			},

			frequencyDurationText(){
				return this.$stores.calendar.frequencyDurationText(this.booking.recur_type, this.booking.recur_count);
			}
		},
	}
</script>
