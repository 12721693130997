import {SlotAttribute} from "./SlotAttribute";


export class Slot {
	static createEmptySlot(timestamp) {
		return new Slot(timestamp, SlotAttribute.createEmpty(), [], false);
	}


	constructor(timestamp, slotattributes = {}, bookings = [], isSelected = false) {
		if(!timestamp) console.error('No timestamp was set for slot');

		this.slotattributes = slotattributes;
		this.bookings = bookings;
		this.isSelected = isSelected;
		this.timestamp = timestamp;
	}

	get isEmpty() {
		return this.bookings.length===0 && !(this.slotattributes.id || this.slotattributes.is_unavailable);
	}

	get hasAttributes() {
		return !!this.slotattributes.start_time;
	}

	get hasOneExclusiveBooking() {
		return this.bookings.length===1 && this.bookings[0].is_exclusive;
	}

	get isExclusiveWithNoBookings() {
		return this.slotattributes.is_exclusivable && this.bookings.length===0;
	}

	get unavailableOrHasOneExclusiveBooking() {
		if(this.slotattributes.is_unavailable || this.hasOneExclusiveBooking) return true;
		return false;
	}

	get exclusiveIcon() {
		let result = false;
		if(this.isExclusiveWithNoBookings) result = 'icon-exclusive';
		else if(this.hasOneExclusiveBooking) result = 'icon-exclusive-booked';
		else if (window.store.app.state.config.is_autoexclusive) result = 'icon-exclusive';
		
		let theme = this.slotattributes.theme>0?'text-theme-'+this.slotattributes.theme+'-dark':'text-theme-base-dark';
		
		return `${result} ${theme}`;
	}

	get exclusiveAlt() {
		if(this.isExclusiveWithNoBookings) return 'Prayer room booked exclusively';
		else if(this.hasOneExclusiveBooking) return 'Available for exclusive booking';
		else if (window.store.app.state.config.is_autoexclusive) return 'Available for exclusive booking';
		else return false;

	}


	resetAttributes() {
		this.slotattributes = SlotAttribute.createEmpty();
	}


	removeBookings() {
		this.bookings = [];
	}


	reset() {
		this.resetAttributes();
		this.removeBookings();
		this.isSelected = false;
	}


	findBookingId(id) {
		return this.bookings.findIndex(b => b.id === id);
	}


	findBookingIdOrSlotId(id) {
		return this.bookings.findIndex((booking)=>booking.id===id || booking.slot_id===id);
	}


	removeBookingById(id) {
		let bookingIndex = this.findBookingId(id);
		if(bookingIndex > -1) this.bookings.splice(bookingIndex, 1);
	}


	removeBookingsByIdArray(idArray) {
		this.bookings.filter((booking, index)=>{
			for (var i = 0; i < idArray.length; i++) {
				if(booking.id===idArray[i]) {
					this.bookings.splice(index, 1);
				}
			}
		});
	}
}
