<template>
	<modal-shell max="600">

		<template slot="toolbar">
			<router-link to="/" class="sm:!hidden">
				<svg-icon name="chevron-left" class="text-shade-0 block mr-1" size="1.8em"></svg-icon>
			</router-link>

			<div class="flex-none px-1">
				<h5 class="text-h5 m-0 p-0 top-0 font-semibold font-heading" style="margin-bottom: -5px" v-t="'modals.view_booking.title'"></h5>
				<span class="font-bold text-base opacity-60">{{dateFormat()}}</span>
			</div>
		</template>

		<template slot="toolbarRight">
			<template v-if="$stores.user.isAdmin">
				<router-link :to="{name: 'edit-calendar-slots', params:{date: date}}">
					<svg-icon name="gear" class="fill-shade-0 block mr-1" size="1.8em"></svg-icon>
				</router-link>
			</template>

			<router-link to="/" class="xs-hide">
				<svg-icon name="close" class="fill-shade-0 block mr-1" size="1.8em"></svg-icon>
			</router-link>
		</template>


		<div class="bg-shade-0 h-full flex flex-col p-0">
			<div class="p-2 pb-1 flex-auto">
				<div v-for="booking in slot.bookings" :key="booking.id" class="flex justify-between items-center p-1">
					<span class="form-label" v-text="booking.printName(('('+$t('modals.view_booking.anonymous')+')'))"></span>
					<div v-if="booking.is_owner || $stores.user.isAdmin">
						<template>
							<router-link
								:to="{ name: 'book-edit', params: {from: date, id: booking.id}}"
								class="btn-black -xxs"
								v-text="booking.isRecurring?$t('modals.view_booking.edit_series_btn'):$t('modals.edit_btn')">
							</router-link>
						</template>
					</div>
				</div>
			</div>
		</div>


		<template slot="footer">
			<div v-if="!slot.unavailableOrHasOneExclusiveBooking">
				<button @click="bookNow" class="btn-primary -sm w-full" v-t="'modals.view_booking.booking_btn'"></button>
			</div>
		</template>

	</modal-shell>
</template>

<script>
	import SvgIcon from "../components/SvgIcon";
	import ModalShell from "./ModalAShell";
	import Datetime from "../Datetime";

	export default {
		components: {
			ModalShell,
			SvgIcon,
		},
		name: 'modal-book-view-slot',
		props: ['date'],
		created() {
			this.setup();
		},
		methods: {
			setup() {
				if (!this.date) return;

				this.slot = this.$stores.slots.find(this.date);
			},

			bookNow() {
				this.$router.push({name: 'book-params', params: {from: this.date, to: this.date}})
			},

      dateFormat(){
        const date = Datetime.createFromStringTimestamp(this.date),
            dateFormat = date.formatToLocaleString({
              weekday: 'long',
              day: 'numeric'
            }),
            timeFormat = date.formatToLocaleString({
              hour: 'numeric',
              minute: 'numeric',
              hour12: this.$stores.app.state.config.is_12hr
            });

        return this.$t('modals.book_pray.sub_title',{D1:dateFormat, D2:timeFormat});
      },
		},
		data() {
			return {
				slot: false,
				show: false,
			}
		},

	}
</script>
