<template>
	<div class="absolute inset-0">
		<scroll-grid-2 :options="options"></scroll-grid-2>
	</div>
</template>
<script>
	import ScrollGrid2 from "../components/CalendarV7/ScrollGrid";
	export default {
		name:'test-page',
		components: {ScrollGrid2},

		data() {
			return {
				options: {},
			}
		},

		created() {
			this.$stores.calendar.setNumberOfDaysInView(false);

			this.options = {
				columnNumber: this.$stores.calendar.numberOfDaysInView,

				totalRows: 14,
				totalColumns: 14,

				beforeResize:() => {
					this.$stores.calendar.setNumberOfDaysInView(false);
					this.options.columnNumber = this.$stores.calendar.numberOfDaysInView;
				},
				afterResize:() => {},

				mounted:() => {
					// this.$stores.calendar.stepToToday();
				},

				generateCellKeyName:(cellX, cellY) => cellX +"-"+cellY,

				onXYChange:(object) => {
					console.log(object);
					// let startXDate = this.gridXToDatetime(object.visibleGridValues.startX);
					// window.store.calendar.setCurrentDate(startXDate);
				},
				onCellClickUpNoScroll:(object) => {
					console.log(object);
					alert('clicked on cellX:'+ object.cellX +", CellY:"+ object.cellY);
					// let startXDate = this.gridXToDatetime(object.visibleGridValues.startX);
					// window.store.calendar.setCurrentDate(startXDate);
				}
			};

			setTimeout(()=>{
				// this.options.columnNumber = 7;
				// this.options.totalRows = 15;
				// this.options.totalColumns = 5;
				console.log("changed", this.options);
			}, 3000);
		}
	}
</script>