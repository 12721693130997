<template>
  <div class="h-full flex flex-col bg-shade-0" >

    <div :class="`toolbar justify-between text-shade-0 z-60 ${isMinimised?'-minimize':''}`">
      <div :class="`px-2 lg:px-4 flex ${isMinimised?'items-center h-full':''}`">
        <div :class="`flex w-full ${isMinimised?'items-center h-full':'items-start'}`">
          <div class="md:!hidden ml-1 self-center" @click="$stores.app.showSidebar">
            <svg-icon name="menu" class="fill-shade-0 block mr-1 hide-print" size="2.1em"></svg-icon>
          </div>

          <div v-show="!isMinimised" class="relative truncate sm:mt-2 pt-0 flex-auto mask-header"
               v-if="$stores.app.state.config.name">
          <h1 class="text-h1 font-heading font-semibold truncate pt-2 pb-1 my-0" style="font-size:1.8rem;line-height:1;" v-text="$stores.app.state.config.group.name"></h1>
          <h4 class="text-h3 font-heading font-semibold truncate py-0 my-0 pb-3" style="font-size:1.1rem;line-height:1;" v-text="$stores.app.state.config.name"></h4>

            <p id="date-special" class="pt-0 mb-0 sm:mt-1 capitalize text-shade-0 text-opacity-70 text-base font-semibold" :key="refreshKey" v-html="rangeText"></p>
          </div>

          <h4 v-show="isMinimised" class="text-h3 ml-2 fill-y my-0 py-0 text-shade-0 font-semibold font-heading" :key="refreshKey" v-text="currentDate"></h4>

          <div class="flex-auto"></div>

          <calendar-navigation v-if="this.$stores.calendar.totalDays > 8" v-show="isMinimised" position="top-left" class="xs-hide sm:mr-2"></calendar-navigation>

          <div :class="`flex ${isMinimised?'h-full items-center':'pt-2 mt-1'}`">
            <menu-list class="mx-1" :list-width="120">
	            <button class="flex items-center btn-darken-1 p-0" slot="button">
		            <svg-icon name="globe" class="block fill-shade-0" size="1.9em"></svg-icon>
	            </button>
	            <div slot="list" class="-pad">
		            <li class="text-shade-7 p-8 cursor-pointer text-right" v-for="(text, value) in localeOptions" :key="value"
		                v-text="text" @click="setLocale(value)"></li>
	            </div>
            </menu-list>

            <router-link v-if="!$stores.user.isLoggedIn"
                         :to="{ name: 'login' }"
                         class="hidden lg:block ml-1 btn-darken-1 -xxs"
                         v-t="'log_in.title'"
            >
            </router-link>
            <router-link v-if="!$stores.user.isLoggedIn"
                         :to="{ name: 'create-login' }"
                         class="hidden lg:block btn-darken-1 -xxs"
                         v-t="'create_login.title'">
            </router-link>

            <menu-list v-if="!$stores.user.isLoggedIn" class="mx-1 lg:!hidden" :list-width="120">
              <svg-icon slot="button" name="person" class="block fill-shade-0" size="1.9em"></svg-icon>
              <div slot="list" class="text-right">
                <li>
                  <router-link :to="{name: 'login'}" v-t="'log_in.title'"></router-link>
                </li>
                <li>
                  <router-link :to="{name: 'create-login'}" v-t="'create_login.title'"></router-link>
                </li>
              </div>
            </menu-list>

            <router-link v-if="$stores.user.isLoggedIn"
                         to="/user/bookings"
                         class="hidden xlg:flex ml-1 btn-darken-1 -xxs"
                         v-t="'bookings.title'">
            </router-link>

            <router-link v-if="$stores.user.isAdmin" :to="{ name: 'settings' }" class="btn-darken-1 p-0 flex items-center">
              <svg-icon name="gear" class="block fill-shade-0" size="1.9em"></svg-icon>
            </router-link>


            <menu-list v-if="$stores.user.isLoggedIn" class="ml-1" :list-width="170">
              <button slot="button" class="relative flex items-center btn-darken-1 -xxs h-[2.6em] lg:h-auto !pr-[1.4em] lg:!pr-[2.5em]">
                <span class="text-shade-0 hidden lg:block font-bold !text-sm" v-if="$stores.user.isAdmin" v-t="'topbar.admin'"></span>
                <svg-icon class="absolute right-[0.3em] fill-shade-0 block" name="person-circle" size="1.9em"></svg-icon>
              </button>
              <div slot="list" class="text-right">
	              <li v-if="$stores.user.isAdmin">
		              <router-link to="/stats" v-t="'admin_stats.title'"></router-link>
	              </li>
                <li class="xlg:!hidden">
                  <router-link to="/user/bookings" v-t="'bookings.title'"></router-link>
                </li>
                <li>
                  <router-link to="/user/notifications" v-t="'notifications.title'"></router-link>
                </li>
                <li>
                  <router-link to="/logout" v-t="'logout.title'"></router-link>
                </li>
              </div>
            </menu-list>
          </div>
        </div>
      </div>

      <div v-show="!isMinimised" class="bg-shade-0 flex items-center px-3 lg:px-4 py-2 sm:py-3">
        <h3 class="text-h3 my-0 py-0 text-shade-10 font-semibold font-heading" :key="refreshKey" v-text="currentDate"></h3>
        <div class="flex-auto"></div>
        <calendar-navigation v-if="this.$stores.calendar.totalDays > 8" position="top-left" class="xs-hide"></calendar-navigation>
      </div>
    </div>

    <div v-show="!isMinimised" id="admin-notification-bar" class="pb-2 px-3 lg:px-4 z-50 relative bg-shade-0">
	    <div class="hide-print bg-shade-3 py-2 px-4 flex justify-between items-start cursor-pointer"
	         v-if="$stores.app.showAdminFlashMessage && $stores.user.isAdmin">
		    <div class="py-1 text-shade-10 pr-4 relative xlg:flex text-h5" style="top: 2px;">
			    <div @click="onToggleDetails" class="pr-2">
				    <svg-icon name="chevron-down" size="1em" class="inline-block text-shade-10 xlg:hidden mr-1"></svg-icon>
				    <span class="font-bold" v-t="'topbar.admin_banner_text[0]'"></span>
			    </div>
			    <span :class="`${toggleDetails?'':'hidden xlg:inline'}`" v-t="'topbar.admin_banner_text[1]'"></span>
		    </div>

		    <button class="btn-black -xs" @click="$stores.app.showAdminFlashMessage=false"
		            v-t="'topbar.admin_banner_btn'"></button>
	    </div>
    </div>

    <div class="z-10 flex-auto h-full flex flex-col">
      <div class="h-full flex justify-center">
        <div class="relative h-full w-full">
          <calendar v-if="isReady"></calendar>
        </div>

        <a-loader></a-loader>

        <calendar-navigation position="bottom" class="sm:hidden"></calendar-navigation>
      </div>
    </div>
  </div>

</template>


<script>
import SvgIcon from "../components/SvgIcon";
import MenuList from "../components/MenuList";
import CalendarNavigation from "../components/CalendarV7/CalendarNavigation";
import UserRepo from "../stores/UserRepo";
import Datetime from "../Datetime";
import Calendar from "../components/CalendarV7/Calendar";
import {locales, setLocale} from "../../locales";
import ALoader from "@/components/CalendarV7/Aloader";

export default {
  components: {
    ALoader,
    Calendar,
    CalendarNavigation,
    MenuList,
    SvgIcon,
  },

  data() {
    return {
      toggleDetails: false,
      toggleMinimizeToolbar: false,
      isReady: false,
      userRepo: UserRepo.user,
      localeOptions: locales.locale_options,
      refreshKey: 0,
      rangeText: '',
      currentDate: ''
    }
  },

  updated() {
    console.log('calendarView Updated');
  },

  mounted() {
    this.$stores.calendar.setNumberOfDaysInView();
    this.isReady = true;

    this.$stores.calendar.event.$on('locale-changed', ()=> {
      this.refreshKey++
      this.setRangeText();
      this.oldCurrentDateKey = undefined;
      this.setCurrentDate();
    });

    this.setRangeText();
    this.setCurrentDate();
  },

  watch: {
    '$stores.calendar.yLimited'(val) {
      this.toggleMinimizeToolbar = Math.abs(val) > 2;
    },

    '$stores.calendar._currentDateString'() {
      this.setCurrentDate();
    }
  },

  methods: {
    setLocale(locale) {
      window.localStorage.setItem('userLocale', locale);
      setLocale(locale);
    },

    onToggleDetails() {
      this.toggleDetails = !this.toggleDetails;
    },

    // Cannot be computed property as computed properties will not update on locale change
    // cannot be method as this updates on every re-render which ends up being every frame
    setRangeText() {
      let start = Datetime.createFromServerString(this.$stores.app.state.config.start_time)
          .formatToLocaleString({year: 'numeric', month: 'long', day: 'numeric'});
      let end = Datetime.createFromServerString(this.$stores.app.state.config.end_time)
          .formatToLocaleString({year: 'numeric', month: 'long', day: 'numeric'});

      let until = ' <b>-</b> ';

      this.rangeText = `${start} ${until} ${end} ${this.timezone}`;
    },

    setCurrentDate() {
      if(!this.$stores.calendar._currentDate) return;

      let month = this.$stores.calendar._currentDate.getMonth(),
          year = this.$stores.calendar._currentDate.getYear(),
          currentDateKey = month+'-'+year;

      if(currentDateKey === this.oldCurrentDateKey) return;

      this.oldCurrentDateKey = currentDateKey;
      this.currentDate = this.$stores.calendar.currentDate.clone().formatToLocaleString({month: 'long', year: 'numeric'});
    }

  },

  computed: {
    isMinimised() {
      return this.toggleMinimizeToolbar && (this.$breakpoint.val === 'xs' || this.$breakpoint.val === 'sm');
    },

    timezone() {
      let timezoneName = this.$stores.app.state.config.timezone_desc;
      let negPos = this.$stores.app.state.config.timezone_offset >= 0 ? '+' : '-';
      let hours = this.$stores.app.state.config.timezone_offset / 60 / 60;
      let hour = Math.floor(hours);
      let minutes = (hours - hour) * 60;
      let offset = `${hour}:${window.padd(minutes)}`;

      return `(GMT ${negPos}${offset}) ${timezoneName}`;
    }
  }
}
</script>