<template>
	<div class="container-inner py-6 mb-6">
		<div class="flex flex-col sm:flex-row -mx-2">


			<div class="sm:w-7/12 px-2 order-2 sm:order-1">
				<div class="bg-primary border border-primary rounded shadow-1 overflow-hidden" style="border-radius: .3rem">
					<div class="flex flex-col">
						<div class="p-4">
							<h4 class="text-h3 m-0 p-0 font-semibold font-heading text-shade-0" v-t="'admin_stats.top_users.title'"></h4>
						</div>
						<div class="bg-shade-0 relative">
							<table class="m-0 p-0">
								<tr v-for="(data, index) in top_users" :key="data.name+index">
									<td class="bg-primary-lighter-2 text-right">
										<span :class="`bold ${top_users_loading_state===2?'':'opacity-0'}`" v-text="index+1"></span>
									</td>
									<td class="w-full" v-text="data.name"></td>
								</tr>
							</table>
							<div v-show="top_users_loading_state===1" class="absolute loader-on-white" style="top: 50%;left: 50%;margin-left: -2rem;width: 6rem;height: 6rem;margin-top: -3rem;"></div>
						</div>
					</div>
				</div>
			</div>


			<div class="sm:w-5/12 px-2 order-1 sm:order-2">
				<div v-if="eventNotEnded()" class="bg-primary border border-primary rounded shadow-1 overflow-hidden mb-6" style="border-radius: .3rem">
					<div class="flex flex-col">
						<div class="p-4 text-shade-0">
							<div class="relative">
								<h1 :class="`text-h1 m-0 p-0 font-semibold font-heading my-2 ${booked_count_loading_state===2?'':'opacity-0'}`" style="font-size: 3.3rem;" v-text="bookedPercentage"></h1>
								<h4 :class="`text-h3 m-0 p-0 font-semibold mb-1 ${booked_count_loading_state===2?'':'opacity-0'}`" style="font-size: 1.4rem;" v-t="'admin_stats.slots_booked.title'"></h4>
								<div v-show="booked_count_loading_state===1" class="absolute loader-on-primary" style="top:-.4rem;width:6rem;height:6rem;"></div>
							</div>
						</div>
						<div class="bg-shade-0 p-4">
							<select class="form-select w-full" :disabled="booked_count_loading_state===1" v-model="booked_count_value" @change="getBookCountValue">
								<option v-for="option in options" :value="option.value" v-text="option.text" :key="option.text"></option>
							</select>
						</div>
					</div>
				</div>
			</div>


		</div>
	</div>
</template>


<script>
import Page from '@/stores/Page';
import Datetime from "@/Datetime";

export default {
	components: {},
	name: 'stats',
	data(){
		return {
			page: Page,
			title: '',
			top_users: [],
			top_users_loading_state: 0,
			booked_count_value: 7,
			booked_count: 0,
			booked_count_loading_state: 0,
			options: []
		}
	},

	created() {
		this.page.title=this.$t('admin_stats.title');
		this.top_users = Array.from({length: 10}, () => ({name: ''}));
		this.firstLoadData();
		this.setOptions();
	},

    computed: {
        bookedPercentage() {
            let val = this.booked_count > 0 ? Math.round(this.booked_count) : 0 ;

            return `${val}%`;
        }
    },


	methods: {
		eventNotEnded() {
			return Datetime.now().isBefore(Datetime.createFromServerString(window.store.app.state.config.end_time));
		},

		setOptions() {
			let upcoming = Datetime.now().isBefore(Datetime.createFromServerString(window.store.app.state.config.start_time));
			let daysLeft = Datetime.now().diffDays(Datetime.createFromServerString(window.store.app.state.config.end_time));
			let totalEventDays = Datetime.createFromServerString(window.store.app.state.config.start_time).diffDays(Datetime.createFromServerString(window.store.app.state.config.end_time));
			let eventHasMoreThan7Days = totalEventDays > 7
			let eventHasMoreThan7DaysRemaining = daysLeft > 7
			let eventHasMoreThan14Days = totalEventDays > 14
			let eventHasMoreThan14DaysRemaining = daysLeft > 14

			let options = [
				{ value: 7, text: this.optionText(7) },
			];

			if(upcoming) {
				if(eventHasMoreThan7Days) options.push({ value: 14, text: this.optionText(14) });
				if(eventHasMoreThan14Days) options.push({ value: 28, text: this.optionText(28) });
			}else {
				if(eventHasMoreThan7DaysRemaining) options.push({ value: 14, text: this.optionText(14) });
				if(eventHasMoreThan14DaysRemaining) options.push({ value: 28, text: this.optionText(28) });
			}

			this.options = options;
		},
		optionText(value) {
			if(Datetime.now().isBefore(Datetime.createFromServerString(window.store.app.state.config.start_time))) {
				return this.$t('admin_stats.slots_booked.options_before', {D1: value})
			}else {
				return this.$t('admin_stats.slots_booked.options_after', {D1: value})
			}
		},
		firstLoadData() {
			this.top_users_loading_state = 1;
			this.booked_count_loading_state = 1;

			window.apiFetcher.get('/statistics')
				.then((response)=>{
					console.log(response);
					this.top_users_loading_state = 2;
					this.booked_count_loading_state = 2;

					this.booked_count = response.availability_percentage;
					this.top_users = response.top_users;
				})
				.catch(()=>{
					this.top_users_loading_state = -1;
					this.booked_count_loading_state = -1;

					setTimeout(()=>{
						this.firstLoadData();
					}, 6000);
					this.$toast.warn({
						body: this.$t('error_message.fail_try_again', {D1:'6 seconds'}),
						timeout: 5500
					});
				})
		},

		getBookCountValue(){
			this.booked_count_loading_state = 1;
			window.apiFetcher.get('/statistics?days='+this.booked_count_value)
				.then((response)=>{
					this.booked_count_loading_state = 2;
					this.booked_count = response.availability_percentage;
				})
				.catch(()=>{
					this.booked_count_loading_state = -1;

					setTimeout(()=>{
						this.getBookCountValue();
					}, 3000);

					this.$toast.warn({
						body: this.$t('error_message.fail_try_again', {D1:'6 seconds.'}),
						timeout: 5500
					});
				})
		}
	}
}
</script>
