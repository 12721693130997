<template>
	<div :class="containerClass()" :style="containerStyle()">
		<!--// For debug use. Adds timestamp to each slot -->
<!--		<span class="text-base">{{slot.timestamp}}</span>-->
<!--		<span class="text-base">{{keyName}}</span>-->

		<template v-if="slot">
			<!-- unavailable slot -->
			<template v-if="slot.slotattributes.is_unavailable"></template>

			<!-- Bookings -->
			<template v-else>

				<div v-for="booking in slot.bookings" :key="booking.id" :class="`cal_slot ${booking.is_owner && '-owner'}`">
					<span v-text="isAnonymous(booking) ? 'Anonymous' : booking.name"></span>
				</div>

				<!-- Exclusive slot visual identification - using img with svg src as this is the most performant -->
				<span v-if="slot.exclusiveIcon" :title="slot.exclusiveAlt"
				      :class="`cal_slot-exclusive-state ${slot.exclusiveIcon}`"
				></span>
			</template>
		</template>


		<template v-else>
				<span title="Available for exclusive booking"
				      :class="`cal_slot-exclusive-state icon-exclusive text-theme-base-dark`"></span>
		</template>

	</div>
</template>

<script>
	export default {
		name: 'slot-cell',
		props:{
			gridWidth:{},
			x:{},
			y:{},
			keyName: {required: true, type: String}
		},

		computed: {
			slot() {
				if(this.keyName.indexOf('is_autoexclusive') > -1) return false;

				return this.$stores.slots.findOrCreate(this.keyName);
			}
		},


		methods: {
			isAnonymous(booking) {
				return booking.is_anonymous || this.$stores.app.state.config.is_anonymous;
			},

			containerClass() {
				if(!this.slot) return `cal_slot-cell`;

				let theme = this.slot.slotattributes.theme;
				let unavailable = this.slot.slotattributes.is_unavailable;
				return `cal_slot-cell ${theme>0?'theme-cell-'+theme:''} ${unavailable?'theme-unavailable':''}`;
			},

			containerStyle() {
				return `transform:translateY(${this.y*80}px);left:${((this.x*100)/this.$stores.calendar.numberOfDaysInView)}%;width:${100/this.$stores.calendar.numberOfDaysInView}%;`;
			},
		}
	}
</script>
