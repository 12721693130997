var toasts = [
	// {id: 0, body: 'hello world'}
];

export default {
	defaultTimeout: 8000,
	defaultPosition: 'bottom-0',

	_total: 0,

	toasts: toasts,

	error(object){
		object.type = 'error';
		this._add(object);
	},

	warn(object){
		object.type = 'warn';
		this._add(object);
	},

	good(object){
		object.type = 'good';
		this._add(object);
	},

	info(object){
		this._add(object);
	},

	remove(toast) {
		let index = this.toasts.findIndex((c) => c.id === toast.id);
		this.toasts.splice(index, 1);
	},

	removeWithDelay(toast, delay) {
		setTimeout(()=>this.remove(toast), delay)
	},

	_add(object) {
		this._total++;
		object.id = this._total;
		this.toasts.unshift(object);
	}

}
