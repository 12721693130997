<template>
	<div ref="scrollGridContainer" class="absolute inset-0 overflow-hidden">
		<div ref="scrollGrid" class="yoyo h-full w-full" style="transform: translate(-0%,0);will-change: transform;">

			<slot ref="grid-background" name="grid-background"></slot>

			<!-- slots will render in here! -->
			<div ref="gridContainer" class="absolute inset-0"></div>
		</div>
	</div>
</template>

<script>
	import {clearRequestInterval, requestInterval} from "../../Utils";
	import {PeripheralInteraction} from "../../PeripheralInteraction";
	import Vue from 'vue';
	import {NonBlockingQueue} from "@/NonBlockingQueue";

	export const FillerCellComponent = {
		name: 'filler-cell-component',
		render(createElement) {
			return createElement('div', {class:'absolute relative', style:this.containerStyle()}, [createElement('h5', {class:'m-0', }, this.keyName)]);
		},
		props:{ x:{}, y:{}, keyName: {required: true, type: String} },
		methods: {
			containerStyle() {
				return `height:80px;color:white;box-sizing: border-box;border:1px solid red;background:grey;-webkit-perspective: 1000; -webkit-backface-visibility: hidden;outline: 1px solid transparent;position:absolute;transform:translateY(${this.y*80}px);left:${((this.x*100)/this.$parent.internalOptions.columnNumber)}%;width:${100/this.$parent.internalOptions.columnNumber}%;will-change: transform;`;
			},
		}
	};

	let optionsDefaultValue = {
		columnNumber: 7,
		totalRows: 24,
		totalColumns: 7,
		beforeResize:() => {},
		afterResize:() => {},
		cellComponent: FillerCellComponent,
		generateCellKeyName:(cellX, cellY) => cellX +"-"+cellY,
		created:() => {},
		mounted:() => {},
		onXYChange:() => {},
		onCellClickUpNoScroll:() => {},

		boundaryDetectionOffset: 2,
		boundaryDetectionArea: 7,
		boundaryCreated:() => {},
		boundaryRight:() => {},
		boundaryLeft:() => {}
	};

	export default {
		name: 'scroll-grid-2',

		updated() {
		},

		props: {
			options: {
				required: true,
				type: Object,
				defaultValue: optionsDefaultValue
			}
		},

		data() {
			return {
				internalOptions: optionsDefaultValue,
				ComponentClass: null,

				elKeyStore: {},
				visibleGridValuesWithKeyName: {},
				xPercent: 0,

				// required to be undefined as setting to 0 causes PeripheralInteraction to not run callback on first load
				slaveScrollX: undefined,
				slaveScrollY: undefined,

				slaveScrollYLimited:0,
				scrollGridWidth: 0,
				scrollGridHeight: 0,
				lastScrollGridWidth: 0,
				lastScrollGridHeight: 0,
				peripheralInteraction: undefined,
				visibleGridValues: {startX:undefined, endX:undefined, totalX:undefined, startY:undefined, endY:undefined, totalY:undefined, total:undefined},
			}
		},

		watch: {
			options: {
				immediate: true,
				deep:true,
				handler() {
					this.internalOptions = Object.assign({},optionsDefaultValue, this.options);
					this.ComponentClass = Vue.extend(this.internalOptions.cellComponent);

					this.resize();
				}
			}
		},

		created() {
			this.internalOptions.created();
		},

		mounted() {
			window.scrollGrid = this;

			this.msFPS = 1000 / 60;
			this.peripheralInteraction = new PeripheralInteraction(
				this.$refs.scrollGrid,
				(x, y) => {
					if (x !== this.slaveScrollX || y !== this.slaveScrollY) {
						this.slaveScrollX = x;
						this.slaveScrollY = y;
						this.slaveScrollYLimited = y > 0 ? 0 : y;
						this.drawMove();
						this.setVisibleGridXY(x, y);

						this.internalOptions.onXYChange({
							x:x, y:y,
							yLimited: this.slaveScrollYLimited,
							xPercent: this.xPercent,
							visibleGridValues: this.visibleGridValues
						});
					}
				},
				(evt) => {
					let gridReference = this.mouseXYtogridReference(evt);

					this.internalOptions.onCellClickUpNoScroll({
						...gridReference,
						keyName: this.gridReferenceToKeyName(gridReference.cellX,gridReference.cellY),
						visibleGridValues: this.visibleGridValues
					});
				});

			window.peripheralInteraction = this.peripheralInteraction;
			this.resize();

			// setup resize listener
			this.resizeDetectionInterval = requestInterval(()=> {
				this.resizeDetection();
				this.peripheralInteraction.calculateMove();
			}, 11);

			this.unmountWatchVisibleGridValues = this.$watch('visibleGridValues', (newVal,oldVal)=>{
				if(newVal.total !== oldVal.total) {
					this.renderCellsFromVisibleGridValues();
					this.boundaryDetection();
				}
			});

			// todo: remove "this.$stores.calendar" dependency
			this.$stores.calendar.event.$on('force-redraw', ()=>{
				console.log("force-redraw");
				this.renderCellsFromVisibleGridValues();
			});

			// todo: remove "this.$stores.calendar" dependency
			this.$stores.calendar.event.$on('force-resize', ()=>{
				this.resize();
			});


			this.internalOptions.mounted();

		},


		beforeDestroy() {
			console.log("DESTROY DESTROY DESTROY DESTROY DESTROY DESTROY DESTROY DESTROY");
			this.unmountWatchVisibleGridValues();
			clearRequestInterval(this.resizeDetectionInterval);
		},


		methods: {
			setToTop() {
				// snap to nearest date
				this.$stores.calendar.stepToCurrent();
				//snap to top
				window.peripheralInteraction.setYExact(0);

				// update everything
				this.slaveScrollYLimited = 0;
				this.drawMove();
				this.setVisibleGridXY(this.slaveScrollX,0);
			},

			resizeDetection() {
				let viewPortWrapper = this.$refs.scrollGrid;
				if(!viewPortWrapper) return;

				if (
					this.lastScrollGridWidth !== this.$refs.scrollGrid.clientWidth
					|| this.lastScrollGridHeight !== this.$refs.scrollGrid.clientHeight
				) {
					this.resize();
					this.lastScrollGridWidth = this.$refs.scrollGrid.clientWidth;
					this.lastScrollGridHeight = this.$refs.scrollGrid.clientHeight;
				}
			},


			resize: function () {
				// update peripheralInteraction. After that we redraw the grid.

				if (!this.$refs.scrollGrid) return;

				this.internalOptions.beforeResize();

				this.scrollGridWidth = this.$refs.scrollGrid.clientWidth;
				this.scrollGridHeight = this.$refs.scrollGrid.clientHeight;

				// This fixes a bug where the position would slip when resizing.
					// basically we are debouncing so the value only gets reset after 1 second of inactivity
				if(this.oldVisibleGridValues) {
					clearTimeout(this.resizeDebounce);
					this.resizeDebounce = setTimeout(()=>{
						this.oldVisibleGridValues = false;
					}, 1000);
				}
				else this.oldVisibleGridValues = JSON.parse(JSON.stringify(this.visibleGridValues));


				let calHeight = this.scrollGridHeight;
				let scrollContentHeight = -((this.internalOptions.totalRows * 80) - calHeight);
				if (this.$breakpoint.val === 'xs') scrollContentHeight += -50;


				let scrollContentWidth = this.scrollGridWidth * (this.internalOptions.totalColumns / this.internalOptions.columnNumber);
				scrollContentWidth = -(scrollContentWidth - this.scrollGridWidth);
				scrollContentWidth = scrollContentWidth > 0 ? -0 : scrollContentWidth;



				if (this.$refs.scrollGrid) {
					this.peripheralInteraction.updateDimensions(this.scrollGridWidth, scrollContentHeight, scrollContentWidth);

					// If the breakpoint changes then we want to make sure the column position stays in the correct place.
						// NOTE: I feel like I should be using 'columnNumber' instead but it didn't work.
					if (this.oldVisibleGridValues.startX && (this.$breakpoint.val !== this.oldBreakpoint)) {
						this.peripheralInteraction.stepTo(this.oldVisibleGridValues.startX / this.internalOptions.columnNumber);
						this.oldbreakpoint = this.$breakpoint.val;
					}
				}

				this.internalOptions.afterResize();

				//Redraw all slots! - Doesn't really need to run unless numberOfDaysInView has changed so we are being a bit lazy here.
				this.setVisibleGridXY(this.peripheralInteraction.xx, this.peripheralInteraction.yy);
				this.renderCellsFromVisibleGridValues();
			},



			// This gets the visible grid values and flattens it into an object of Visible timestamps.
			// It also filters out timestamps so that they wont get rendered which increases performance.
			renderCellsFromVisibleGridValues() {
				let vgv = this.visibleGridValues;
				if(!this.$refs.scrollGrid) return;

				let visibleGridValuesWithKeyName = {};
				let keyNameIndex = [];

				for (let x = vgv.startX; x < vgv.endX; x++){
					for (let y = vgv.startY; y < vgv.endY; y++){

						let keyName = this.internalOptions.generateCellKeyName(x, y);

						if(keyName) {
							keyNameIndex.push(keyName);
							visibleGridValuesWithKeyName[keyName] = {keyName:keyName, x:x, y:y};
						}
					}
				}

				this.visibleGridValuesWithKeyName = visibleGridValuesWithKeyName;
				this.updateGridSlotElements(visibleGridValuesWithKeyName, keyNameIndex);
			},




			// This updates the cells/slots that are visible by removing
			// slots that are not visible or adding slots that are visible.
			updateGridSlotElements(visibleGridValuesWithKeyName, keyNameIndex) {

				// using NonBlockingQueue to prevent stuttering on slow machines!
				new NonBlockingQueue(()=> {
					// Sort out what to delete, what to leave and what to add.
					let toDelete = [];
					let alreadyExists = [];
					Object.keys(this.elKeyStore).map((key) => {
						if (keyNameIndex.indexOf(key) > -1) alreadyExists.push(key);
						else toDelete.push(key);
					});

					// Delete what is not visible
					toDelete.forEach((key) => {
						this.elKeyStore[key].el.parentNode.removeChild(this.elKeyStore[key].el);
						delete this.elKeyStore[key];
					});


					// Add any cells/slots that DON'T already exist
					let toAdd = [];
					Object.keys(visibleGridValuesWithKeyName).map((key) => {
						if (alreadyExists.indexOf(key) < 0) toAdd.push(key);
					});

					toAdd.forEach((key) => {
						// create vue instance and add it to the grid
						let instance = new this.ComponentClass({
							parent: this,
							propsData: visibleGridValuesWithKeyName[key]
						});
						instance.$mount();
						this.$refs.gridContainer.appendChild(instance.$el);

						this.elKeyStore[key] = {el: instance.$el};
					});
				});
			},



			drawMove() {
				// TODO:IMPROVEMENT get direction started and fix to that axis to prevent unneeded slot rendering.
				// TODO:IMPROVEMENT detect if is scrolling or clicking with micro movement. clicking is a bit insensitive.

				this.xPercent = -(-(this.slaveScrollX)/(this.scrollGridWidth) * 100);
				this.$refs.scrollGrid.style.transform = 'translate(' + this.xPercent + '%, ' + this.slaveScrollYLimited + 'px)';
			},


			setVisibleGridXY(x, y) {
				this.visibleGridValues = this.setVisibleGrid(x,y, undefined,undefined);
			},

			// Converts X Y values from PeripheralInteraction into visible grid XY values.
			// Use 'undefined' as a parameter value where you want the default setting to apply.
			setVisibleGrid(x,y, isPrecise=false, isYLimited=true) {
				// limit y to not go beyond 0 and over.
				if(isYLimited) y = y > 0?0:y;
				let startX = Math.abs((x/(this.scrollGridWidth/this.internalOptions.columnNumber)));
				let endX = Math.abs( (x/(this.scrollGridWidth/this.internalOptions.columnNumber)) ) + (this.internalOptions.columnNumber-1);

				let xCount = this.internalOptions.totalColumns;
				if(!isPrecise) startX = Math.floor(startX);
				if(!isPrecise) endX = Math.floor(endX);
				endX = endX+2; // extra
				endX = endX>xCount?xCount:endX;
				let totalX = endX-startX;

				let yCount = this.internalOptions.totalRows;
				let startY = Math.abs(y / (yCount*80/yCount));
				let endY = Math.abs( (y / (yCount*80/yCount)) ) + Math.floor(this.scrollGridHeight/80);
				if(!isPrecise) startY = Math.floor(startY);
				if(!isPrecise) endY = Math.floor(endY) + 1;
				endY = endY+1; //extra
				endY = endY>yCount?yCount:endY;

				let totalY = endY-startY;

				return {startX:startX, endX:endX, totalX:totalX, startY:startY, endY:endY, totalY:totalY, total:startX+startY};
			},


			mouseXYtogridReference(evt) {
				let viewPort = this.$refs.scrollGridContainer.getBoundingClientRect();

				let pageX = evt.pageX;
				let pageY = evt.pageY;


				if(evt.changedTouches && evt.changedTouches[0]) {
					pageX = evt.changedTouches[0].pageX;
					pageY = evt.changedTouches[0].pageY;
				}

				let viewPortX = (pageX - viewPort.left);
				let viewPortY = (pageY - viewPort.top);

				let mouseViewPortGridReference = this.setVisibleGrid(viewPortX,viewPortY, true, false);
				let elementGridReference = this.setVisibleGrid(this.slaveScrollX,this.slaveScrollYLimited, true, undefined);

				let cellX = Math.floor(elementGridReference.startX + mouseViewPortGridReference.startX);
				let cellY = Math.floor(elementGridReference.startY + mouseViewPortGridReference.startY);

				return {cellX, cellY};
			},

			gridReferenceToKeyName(x, y) {
				return Object.keys(this.visibleGridValuesWithKeyName)
					.find((key)=> this.visibleGridValuesWithKeyName[key].x===x && this.visibleGridValuesWithKeyName[key].y===y);
			},


			boundaryDetection() {
				let offset = this.internalOptions.boundaryDetectionOffset;
				let boundaryArea = this.internalOptions.boundaryDetectionArea; //7
				let vgv = this.visibleGridValues;
				let currentX = vgv.startX;



				if ((this.visibleBoundaryStartX === undefined || this.visibleBoundaryEndX === undefined)) {
					this.visibleBoundaryStartX = vgv.startX - (boundaryArea);
					this.visibleBoundaryEndX = vgv.endX - 1;

					this.internalOptions.boundaryCreated({
						startCellX: vgv.startX + (-boundaryArea),
						endCellX: vgv.startX + (boundaryArea * 2 - 1),
					});

					return;
				}

				if (currentX >= this.visibleBoundaryEndX - 1) {
					console.log('OUT OF BOUNDARY RIGHT');
					this.visibleBoundaryStartX = this.visibleBoundaryEndX - (boundaryArea * offset);
					this.visibleBoundaryEndX = this.visibleBoundaryEndX + (boundaryArea * offset);

					this.internalOptions.boundaryRight({
						startCellX: vgv.startX + (boundaryArea + 1),
						boundaryArea: boundaryArea
					});

					return;
				}

				if (currentX <= this.visibleBoundaryStartX) {
					console.log('OUT OF BOUNDARY LEFT!!!');
					this.visibleBoundaryEndX = this.visibleBoundaryStartX + (boundaryArea * offset);
					this.visibleBoundaryStartX = this.visibleBoundaryStartX - (boundaryArea * offset);

					this.internalOptions.boundaryLeft({
						startCellX: vgv.startX,
						boundaryArea: boundaryArea
					});

					return;
				}
			}
		}
	}
</script>