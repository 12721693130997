<template>
	<div>

		<div class="transition-all bg-shade-10 text-shade-0 flex items-center mx-auto font-normal sm:max-w-2 rounded shadow-2 w-11/12 sm:w-8/12 md:w-7/12 lg:w-5/12 m-4 mb-15 md:mb-6">
			<div v-if="icon" :class="`flex self-stretch items-center justify-center px-4 py-2 ${bg} rounded-l`">
				<svg-icon fill="currentColor" :name="icon" size="1.8rem"></svg-icon>
			</div>

			<div class="px-4 py-2 flex-auto" v-html="toast.body"></div>
			<button class="btn-white -xxs mr-2" v-if="!toast.actions" @click="toastStore.remove(toast)">
				<span class="shade-7">Close</span>
			</button>
			<div class="flex" v-else :key="action.label" v-for="action in toast.actions">
				<button class="btn-white -xxs" @click="action.handle(toast, toastStore)"
				        v-text="action.label"></button>
			</div>
		</div>
	</div>
</template>

<script>
	import Toast from './Toast'
	import SvgIcon from "../SvgIcon";

	export default {
		name: 'toast-item',
		components: {SvgIcon},
		data() {
			return {
				toastStore: Toast
			}
		},
		props: {
			toast:Object
		},
		mounted() {
			console.log(this.toast);
			this.setTimeout();
		},
		methods:{
			setTimeout() {
				if(this.toast.timeout === false) return false;
				if(this.toast.timeout === undefined) return this.toastStore.removeWithDelay(this.toast, this.toastStore.defaultTimeout);
				if(Number.isInteger(this.toast.timeout)) return this.toastStore.removeWithDelay(this.toast, this.toast.timeout);

				throw new Error('Toast timeout must be either false, undefined or a number/ms')
			}
		},
		computed:{
			bg() {
				switch (this.toast.type) {
					case 'error': return 'bg-bad';
					case 'warn' : return 'bg-warning';
					case 'good' : return 'bg-good';
					default     : return 'bg-info';
				}
			},

      icon() {
        switch (this.toast.type) {
          case 'error': return 'close';
          case 'warn' : return 'warn-outline';
          case 'good' : return 'check';
          default     : return false;
        }
      }
		}
	}
</script>

<style>

</style>
