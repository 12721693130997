import {UserBooking} from "../models/UserBooking";
import Datetime from "../Datetime";


export default class UserBookingRepo {

	static state = {
		loadingState: 0,
		userBookings: [],
		userBookingsByDate: [],
	};



	static getFromServer() {
		UserBookingRepo.state.loadingState = 1;
		return new Promise((resolve, reject)=>{
			window.apiFetcher.get('/events', {owned: '1'})
				.then((data)=>{
					UserBookingRepo.state.loadingState = 2;
					console.time('UserBookingRepo');
					UserBookingRepo.state.userBookings = UserBookingRepo.parseUserBookings(data);
					console.log(UserBookingRepo.state.userBookings);
					console.timeEnd('UserBookingRepo');
					resolve(data);
				}).catch((error)=>{
				UserBookingRepo.state.loadingState = -1;
				reject(error);
			});
		});
	}



	static parseUserBookings(object) {
		let r = [];
		let now = Datetime.now();

		if(object) object = object.sort(function(a,b){
			return new Date(a.start_time) - new Date(b.start_time);
		});


		if(object) object.forEach((booking) => {
			let start = Datetime.createFromServerString(booking.start_time);
			let end = Datetime.createFromServerString(booking.end_time);

			if(end.isBefore(now)) return;

			let totalDays = Math.ceil(start.diffDays(end, true));

			for (let i = 0; i < totalDays; i++) {
				let current = Datetime.createFromServerString(booking.start_time).addDays(i);
				let formatted = UserBookingRepo.getFormattedTimes(start, end, current);

				r.push(new UserBooking(
					formatted.isAllDay,
					current.formatToDateString(),
					formatted.startTimeDiff,
					formatted.endTimeDiff,
					booking
				));
			}
		});

		UserBookingRepo.state.userBookingsByDate = r.reduce((acc, curr) => {
			let find = acc[curr.date];
			if (!find) acc[curr.date] = [];
			acc[curr.date].push(curr);
			return acc;
		}, {});

		return r;
	}



	static getFormattedTimes(start, end, current) {
		let startTimeDiff;
		let endTimeDiff;
		let isAllDay;

		if(window.store.app.state.config.is_12hr) {

			// set start time
			startTimeDiff = '00:00 AM';
			if (!(start.diffDays(current) >= 1)) startTimeDiff = current.formatToTimeAMPM();

			// set end time
			endTimeDiff = '12:00 AM';
			if (!(current.diffDays(end) >= 1)) endTimeDiff = end.formatToTimeAMPM();

			// set boolean if is all day
			isAllDay = startTimeDiff === '00:00 AM' && endTimeDiff === '12:00 AM';
		}else{
			// set start time
			startTimeDiff = '00:00';
			if (start.diffDays(current) < 1) startTimeDiff = current.formatToTime24Hour();

			// set end time
			endTimeDiff = '24:00';
			if (current.diffDays(end) < 1) endTimeDiff = end.formatToTime24Hour();

			// set boolean if is all day
			isAllDay = startTimeDiff === '00:00' && endTimeDiff === '24:00';
		}

		return {
			startTimeDiff,
			endTimeDiff,
			isAllDay
		};
	}
}
