import Vue from 'vue'
import VueRouter from 'vue-router';
import Page from '/src/views/Page'
import PageContact from '/src/views/PageContact'
import PageUserCreateLogin from '/src/views/PageUserCreateLogin'
import PageUserLogin from '/src/views/PageUserLogin'
import PageUserBookings from '/src/views/PageUserBookings'
import PageSettings from '/src/views/PageSettings'
import Error404 from '/src/views/Error404'

import ModalRouter from '/src/views/ModalARouter'
import ModalBookSlot from '/src/views/ModalBookSlot'
import ModalSlotAttributes from '/src/views/ModalSlotAttributes'
import ModalBookViewSlot from '/src/views/ModalBookViewSlot'

import Middleware from '/src/middleware'

import CalendarViewv7 from '/src/views/CalendarView'
import PageUserNotifications from "/src/views/PageUserNotifications";
import UserRepo from "/src/stores/UserRepo";
import TestPage from "/src/views/TestPage";
import PageStats from "/src/views/PageStats";
import Styles from "@/views/Styles";

Vue.use(VueRouter);

let routes = [
	{
		path: '/',
		name: 'home',
		meta:{ title: ''},
		component: CalendarViewv7
	},
	
	/*
	*
	* MODAL RELATED ROUTES
	*
	* */
	{
		path: '/',
		name: 'modal',
		components: {
			default: CalendarViewv7,
			'modal': ModalRouter,
		},
		children: [
			{
				path: 'slot/:date',
				name: 'view-slot',
				meta:{ title: 'View slot'},
				props: true,
				component: ModalBookViewSlot,
				beforeEnter: (to, from, next) => {
					Middleware.beforeViewSlot(to, from, next);
				}
			},
			{
				path: 'book/:from/:to',
				name: 'book-params',
				meta:{ title: 'Book slot'},
				props: true,
				component: ModalBookSlot,
				beforeEnter: (to, from, next) => {
					Middleware.beforeEnterBooking(to,from, next);
				},
			},
			{
				path: 'slot/:date/edit/:id',
				name: 'book-edit',
				meta:{ title: 'Edit booking'},
				props: true,
				component: ModalBookSlot,
				beforeEnter: (to, from, next) => {
					Middleware.beforeEnterEditBooking(to,from, next);
				}
			},
			{
				path: 'edit-calendar/slots/:date',
				name: 'edit-calendar-slots',
				meta:{ title: 'Edit calendar slots'},
				props: true,
				component: ModalSlotAttributes,
				beforeEnter: (to, from, next) => {
					Middleware.beforeEnterEditCalendarSlot(to,from, next);
				}
			},
		]
	},
	
	{
		path: '/',
		name: 'pages',
		component: Page,
		children: [
			{
				path: 'contact-organiser',
				name: 'contact-organiser',
				meta:{ title: 'Contact organiser'},
				component: PageContact
			},
			
			
			/*
			*
			* USER RELATED ROUTES
			*
			* */
			{
				path: '/login/create',
				name: 'create-login',
				meta:{ title: 'Create login'},
				component: PageUserCreateLogin
			},
			{
				path: '/login',
				name: 'login',
				meta:{ title: 'Login'},
				component: PageUserLogin
			},
			{
				path: '/logout',
				name: 'logout',
				meta:{ title: 'Logout'},
				component: Page,
				beforeEnter: (to, from, next) => {
					UserRepo.logout().then(()=>{
						next({name: 'home'});
						window.$toast.good({
							body: 'Successfully logged out'
						});
					});
				}
			},
			{
				path: '/user/notifications',
				name: 'notifications',
				meta:{ title: 'User notifications'},
				component: PageUserNotifications,
				beforeEnter: (to, from, next) => {
					Middleware.checkUser(to,from, next);
				}
			},
			{
				path: '/user/bookings',
				name: 'bookings',
				meta:{ title: 'My bookings'},
				component: PageUserBookings,
				beforeEnter: (to, from, next) => {
					Middleware.checkUser(to,from, next);
				}
			},
			
			
			/*
			*
			* ADMIN ROUTES
			*
			* */
			{
				path: '/settings',
				name: 'settings',
				meta:{ title: 'Settings'},
				component: PageSettings,
				beforeEnter: (to, from, next) => {
					Middleware.checkAdmin(to,from, next);
				}
			},
			{
				path: '/stats',
				name: 'stats',
				meta:{ title: 'Stats'},
				props: true,
				component: PageStats,
				beforeEnter: (to, from, next) => {
					Middleware.checkAdmin(to,from, next);
				}
			},
		]
	},
	{
		path: '*',
		component: Error404
	}
];

if(process.env.NODE_ENV!=='production') {
	routes.push({
		path: '/grid-test-page',
		name: 'grid-test-page',
		component: TestPage
	});
	
	const pagesIndex = routes.findIndex(rootRoute => rootRoute.name === 'pages');
	routes[pagesIndex].children.push({
		path: 'styles',
		name: 'styles',
		meta:{ title: 'Styles'},
		component: Styles
	});
}

export default new VueRouter({
	base: process.env.NODE_ENV==='production'
		? '/signup/' + window.signupAppPayload.config.uniqid
		: '/signup/beta',
	
	mode: 'history',
	routes: routes
})
