export class PrintCalendar {
	static autoLoad() {
		return new PrintCalendar();
	}

	constructor() {
		this.listen();
	}

	listen() {
		window.addEventListener("beforeprint", this.beforePrint.bind(this));
		window.addEventListener("afterprint", this.afterPrint.bind(this));

		var mediaQueryList = window.matchMedia('print');
		mediaQueryList.addListener((mql) => {
			if(mql.matches) {
				this.beforePrint();
			}
		});
	}

	getElements() {
		let mainEl = document.querySelector('.main');
		let toolbarEl = document.querySelector('.toolbar');
		let firstCalXAxisItemEl = document.querySelector('.cal_x-axis-item');
		let blurEl = document.querySelector('.blur');

		return {mainEl, toolbarEl, firstCalXAxisItemEl, blurEl};
	}

	beforePrint() {
		let {mainEl, toolbarEl, firstCalXAxisItemEl, blurEl} = this.getElements();
		if(!mainEl || !toolbarEl || !firstCalXAxisItemEl || !blurEl) return;

		let totalHeight =
			toolbarEl.clientHeight
			+ (80 * window.store.app.state.slots_per_day)
			+ firstCalXAxisItemEl.clientHeight;

		window.scrollGrid.setToTop();

		blurEl.style.height = totalHeight + 'px';
		mainEl.style.height = totalHeight + 'px';
	}

	afterPrint() {
		let {mainEl, toolbarEl, firstCalXAxisItemEl, blurEl} = this.getElements();
		if(!mainEl || !toolbarEl || !firstCalXAxisItemEl || !blurEl) return;

		blurEl.style.height = '';
		mainEl.style.height = '';
	}
}