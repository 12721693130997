import {User} from "../models/User";
import App from './App';

const guest = User.createGuest();

export default class UserRepo {

	static user = guest;

	static form = {
		loginLoaded: 0,
		loggoutLoaded: 0,
		notificationLoaded: 0,
		notificationSaveLoaded: 0,
		signupLoaded: 0,
		signupErrors: {email:''},
		formError: false,
		is_admin: false,
		is_logged_in: false
	};

	//only called when requesting /payload from api
	static updateUserFromRequest(response) {
		if(!response.config.user) return;

		let r = response.config;
		UserRepo.user = new User(r.user.email, r.user.firstname, r.user.lastname, r.is_admin, true);
	}

	static logout(reload = true){
		return new Promise((resolve, reject)=>{
			UserRepo.form.loggoutLoaded = 1;
			window.apiFetcher.post('/logout', {})
				.then(() => {
					UserRepo.user.reset();
					UserRepo.form.loggoutLoaded = 2;
					if (reload) location.reload();
					resolve();
				})
				.catch(() => {
					UserRepo.form.loggoutLoaded = -1;
					reject();
				})
		});
	}

	static login(email, password) {
		UserRepo.form.loginLoaded = 1;
		return new Promise((resolve, reject)=>{
			window.apiFetcher.post('/login', {
				email,
				password
			})
				.then((r) => {
					UserRepo.form.loginLoaded = 2;
					UserRepo.user = new User(r.user.email, r.user.firstname, r.user.lastname, r.is_admin, true);
					if (r.config) App.state.config = Object.assign({}, App.state.config, r.config);
					resolve(r);
					
					// remove all slots - needed as slots may belong to user that need updating.
					window.store.slots.empty();
					window.store.app.getFromServer();
				})
				.catch((error, e) => {
					UserRepo.form.loginLoaded = -1;
					UserRepo.form.is_logged_in = false;
					reject(error, e);
				})
		});
	}

	static signup(newUser) {
		UserRepo.form.signupLoaded = 1;

		return new Promise((resolve, reject)=>{


			if(newUser.email !== newUser.email_confirm) {
				UserRepo.form.signupErrors = {email:`${newUser.email} and ${newUser.email_confirm} do not match`};
				UserRepo.form.signupLoaded = -1;
			}

			else window.apiFetcher.post('/createlogin', newUser)
				.then((r) => {
					UserRepo.form.signupErrors = {};
					UserRepo.form.signupLoaded = 2;
					UserRepo.user = new User(r.user.email, r.user.firstname, r.user.lastname, r.is_admin, true);
					resolve(r);
				})
				.catch((error, e) => {
					UserRepo.form.signupErrors = {};
					UserRepo.form.signupLoaded = -1;
					UserRepo.user.is_logged_in = false;

					// Required as api does not say what field the error is related to.
					let foundNotUnique = error.response.data.errors
						.find((a) => a.message === 'The email entered is already in use.');
					if(foundNotUnique) UserRepo.form.signupErrors.email = 'The email entered is already in use.';

					reject(error, e);
				})
		});
	}

	static getNotifications() {
		return new Promise((resolve, reject) => {
			window.apiFetcher.get('/notifications')
				.then((r) => {
					UserRepo.form.notificationLoaded = 2;
					resolve(r);
				})
				.catch((error, e) => {
					UserRepo.form.notificationLoaded = -1;
					reject(error, e);
				})
		});
	}

	static setNotifications(notificationsToSave) {
		return new Promise((resolve, reject) => {
			window.apiFetcher.post('/notifications', notificationsToSave)
				.then((r) => {
					UserRepo.form.notificationSaveLoaded = 2;
					resolve(r);
				})
				.catch((error, e) => {
					UserRepo.form.notificationSaveLoaded = -1;
					reject(error, e);
				})
		});
	}
}
