<template>
	<div id="app" class="h-full">
		<div :class="shellContainer">
			<div :class="sidebar">
				<div class="sidebar-content">
					<side-bar></side-bar>
				</div>
				<div class="sidebar-background" @click="$stores.app.hideSidebar"></div>
			</div>
			<main :class="main">
				<router-view/>
			</main>
		</div>

		<inline-modal-controller></inline-modal-controller>

		<transition name="fade" mode="out-in" @enter="$stores.app.blurAll" @leave="$stores.app.focusAll">
			<router-view name="modal" />
		</transition>

		<toast-controller></toast-controller>
	</div>
</template>

<script>
	import SideBar from "./components/SideBar";
	import ToastController from "./components/Toast/ToastController";
	import InlineModalController from "./components/InlineModal/InlineModalController";

	export default {
		components: {
			InlineModalController,
			ToastController,
			SideBar
		},
		computed:{
			shellContainer(){ return {
				'h-full blur': true,
				'-active': this.$stores.app.isSiteBlurred
			}},
			sidebar() {return {
				'sidebar': true,
				'-show': this.$stores.app.isShowingSidebar
			}},
			main(){ return {
				'main h-full': true,
				'-active': this.$stores.app.isMainBlurred && this.$stores.app.isShowingSidebar
			}}
		}
	}
</script>
