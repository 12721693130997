import Vue from "vue";
import Datetime from "../Datetime";
import {NonBlockingQueue} from "../NonBlockingQueue";
import {i18n} from "../../locales";

let Calendar = {
	event: new Vue(),
	windowOfDates: [],
	todayDate: Datetime.now().formatToDateString(),
	numberOfDaysInView: 0,
	numberOfDaysInViewXAxis: 0,
	totalDays: 0,
	originalTotalDays: 0,
	highlightedDate: undefined,
	yLimited: 0,
	startX: 0,
	xPercent: 0,
	viewPortWrapperWidth: 80,
	
	_currentDate: undefined,
	_currentDateString: undefined,

	get columnWidthPercent() {
		return 100/Calendar.numberOfDaysInView;
	},

	get frequencyOptions() {
		return i18n.t('modals.repeat_options.frequencies')
			.map((item,index)=>[index, item]);
	},

	get deleteOptions(){
		return [
			['this',         i18n.t('modals.book_pray.delete_options.this')],
			['following',   i18n.t('modals.book_pray.delete_options.following')],
			['all',         i18n.t('modals.book_pray.delete_options.all')]
		];
	},
	
	get deleteSlotAttributeOptions(){
		return [
			['false',       i18n.t('modals.book_pray.delete_options_attributes.this')],
			['following',   i18n.t('modals.book_pray.delete_options_attributes.following')],
			['all',         i18n.t('modals.book_pray.delete_options_attributes.all')]
		];
	},


	// opted to put this here rather than duplicating code for slotattributes and booking
	frequencyDurationText(recur_type, recur_count) {
		let count = recur_count;
		if(recur_type===0) return i18n.t('modals.repeat_options.label');

		if(recur_type===1) {
			switch(count) {
				case(0): return i18n.t('modals.repeat_options.repeat_daily');
				case(1): return i18n.t('modals.repeat_options.repeat_day', {D1:count});
				default: return i18n.t('modals.repeat_options.repeat_days', {D1:count});
			}
		}

		else if(recur_type===2) {
			switch(count) {
				case(0): return i18n.t('modals.repeat_options.repeat_weekly');
				case(1): return i18n.t('modals.repeat_options.repeat_week', {D1:count});
				default: return i18n.t('modals.repeat_options.repeat_weeks', {D1:count});
			}
		}
	},



	get isWeekendArray() {
		let scopeStart = Datetime.createFromEpoch(Calendar.scopeStartDate);
		let numberOfDaysInWeek = window.store.app.state.is_8_day?8:7;

		let array = [];
		array.push( scopeStart.isWeekend() );
		for (let i = 1; i < numberOfDaysInWeek; i++) {
			array.push( scopeStart.addDate(1).isWeekend() );
		}
		
		return array;
	},



	get isTodayWithinEventRange() {
		let start = Datetime.createFromServerString(window.store.app.state.config.start_time);
		let end = Datetime.createFromServerString(window.store.app.state.config.end_time);
		return Datetime.now().isBetweenOrSame(start, end);
	},



	get currentDate() {
		if(!Calendar._currentDate) return undefined;
		return Calendar._currentDate.clone();
	},



	setCurrentDate(datetime) {
		let datetimeString = datetime.formatToDateString();
		// important as otherwise UI that relies on this will re-render every frame.
		if(Calendar._currentDate===undefined || datetimeString !== Calendar._currentDate.formatToDateString()) {
			console.log('setCurrentDate', datetime, datetimeString);
			Calendar._currentDate = datetime;
			Calendar._currentDateString = datetimeString;
		}
	},



	attemptToBook(timestamp) {
		console.log(timestamp);
		Calendar.highlightedDate = Datetime.createFromStringTimestamp(timestamp);
		console.log('timestamp',timestamp, ' | out of bounds', Calendar.isDatetimeOutOfOriginalDate(Calendar.highlightedDate));
		if(Calendar.isDatetimeOutOfOriginalDate(Calendar.highlightedDate)) return;

		let slot = window.store.slots.findOrCreate(timestamp);
		console.log('found'. slot);

		if(slot) {
			slot.isSelected = true;
			Calendar.bookSlotRoute(slot);
			setTimeout(()=>slot.isSelected = false, 1000);
		}
		else console.log("couldn't find slot", slot);
	},


	bookSlotRoute(slot) {
		let from = Calendar.highlightedDate.formatToTimestampString();
		let to = Calendar.highlightedDate.formatToTimestampString();

		console.log('BookSlotRoute', slot);

		// allow admins to edit slots which have attribute of is_unavailable.
		if(window.store.user.isAdmin && slot.slotattributes.is_unavailable) {
			window.store.router.push({name: 'edit-calendar-slots', params:{date: from}});
		}

		if(!slot.slotattributes.is_unavailable) {
			// if the slot already has a booking then we want to show bookingsListView.
			if(slot.bookings.length>0) {
				window.store.router.push({
					name: 'view-slot',
					params: {date: from}
				});
			}
			//else simply show booking form
			else {
				window.store.router.push({
					name: 'book-params',
					params: {from, to}
				});
			}
		}
	},



	setNumberOfDaysInView(forceResize = true) {
		let numberOfDaysInView = 7;
		let numberOfDaysInViewXAxis = 7;
		let triggerNumberOfDaysInView = false;

		if (window.$breakpoint.val === 'xs') numberOfDaysInView = 1;
		else if (window.store.app.state.is_8_day) numberOfDaysInView = 8;

		if (window.store.app.state.is_8_day) numberOfDaysInViewXAxis = 8;

		if(Calendar.numberOfDaysInView !== numberOfDaysInView) triggerNumberOfDaysInView = true;

		Calendar.numberOfDaysInView = numberOfDaysInView;
		Calendar.numberOfDaysInViewXAxis = numberOfDaysInViewXAxis;

		if(triggerNumberOfDaysInView) Calendar.event.$emit('numberOfDaysInView-changed');

		if(forceResize) Calendar.event.$emit('force-resize')
	},




	setCalendarDateScope() {
		Calendar.originalStartDate = Datetime.createFromServerString(window.store.app.state.config.start_time);
		Calendar.originalEndDate = Datetime.createFromServerString(window.store.app.state.config.end_time);
		Calendar.originalTotalDays = Math.round(Calendar.originalStartDate.clone().startOfDay().diffDays(Calendar.originalEndDate.clone().endOfDay(), true));
		
		Calendar.scopeStartDate = Datetime.createFromEpoch(Calendar.originalStartDate).setHours(0).setMinutes(0);
		Calendar.scopeEndDate = Datetime.createFromEpoch(Calendar.originalEndDate).setHours(23).setMinutes(59);
		
		if (Calendar.originalTotalDays > 8) {
			Calendar.scopeStartDate = Calendar.scopeStartDate.startOfWeek();
			Calendar.scopeEndDate = Calendar.scopeEndDate.startOfWeek().addDate(6);
		}else if (Calendar.originalTotalDays < 8) {
			Calendar.scopeEndDate = Calendar.scopeStartDate.clone().endOfDay().addDate(6);
		}
		
		Calendar.totalDays = Math.round(Calendar.scopeStartDate.diffDays(Calendar.scopeEndDate, true));


		// set out of bounds slots
		window.store.slots.slotsBetween(
			Calendar.scopeStartDate.formatToDatetimeString(),
			Calendar.originalStartDate.formatToDatetimeString(),
			(slot)=>{
				slot.slotattributes.is_unavailable = true;
			}
		);
		window.store.slots.slotsBetween(
			Calendar.originalEndDate.formatToDatetimeString(),
			Calendar.scopeEndDate.formatToDatetimeString(),
			(slot)=>{
				slot.slotattributes.is_unavailable = true;
			}
		);
		console.log('-----',Calendar.scopeEndDate.formatToDatetimeString(), Calendar.originalEndDate.formatToDatetimeString());
		new NonBlockingQueue(()=>window.store.calendar.event.$emit('force-redraw'));
	},
	

	isDatetimeOutOfOriginalDate(datetime) {
		return !datetime.isBetweenOrSame(Calendar.originalStartDate, Calendar.originalEndDate);
	},


	scrollToDate(date, smooth) {
		date = Datetime.createFromDateString(date);

		// scroll to limits if today is out of bounds
		if(date.isAfter(Calendar.scopeEndDate)) {
			date = Calendar.scopeEndDate;
		}

		if(date.isBefore(Calendar.scopeStartDate)) {
			window.peripheralInteraction.stepTo(0, smooth);
			return;
		}

		// find scroll location
		let findIndexFromDate = -1;
		if(window.$breakpoint.val === 'xs') {
			findIndexFromDate = Calendar.scopeStartDate.diffDays(date, true);
			findIndexFromDate = Math.round(findIndexFromDate);
		}
		else if(Calendar.totalDays <= 8) {
			findIndexFromDate = 0;
		}
		else if(window.$breakpoint.val !== 'xs') {
			let startOfWeek = Datetime.createFromEpoch(Calendar.scopeStartDate).startOfWeek();
			let diff = startOfWeek.diffDays(date.clone().startOfWeek(), true);

			findIndexFromDate = Math.round( Math.round(diff) / Calendar.numberOfDaysInView );
		}
		
		// scroll to location
		if (findIndexFromDate > -1) {
			window.peripheralInteraction.stepTo(findIndexFromDate, smooth);
		}
	},
	
	stepToStart(smooth = false) {
		window.peripheralInteraction.stepTo(0, smooth);
	},

	stepToToday(smooth = false) {
		Calendar.scrollToDate(
			Calendar.todayDate,
			smooth
		);
	},

	stepToCurrent(smooth = false) {
		Calendar.scrollToDate(
			Calendar.currentDate.formatToDateString(),
			smooth
		);
	},



	stepBack() {
		window.peripheralInteraction.stepBack();
	},



	stepForward() {
		window.peripheralInteraction.stepForward();
	},
};

export default Calendar;