<template>
	<div class="flex items-start space-x-1">
		<input class="form-checkbox" :id="name" :name="name" v-on="listeners" v-bind="$attrs" :checked="value" type="checkbox" />

		<label v-if="label" class="form-label" :for="name" v-html="label"></label>
		<label v-else class="form-label" :for="name">
			<slot></slot>
		</label>
	</div>
</template>

<script>
	export default {
		name: 'a-checkbox',
		inheritAttrs: false,
		props: {
			label:{
				type: String
			},
			name: {
				type: String,
				required: true
			},
			value: {
				type: Boolean
			}
		},
		computed: {
			listeners() {
			  const that = this;
				return {
					...this.$listeners,
          input (event) {
            that.$emit('input', event.target.checked);
          },
          change(event) {
            that.$emit('input', event.target.checked);
          }
				};
			}
		}
	}
</script>

