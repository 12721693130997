import App from "./App";
import Page from "./Page";
import UserRepo from './UserRepo';
import Slots from './Slots';
import Calendar from "./Calendar";
import UserBookingRepo from "./UserBookingRepo";

let store = {
	app: App,
	page: Page,
	slots: Slots,
	calendar: Calendar,
	userRepo: UserRepo,
	get user() { return UserRepo.user; },
	userBookingsRepo: UserBookingRepo
};

window.store = store;

export default store;
