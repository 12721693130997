<template>
	<div class="menu inline-block">
		<div v-if="show" @click="toggle" style="outline: 0;" class="no-outline fixed inset-0"></div>
		<div @click="toggle" class="cursor-pointer">
			<slot name="button"></slot>
		</div>
		<!--<transition name="fade-down">-->
			<div v-if="show" class="relative">
				<ul class="menu_list shadow-1 px-0 py-1 m-0 absolute top-0 right-0 bg-shade-0 rounded border border-shade-2 block"
				    @click="toggle" :style="`width:${listWidth}px`">
					<slot name="list"></slot>
				</ul>
			</div>
		<!--</transition>-->
	</div>
</template>
<script>
	export default {
		name: 'menu-list',
		props: {
			listWidth: {
				type:Number
			}
		},
		data() {
			return {
				show: false
			}
		},
		methods:{
			toggle() {
				this.show = !this.show;
			}
		}
	}
</script>

<style lang="scss">
	.menu_list {
		z-index: 100;

		.-pad {
			li { padding: 8px 8px; }
		}
		li {
			a {
				color: #494949;
				text-decoration: none;
				display: block;
				padding: 8px 8px;
			}
			display: block;
			list-style: none;
			padding: 0;
			&:before {
				content: ''
			}
			&:hover {
				background-color: #f4f4f4;
			}
		}
	}
</style>
