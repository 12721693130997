<template>
	<div class="h-full flex flex-col">

		<div class="toolbar -responsive">
			<div class="toolbar_top flex items-center px-2 text-shade-0">
				<router-link v-if="page.showClose" to="/" class="btn-darken-1 p-0 sm:!hidden">
					<svg-icon name="chevron-left" class="text-shade-0 block mr-1" size="1.8em"></svg-icon>
				</router-link>

				<div class="flex-auto px-1">
					<h4 class="text-h3 xs-hide sm-hide m-0 p-0 font-semibold font-heading" v-text="$stores.app.state.title"></h4>
					<h4 class="text-h3 m-0 p-0 md:!hidden font-semibold font-heading" v-text="page.title"></h4>
				</div>

				<router-link v-if="page.showClose" to="/" class="xs-hide">
					<svg-icon name="close" class="btn-darken-1 p-0 text-shade-0 fill-current" size="1.8em"></svg-icon>
				</router-link>
			</div>

			<div>
				<transition name="fade" mode="out-in">
					<div v-if="page.showPage" class="xs-hide sm-hide container container-inner bg-shade-2 rounded-t border border-b-0 border-shade-2">
						<transition name="fade" mode="out-in">
							<h3 class="text-h3 py-4 m-0 text-shade-8 font-semibold font-heading" v-if="show" v-text="page.title"></h3>
						</transition>
					</div>
				</transition>
			</div>
		</div>



		<div class="overflow-auto bg-shade-1 h-full flex-auto" ref="content">
			<transition name="fade" mode="out-in">
				<div v-if="page.showPage" class="mb-6 container bg-shade-0 rounded-b border border-t-0 border-shade-2 shadow-1">
					<transition name="fade" mode="out-in">
						<router-view></router-view>
					</transition>
				</div>
			</transition>
		</div>

	</div>
</template>
<script>
	import Page from '@/stores/Page';
	import SvgIcon from "../components/SvgIcon";

	export default {
		components: {
			SvgIcon,
		},
		name: 'page',
		mounted(){
			this.page.scrollContainer = this.$refs.content;
		},
		methods: {
		},
		data(){
			return {
				show: true,
				page: Page
			}
		},
		beforeRouteUpdate(a,b,c) {
			this.show=false;
			c();
		},
		beforeRouteEnter(a,b,c) {
			c();
			setTimeout(()=>{
				Page.showPage = true;
			}, 10);
		},
		beforeRouteLeave(a,b,c) {
			Page.showPage = false;
			setTimeout(()=>{c();}, 300);
			setTimeout(()=>{Page.showClose = true;}, 300);
		},

		watch: {
			'$route'(val, oldVal) {
				if(val.name === oldVal.name) this.show=true;

				else {
					setTimeout(()=>{
						this.show=true;
					},300);
				}
			}
		}
	}
</script>

