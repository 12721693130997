<template>
	<div class="container-inner py-6 mb-6">
		<form @submit.prevent="createLogin" @input="form.signupLoaded=0" class="space-y-3">

			<a-input required v-model="state.firstname" :label="$t('create_login.first_name')" name="firstname"></a-input>
			<a-input required v-model="state.lastname" :label="$t('create_login.last_name')" name="lastname"></a-input>

			<div class="sm:flex sm:-mx-2">
				<a-input required type="email" class="w-full sm:px-2" v-model="state.email" label="Email" name="email"></a-input>
				<a-input required type="email" class="w-full sm:px-2" v-model="state.email_confirm" label="Email Confirm" name="email_confirm"></a-input>
			</div>
			<p class="error-message" v-if="form.signupErrors">
				<span v-if="form.signupErrors.email" v-text="form.signupErrors.email"></span>
			</p>

			<a-input required type="password" minlength="6" v-model="state.password" label="Password" name="password"></a-input>



			<a-checkbox required class="py-2"
			            name="agree_terms"
			            v-model="state.agree_terms">
				<i18n path="create_login.accept_text">
					<a place="D1" :href='$stores.app.termsAndConditionsLink' class="link" target="_blank" v-t="'create_login.D1'"></a>
					<a place="D2" :href='$stores.app.privacyPolicyLink' class="link" target="_blank" v-t="'create_login.D2'"></a>
				</i18n>
			</a-checkbox>

			<button class="mt-2 btn-primary w-full" type="submit"
			        :disabled="form.signupLoaded===1"
			        v-text="btnText">
			</button>
		</form>
	</div>
</template>

<script>
	import Page from '@/stores/Page';
	import AInput from "../components/inputs/AInput";
	import ACheckbox from "../components/inputs/ACheckbox";

	export default {
		components: {
			ACheckbox,
			AInput},
		name: 'create-login',
		data(){
			return {
				page: Page,
				title: this.$t('create_login.title'),
				state:{
					firstname: '',
					lastname: '',
					email: '',
					email_confirm: '',
					password: '',
					agree_terms: false
				},
				form: this.$stores.userRepo.form
			}
		},
		computed:{
			btnText() {
				let txt = '';
				switch(this.form.signupLoaded) {
					case (-1):  txt = this.$t('create_login.submit_state.error'); break;
					case (0):   txt = this.$t('create_login.submit_state.default'); break;
					case (1):   txt = this.$t('create_login.submit_state.loading'); break;
					case (2):   txt = this.$t('create_login.submit_state.loaded'); break;
				}
				return txt;
			},
		},
		mounted() {
			this.page.title=this.title;
		},

		methods: {
			createLogin() {
				this.$stores.userRepo.signup(this.state)
					.then(()=>{
						this.$stores.app.redirectFromStored();
						this.$toast.good({
							body: this.$t('create_login.success')
						})
					})
					.catch((err)=>{
						console.log(err);
					});
			},
		}
	}
</script>
