<template>
	<div class="scroll-grid">

		<!-- x-axis goes here -->
		<div class="cal_x-axis-container">
			<x-axis></x-axis>
		</div>

		<div class="cal_axis-cover"></div>


		<!-- view port wrapper allows us to get the width of the grid when resizing as the inner div has a fixed width and height -->
		<div ref="viewPortWrapper" class="absolute inset-0">
			<!-- view port contains the grid -->
			<div :class="`view-port flex flex-col absolute inset-0`" ref="viewPort"
			     @mousemove="changeCursor($event)" @mousedown="changeCursor($event)" @mouseup="changeCursor($event)">
				<!-- The scrollable grid container -->
				<scroll-grid-2 :options="options">
					<week-grid slot="grid-background" ref="gridBackground" style="will-change: transform;"></week-grid>
				</scroll-grid-2>
			</div>
		</div>

		<!-- y-axis goes here -->
		<div ref="yAxis">
			<time-line></time-line>
			<div class="cal_y-axis-container">
				<y-axis></y-axis>
			</div>
		</div>
	</div>
</template>
<script>

	import Datetime from "../../Datetime";
	import SlotCell from "./SlotCell";
	import ScrollGrid2 from "./ScrollGrid";
	import WeekGrid from "./WeekGrid";
	import TimeLine from "./TimeLine";
	import YAxis from "./YAxis";
	import XAxis from "./XAxis";
	import {clearRequestInterval, requestInterval} from "../../Utils";


	export default {
		name:'calendar',
		components: {
			XAxis,
			YAxis,
			TimeLine,
			ScrollGrid2,
			WeekGrid
		},

		updated() {
			console.log('calendar Updated');
		},

		data() {
			return {
				options: {},
				slot_daily_times_array:[],
			}
		},

		watch: {
			'$stores.app.state.slots_per_day'() {
				this.options.totalRows = this.$stores.app.state.slots_per_day;
			}
		},

		created() {
			this.$stores.calendar.setNumberOfDaysInView(false);
			this.is_8_day = JSON.parse(JSON.stringify(this.$stores.app.state.is_8_day));
			this.startDate = Datetime.createFromEpoch(this.$stores.calendar.scopeStartDate);
			this.$stores.calendar.setCurrentDate(this.startDate); // Needed when in 8_day_mode. x-axis does not know what the current date is without this.
			this.update_local_slot_daily_times_array();

			this.options = this.scrollGridOptions();

			this.updateIfIdleInterval = requestInterval(()=> {
				this.updateIfIdle();
			}, 2000);
		},

		beforeDestroy() {
			clearRequestInterval(this.updateIfIdleInterval);
		},



		methods: {
			scrollGridOptions() {
				return {
					columnNumber:   this.$stores.calendar.numberOfDaysInView,
					totalRows:      this.$stores.app.state.slots_per_day,
					totalColumns:   this.$stores.calendar.totalDays,
					cellComponent:  SlotCell,


					mounted:() => {
						let server_current_time = Datetime.createFromServerString(this.$stores.app.state.config.current_time);
						let server_end_time     = Datetime.createFromServerString(this.$stores.app.state.config.end_time);

						if (server_current_time.isAfterOrSame(server_end_time))
							// '/payload' endpoint gives us the first week if the prayer room has ended so we need to step to the start.
							this.$stores.calendar.stepToStart();
						else
							this.$stores.calendar.stepToToday();
					},


					beforeResize:() => {
						this.$stores.calendar.setNumberOfDaysInView(false);
						this.options.columnNumber = this.$stores.calendar.numberOfDaysInView;
					},


					generateCellKeyName:(cellX, cellY) => {
						// The first method uses a date function.
						// This could be optimised if we stored cellX and cellY in each
						// slot "x-y" instead of calculating the date from the XY values each time.
						// I may need to create a separate index for this so that searching for XY is as simple as slotsXY[x-y].
						// slotsXY[x-y] = timestamp
						let timestamp = this.gridReferenceToTimestamp(cellX, cellY);
						let slot = window.store.slots.find(timestamp);

						if(slot && !slot.isEmpty) {
							return timestamp;
						}
						else if(this.$stores.app.state.config.is_autoexclusive) {
							return "is_autoexclusive_"+cellX +"-"+cellY;
						}
					},


					onXYChange: (object) => {
						this.$stores.calendar.startX = object.visibleGridValues.startX;
						this.$stores.calendar.xPercent = object.xPercent;
						this.$stores.calendar.yLimited = object.yLimited;

						this.$refs.yAxis.style.transform = 'translateY(' + object.yLimited + 'px)';

						//TODO: work out if I need this statement?!
						if (this.$refs.gridBackground) {
							let XPosForGridBackground = 100;
							if (window.$breakpoint.val === 'xs') XPosForGridBackground = (this.is_8_day ? 800 : 700);

							this.$refs.gridBackground.$el.style.transform = 'translateX(' + Math.floor((-(object.xPercent) / XPosForGridBackground)) * 50 + '%)';
						}

						let startXDate = this.gridXToDatetime(object.visibleGridValues.startX);
						this.$stores.calendar.setCurrentDate(startXDate);
					},


					onCellClickUpNoScroll:(object) => {
						let timestamp = this.gridReferenceToTimestamp(object.cellX, object.cellY);
						this.$stores.calendar.attemptToBook(timestamp);

						let startXDate = this.gridXToDatetime(object.visibleGridValues.startX);
						this.$stores.calendar.setCurrentDate(startXDate);
					},


					boundaryDetectionOffset: 2,
					boundaryDetectionArea: 7,

					boundaryCreated:({startCellX, endCellX}) => {
						let start = this.gridXToDatetime(startCellX);
						let end = this.gridXToDatetime(endCellX).setClock(23,59);


						if(!start.getTime() || !end.getTime()) {
							console.error('boundaryCreated: start and end dates were not valid.');
							return;
						}

						if(this.$stores.calendar.totalDays>8){
							setTimeout(()=>{
								console.log('boundaryCreated:', start.formatToDatetimeString(), end.formatToDatetimeString());
								this.$stores.slots.getFromServer(start.formatToDatetimeString(),end.formatToDatetimeString());
							}, 5000);
						}

						this.delayIdle();
					},


					boundaryRight:({startCellX, boundaryArea}) => {
						let start = this.gridXToDatetime(startCellX);
						let end = start.clone().addDate( (boundaryArea * this.options.boundaryDetectionOffset) - 1 ).setClock(23,59);

						console.log('boundaryRight', start.formatToLongDateString(), end.formatToLongDateString());
						this.$stores.slots.getFromServer(start.formatToDatetimeString(),end.formatToDatetimeString());

						this.delayIdle();
					},


					boundaryLeft:({startCellX, boundaryArea}) => {
						let date = this.gridXToDatetime(startCellX);
						let start = date.clone().addDate( -((boundaryArea * this.options.boundaryDetectionOffset)) );
						let end = date.clone().addDate( -1 ).setClock(23,59);

						console.log('boundaryLeft', start.formatToLongDateString(), end.formatToLongDateString());
						this.$stores.slots.getFromServer(start.formatToDatetimeString(),end.formatToDatetimeString());

						this.delayIdle();
					},

				}

			},



			delayIdle() {
				this.updateIfIdleDate = Datetime.now().addMinutes(5);
			},

			updateIfIdle() {
				if(!this.updateIfIdleDate) this.updateIfIdleDate = Datetime.now().addMinutes(5);

				if(Datetime.now().isAfterOrSame(this.updateIfIdleDate)) {
					console.log('updateIfIdle');
					this.updateIfIdleDate = Datetime.now().addMinutes(5);

					let boundaryArea = this.options.boundaryDetectionArea;

					this.options.boundaryCreated({
						startCellX: this.startX + (-boundaryArea),
						endCellX: this.startX + (boundaryArea * 2 - 1),
					});
				}
			},

			gridReferenceToTimestamp(cellX, cellY) {
				let times = this.slot_daily_times_array;
				let time = Number(times[cellY]);
				let hour = Math.floor(time);
				let minute = time%1>0?30:0;

				return this.startDate
					.clone()
					.addDate(cellX)
					.setHours(hour)
					.setMinutes(minute)
					.formatToTimestampString();
			},

			// Converts gridX value into a Datetime instance
			gridXToDatetime(gridX) {
				return this.startDate
					.clone()
					.addDate(gridX);
			},




			// change the cursor when dragging the grid around
			changeCursor(event) {
				if(event.buttons > 0 && event.type === 'mousemove')
				  document.body.style.cursor = 'grabbing';
				else
				  document.body.style.cursor = '';
			},


			update_local_slot_daily_times_array() {
				this.slot_daily_times_array = JSON.parse(JSON.stringify(window.store.app.state.slot_daily_times_array));
			},
		}
	}
</script>