<template>
	<div class="fixed inset-0 flex items-center justify-center h-full z-60">
		<div class="bg-darken-5 fixed inset-0 z-50"></div>
		<router-view class="z-60" />
	</div>

</template>

<script>
	export default {
		name: 'modal-router',
		data() {
			return {
				showFadeModal: false,
				contentHH: 0,
			}
		},
		mounted(){
			this.$nextTick(()=>this.showFadeModal=true);
		},
		beforeRouteEnter(to, from ,next) {
			if(this) this.$nextTick(()=>this.showFadeModal=true);
			next();
		},
		beforeRouteUpdate(to, from ,next) {
			this.showFadeModal = false;
			setTimeout(()=>{
				next();
				this.$nextTick(()=>this.showFadeModal=true);
			}, 300);
		},
		beforeRouteLeave(to, from ,next) {
			this.contentHH = 0;
			next();
		},
	}
</script>
