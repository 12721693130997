<template>
	<div class="flex flex-col bg-shade-0 sm:mx-6 rounded ani-height" :style="`width:${width};height:${height}`">

		<div class="toolbar -small rounded-t text-shade-0" ref="toolbar">
			<div class="flex h-full items-center px-1">
				<slot name="toolbar"></slot>
				<div class="flex-auto"></div>
				<slot name="toolbarRight"></slot>
			</div>
		</div>

		<div class="overflow-auto flex-auto h-full sm:h-auto">
			<div class="h-full sm:h-auto" ref="content">
				<transition name="fade" mode="out-in">
					<slot v-if="showFader"></slot>
				</transition>
			</div>
		</div>
		<div ref="footer" class="sm:pb-5 p-3 bg-shade-1">
			<transition name="fade" mode="out-in">
				<slot v-if="showFader" name="footer"></slot>
			</transition>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'modal-shell',
		props:{
			max: {
				defaultValue: 400
			}
		},
		updated(){
			this.setContent();
			setTimeout(()=>{
				this.setContent();
			}, 251)
		},
		data() {
			return {
				contentH: 0,
				windowH: 400,
			}
		},
		mounted() {
			this.setWindowHeight();
			window.addEventListener('resize', this.setWindowHeight);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.setWindowHeight);
		},
		computed: {
			showFader(){
				return this.$parent ?this.$parent.$parent.showFadeModal:false;
			},
			width() {
				if (this.$breakpoint.val === 'xs') return 100 + '%';
				return '350px';
			},
			height() {
				if (this.$breakpoint.val === 'xs') return 100 + '%';
				// if(this.contentH === 0) this.contentH = this.$parent.$parent.contentHH;

				let t = this.contentH >= this.max ? this.max : this.contentH;
				let t2 = t >= this.windowH - 50 ? this.windowH - 50 : t;
				return t2 + 'px';
			},
		},
		methods: {
			setContent() {
				let footer = this.$refs.footer;
				let content = this.$refs.content;
				let toolbar = this.$refs.toolbar;

				if(footer && content && toolbar) {
					this.contentH = footer.clientHeight + content.clientHeight + toolbar.clientHeight;
					this.$parent.$parent.contentHH = this.contentH;
				}
			},
			setWindowHeight() {
				this.windowH = window.innerHeight;
			}
		}
	}
</script>
