<template>
	<div class="bg-shade-2 flex items-center justify-center absolute inset-0">
		<div class="text-center container">
			<h2 class="text-h2 pb-3" v-t="'error_page.status_404'"></h2>
			<router-link to="/" class="btn-primary" v-t="'error_page.btn'"></router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'error-404',
	}
</script>
