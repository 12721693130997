import VueI18n from 'vue-i18n';

import locales, {locale_keys} from './locales';
import Datetime from "@/Datetime";
import Calendar from "@/stores/Calendar";
import App from "@/stores/App";

let i18n;
const load = function() {
	let defaultLocale = getLocale();

	i18n = new VueI18n({
		fallbackLocale: 'en',
		locale: defaultLocale,
		messages: locales,
		preserveDirectiveContent: true
	});

	Datetime.setLocale(defaultLocale);
}


const setLocale = function (locale = 'en') {
	i18n.locale = locale;
	Datetime.setLocale(locale);

	// redraw calendar
	Calendar.event.$emit('locale-changed');
}

const getLocale = function () {
	let currentLocale = window.localStorage.getItem('userLocale');
	
	// set a default
	if(!currentLocale) {
		// check if server found a default
		if(App.state && App.state.config.language && App.state.config.language !== '') {
			currentLocale = App.state.config.language.toLowerCase();
		}
	}
	// Check if we have that translation, otherwise fallback
	if(locale_keys.indexOf(currentLocale) === -1) {
		currentLocale = 'en';
	}
	
	return currentLocale;
}


export {
	getLocale,
	setLocale,
	load,
	i18n,
	locales
};