<template>
	<div class="form-input mb-4 px-0 py-0 divide-y-2 divide-shade-5 overflow-hidden">
		<div v-for="(fr, index) in options" :key="index" class="relative">
			<input class="absolute h-full w-full top-0 left-0 z-10 m-0 opacity-0" type="radio" :checked="value==index" :value="index" v-on="listeners" v-bind="$attrs" name="tester" />
			<div class="font-normal text-center hover:no-underline relative px-4 py-2 cursor-pointer" :class="value==index?'bg-shade-2-dark':''">
				<span v-text="fr[1]"></span>
				<svg-icon class="absolute fill-shade-9 top-0 mt-1.7 right-0 mr-3" :class="value==index?'opacity-100':'opacity-0'" name="check" size="1.5em"></svg-icon>
			</div>
		</div>
	</div>
</template>

<script>
	import SvgIcon from "../SvgIcon";
	export default {
		name: 'a-radio-list',
		components: {SvgIcon},
		inheritAttrs: false,
		props: {
			value: {},
			options: {
				type: Array,
				required: true
			}
		},
		computed: {
			listeners() {
			  const that = this;
				return {
					...this.$listeners,
          input (event) {
            that.$emit('input', event.target.value);
          },
          change(event) {
            that.$emit('input', event.target.value);
          }
				};
			}
		}
	}
</script>
