<template>
	<div class="form-input mb-4">
		<div class="flex justify-between items-center">
			<button type="button" :class="`btn p-0 ${minLimit&&'opacity-50'}`" :disabled="minLimit" @click="minusDisplayDuration()">
				<svg-icon class="block text-shade-9 cursor-pointer" name="chevron-left" size="30px"></svg-icon>
			</button>

			<div v-if="valueDisplay" class="flex-auto text-center" v-text="valueDisplay(newValue)"></div>
			<div v-else class="flex-auto text-center" v-text="newValue"></div>

			<button type="button" :class="`btn p-0 ${maxLimit&&'opacity-5'}`" :disabled="maxLimit" @click="addDisplayDuration()">
				<svg-icon class="block text-shade-9 cursor-pointer" name="chevron-right" size="30px"></svg-icon>
			</button>

			<input type="hidden" :value="newValue" v-on="listeners" v-bind="$attrs">
		</div>
	</div>
</template>
<script>
	import SvgIcon from "../SvgIcon";
	export default {
		name: 'a-number',
		components: {SvgIcon},
		inheritAttrs: false,
		props: {
			valueDisplay:{},
			min:{},
			max:{},
			step: {
				default: 1
			},
			value: {},
		},
		data() {
			return {
				newValue: 0,
			}
		},
		mounted() {
			this.newValue = this.value;
		},

		watch: {
			'value'(val) {
				this.newValue = val;
			}
		},

		computed: {
			listeners() {
				return {
					...this.$listeners,
					input: event => this.$emit('input', event.target.value)
				};
			},
			minLimit() {
				return this.newValue <= this.min;
			},

			maxLimit() {
				return this.newValue >= this.max;
			},
		},

		methods: {
			addDisplayDuration() {
				if(this.maxLimit) return;
				this.newValue = this.newValue + this.step;
				this.$emit('input', this.newValue)
			},

			minusDisplayDuration() {
				console.log(this.minLimit, this.min);
				if(this.minLimit) return;
				this.newValue = this.newValue - this.step;
				this.$emit('input', this.newValue)
			},
		}
	}
</script>
