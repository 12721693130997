<template>
	<div style="width:13px;height:13px" :class="themeCss(themeId)"></div>
</template>
<script>
	export default {
		name: 'color-dot',
		props: ['width', 'height', 'themeId', 'themeColor'],
		methods: {
			themeCss(index) {
				let theme;
				if(this.themeColor) theme = { color: this.themeColor }
				else theme = this.$stores.app.state.config.themes[index];

				let initCss = `inline-block rounded-circle`;

				if(!theme) return 'bg-shade-0 border-shade-6 ' + initCss;
				return `bg-theme-${theme.color} ${initCss}`;
				// border b-theme-${theme.color}-dark
			},
		}
	}
</script>
