<template>
	<div class="container-inner py-6 mb-6">
		<div>
			<form method="post" @submit.prevent="loginUser" @input="form.loginLoaded = 0" class="space-y-3">
				<a-input required type="email" v-model="email" :label="$t('log_in.email')" name="email"></a-input>
				<a-input required minlength="6" v-model="password" class="mt-0" :label="$t('log_in.password')" type="password" name="password"></a-input>
				<p class="error-message" v-if="form.loginLoaded===-1" v-t="'log_in.validation_error'"></p>

				<button class="mt-2 w-full btn-primary"
				        :disabled="form.loginLoaded===1"
				        v-text="btnText"></button>
				<p class="text-center mt-2">
					<a :href="$stores.app.forgotPassword" class="link" target="_blank" v-t="'log_in.forgot_password'">Forgotten your Password?</a>
					&nbsp;
					<router-link class="link" :to="{name:'create-login'}" v-t="'create_login.title'"></router-link>
				</p>
			</form>
		</div>
	</div>
</template>

<script>
	import Page from '@/stores/Page';
	import AInput from "../components/inputs/AInput";

	export default {
		components: {
			AInput
		},
		name: 'login',
		data(){
			return {
				page: Page,
				title: this.$t('log_in.title'),

				email: '',
				password: '',

				form: this.$stores.userRepo.form
			}
		},
		mounted() {
			this.page.title=this.title;
		},

		computed: {
			btnText() {
				let txt = '';
				switch(this.form.loginLoaded) {
					case (-1): txt = this.$t('log_in.submit_state.error'); break;
					case (0): txt = this.$t('log_in.submit_state.default'); break;
					case (1): txt = this.$t('log_in.submit_state.loading'); break;
					case (2): txt = this.$t('log_in.submit_state.loaded'); break;
				}
				return txt;
			}
		},
		methods: {
			loginUser() {
				this.$stores.userRepo.login(this.email,this.password)
				.then(()=>{
					this.$stores.app.redirectFromStored();
					this.$toast.good({
						body: this.$t('log_in.success')
					});
				});
			},
		}
	}
</script>
