<template>
    <div class='container-inner py-6 mb-6'>
        <div>
            <form @submit.prevent='saveToServer' @input='loaded = 0' class='space-y-3'>

                <a-input
                    required
                    v-model='state.name'
                    name='name'
                    :label="$t('contact_organiser.name')"
                ></a-input>

                <a-input
                    type='email'
                    required
                    v-model='state.email'
                    name='email'
                    :label="$t('contact_organiser.email')"
                ></a-input>

                <a-textarea
                    required
                    :label="$t('contact_organiser.message')"
                    name='message'
                    v-model='state.message'
                ></a-textarea>

                <button class='btn-primary w-full' type='submit' :disabled='loaded===1' v-text='btnText'></button>

                <div style='overflow: hidden;width:0;height:0;'>
                    <input type='text' name='yummy_5pam_4_l1f3' placeholder='email' value=''>
                </div>

                <p class='py-2 text-base'>
                    <!--					GDPR statement [to be decided].-->
                </p>
            </form>
        </div>
    </div>
</template>


<script>
import Page from '@/stores/Page';
import AInput from '../components/inputs/AInput';
import ATextarea from '../components/inputs/ATextarea';


export default {
		components: {
			ATextarea,
			AInput},
		name: 'contact',
		data(){
			return {
				page: Page,
                title: this.$t('contact_organiser.title'),
				state: {
					name: '',
					email: '',
					message: ''
				},
				loaded: 0,
			}
		},

		mounted() {
			this.page.title=this.title;

			if(this.$stores.user.isLoggedIn) {
				this.state.name = this.$stores.user.fullName;
				this.state.email = this.$stores.user.email;
				this.state.message = this.$stores.user.message;
			}
		},

		computed: {
			btnText() {
				let txt = '';
				switch(this.loaded) {
					case (-1): txt = this.$t('contact_organiser.submit_state.error'); break;
					case (0): txt = this.$t('contact_organiser.submit_state.default'); break;
					case (1): txt = this.$t('contact_organiser.submit_state.loading'); break;
					case (2): txt = this.$t('contact_organiser.submit_state.loaded'); break;
				}
				return txt;
			}
		},

		methods: {
			saveToServer() {
				this.loaded = 1;

				window.apiFetcher.post('/contact', this.state)
					.then(()=>{
						setTimeout(()=>{
							this.loaded = 2;
							this.$router.push('/');
							this.$toast.good({
								body: this.$t('contact_organiser.success')
							})
						}, 500)
					}).catch(()=>{
						setTimeout(()=>{
							this.loaded = -1;
						}, 500)
					});
			}
		}
	}
</script>
