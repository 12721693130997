import {Polyfillers} from "./Utils";
new Polyfillers(window);

// Prevent double tap because client requested that users not be able to double tap to zoom
let doubleTouchStartTimestamp = 0;
document.addEventListener("click", function(event){
	// make sure labels with checkboxes still work.
	if(event.target.type==='checkbox') return;

	const now = +(new Date());
	if (doubleTouchStartTimestamp + 500 > now) event.preventDefault();
	doubleTouchStartTimestamp = now;
});


import Vue from 'vue';
if(process.env.NODE_ENV!=='production') {
	Vue.config.devtools = true;
	Vue.config.performance = true;
	Vue.config.productionTip = false;
}

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import {load, i18n, setLocale, getLocale} from "../locales";


import './registerServiceWorker'
import Stores from "./stores/Stores";
import App from './App.vue'
import router from './router'

import ApiFetcher from './ApiFetcher';
window.apiFetcher = new ApiFetcher();

import Toast from './components/Toast/Toast';
import Timeout from "./Timeout";
import UserRepo from "./stores/UserRepo";
import './assets/scss/app.scss';

import {PrintCalendar} from "./PrintCalendar";
PrintCalendar.autoLoad();

let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
	container: "body",
	duration: 500,
	easing: [.55,0,.1,1],
	offset: 0,
	cancelable: true,
	onDone: false,
	onCancel: false
});

// development / production folder switch
window.$asset = (src)=>{
	let path = window.location.port === "" ? '/signup-dist/' : '/';
	return path+src;
};
Vue.prototype.$asset = window.$asset;


Stores.router = router;
Vue.util.defineReactive(Vue.prototype, '$stores',  Stores);
router.afterEach((to,from) => { Stores.app.referrer = from; });
router.beforeEach((to, from, next) => {
	let title = document.querySelector('title[data-base-title]').getAttribute('data-base-title');
	let routeTitle = to.meta.title !== undefined
		? (to.meta.title==='' ? '' : " | " + to.meta.title)
		: " | " + to.name;
	
	document.title = title + routeTitle;
	next();
});

import VueGtag from "vue-gtag";
if(process.env.NODE_ENV==='production') {
	Vue.use(VueGtag, {
		config: { id: "UA-742625-8"},
		pageTrackerTemplate(to) {
			return {
				page_title: document.title,
				page_path: router.options.base + to.path,
				page_location: window.location.href
			}
		}
	}, router);
}

Vue.prototype.$toast = Toast;
window.$toast = Toast;


// Check break point
let $breakpoint = { val: 'x' };
Vue.util.defineReactive(Vue.prototype, '$breakpoint',  $breakpoint);
function checkBreakpoint() {
	$breakpoint.val = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/"/g, '');
}
window.addEventListener('resize', checkBreakpoint);
checkBreakpoint();
window.$breakpoint = $breakpoint;



let mountVue = ()=>{
	load();
	setLocale(getLocale());
	window.rootUrl = `/signup/${window.store.app.state.config.uniqid}`;
	new Vue({ router, i18n, render: h => h(App)}).$mount('#app');
};

let timeoutFn = ()=>{
	Timeout.init(
		// callback to notify user that the session has expired
		()=>{
			if(window.store.user.isLoggedIn) {
				UserRepo.logout(false);

				window.showInlineModal({
					title: this.$t('session.title'),
					content:`
					<p class="pt-4 pb-2 text-center">
						<span class="font-bold">{{$t('session.description[0]')}}</span>
						<br />
						{{$t('session.description[1]')}}
					</p>
				`,
					footerComponent:{
						render(h){
							let uniqid = window.store.app.state.config.uniqid;

							return h('div', {class:'p-2'}, [
								h('button', {class:'btn -primary w-full text-center mb-2', on:{click: ()=>{
									window.location = window.location.origin + '/signup/' +uniqid+ '/login';
								}}}, [this.$t('session.login_btn')]),
								h('button', {class:'btn -primary w-full text-center', on:{click: ()=>{
									window.location = window.location.origin + '/signup/'+uniqid;
								}}}, [this.$t('session.home_btn')])
							])
						}
					}
				});
			}
		},
		// callback to warn user that session is about to expire
		()=>{
			if(window.store.user.isLoggedIn) window.$toast.warn({
				timeout: Timeout.warnSeconds*1000,
				body: `This session will end in less than one minute.`
			});
		}
	);
};



//START YOUR ENGINES!!!
// If we didn't get a '/payload' via the 'signupAppPayload' variable then we need to load it first!
// this is useful for development purposes as well as a backup
console.log(process.env.NODE_ENV);
if(process.env.NODE_ENV==='production') {
	window.store.app.parsePayload(window.signupAppPayload);
	mountVue();
	timeoutFn();
}
else{
	window.store.app.getFromServer().then(() => {
		window.themify.setFromHex((
			window.store.app.state
			&& window.store.app.state.config
			&& window.store.app.state.config.group
			&& window.store.app.state.config.group.custom_colour
				? '#'+window.store.app.state.config.group.custom_colour
				: false)
		);
		mountVue();
		timeoutFn();
	});
}

