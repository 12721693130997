import {Velocity} from "./Utils";

export class PeripheralInteraction {
	constructor(containerEl, drawCallback, clickableCallback) {

		// Reasonable defaults for mouse wheel
		this.PIXEL_STEP  = 10;
		this.LINE_HEIGHT = 40;
		this.PAGE_HEIGHT = 800;

		this.containerEl = containerEl;
		this.drawCallback = drawCallback;
		this.clickableCallback = clickableCallback;

		this.moveFreely = false;
		this.directionAxis = false;

		this.calculateMove = ()=>{};
		this.mouse = this.touch;
		this.setType('mouse');
		this.xx = 0;
		this.yy = 0;

		this.touching = false;
		this.targetX = 0;
		this.vx = 0;
		this.vy = 0;

		this.oldXY = 0;
		this.type = 'mouse';
		this.outsideClickableGraceZone = false;
		this.outsideClickableGraceZoneAmount = 5;

		this.touchStartX = 0;
		this.touchStartY = 0;

		this.velocityX = new Velocity();
		this.velocityY = new Velocity();

		this.touchstart = (evt) => { this.interactStart(evt, 'touch', evt.touches[0].pageX, evt.touches[0].pageY); };
		this.touchmove = (evt) =>  { this.interactMove(evt, evt.touches[0].pageX, evt.touches[0].pageY); };
		this.touchend = (evt) => { this.interactEnd(evt) };
		this.mousedown = (evt) =>  { this.interactStart(evt, 'mouse', evt.pageX, evt.pageY); };
		this.mousemove = (evt) =>  { this.interactMove(evt, evt.pageX, evt.pageY); };
		this.mouseup = (evt) => { this.interactEnd(evt) };
		this.wheelFn = (evt) => { this.interactWheel(evt) };

		this.listeners();
	}


	updateDimensions(itemWidth, scrollContentHeight, scrollContentWidth) {
		this.itemWidth = itemWidth;
		this.scrollContentHeight = scrollContentHeight;
		this.scrollContentWidth = scrollContentWidth;

		this.setType('touch');
		this.touching = false;
		this.targetX = (Math.round(this.xx/this.itemWidth)*this.itemWidth);
	}



	/* ****************
		Listeners and listener functions
	******************/
	removeListeners() {
		this.containerEl.removeEventListener('wheel', this.wheelFn);
		this.containerEl.removeEventListener('touchstart', this.touchstart);
		window.removeEventListener('touchmove', this.touchmove);
		window.removeEventListener('touchend', this.touchend);
		this.containerEl.removeEventListener('mousedown', this.mousedown);
		window.removeEventListener('mousemove', this.mousemove);
		window.removeEventListener('mouseup', this.mouseup);
	}


	listeners() {
		this.containerEl.addEventListener('wheel', this.wheelFn);
		this.containerEl.addEventListener('touchstart', this.touchstart);
		window.addEventListener('touchmove', this.touchmove);
		window.addEventListener('touchend', this.touchend);
		this.containerEl.addEventListener('mousedown', this.mousedown);
		window.addEventListener('mousemove', this.mousemove);
		window.addEventListener('mouseup', this.mouseup);
	}


	interactStart(evt, type, pageX, pageY) {
		this.vx = 0;
		this.startPageX = pageX;
		this.startPageY = pageY;
		evt.preventDefault();
		this.directionAxis = false;
		this.touching = true;
		this.setType(type);
		this.touchStartX = -this.xx + pageX;
		this.touchStartY = -this.yy + pageY;
		this.outsideClickableGraceZone = false;

		clearTimeout(this.ttt);
		clearTimeout(this.tt);
	}


	interactMove(evt, pageX, pageY){
		evt.stopPropagation();
		if(!this.touching) return;

		// calculate if cursor has gone outside of grace zone
		let diffX = this.startPageX - pageX;
		let diffY = this.startPageY - pageY;
		if(Math.abs(diffX) > this.outsideClickableGraceZoneAmount || Math.abs(diffY) > this.outsideClickableGraceZoneAmount) {
			this.outsideClickableGraceZone = true;
		}

		if(this.moveFreely===false) this.detectDirectionOnce(this.startPageX, this.startPageY, pageX, pageY);

		if(this.moveFreely || this.directionAxis === 'X') {
			this.setXExact(-(this.touchStartX - pageX));
			this.velocityX.updatePosition(pageX);
		}

		if(this.moveFreely || this.directionAxis === 'Y') {
			this.setYExact(-(this.touchStartY - pageY));
			this.velocityY.updatePosition(pageY);
		}
	}


	interactEnd(evt){
		evt.stopPropagation();
		if(!this.touching) return;

		this.touching = false;
		this.directionAxis = false;
		this.vx = this.velocityX.getVelocity()/40;
		this.vy = this.velocityY.getVelocity()/40;

		// Snapping calculation - work out where to stop scrolling
		var skip = (this.vx/100); // every 100 velocity equals a skipped snapping point
		this.targetX = this.xx + (skip*this.itemWidth);
		this.targetX = Math.round(this.targetX/this.itemWidth)*this.itemWidth;

		if(this.clickableCallback) {
			// its a mouse click event
			if(!evt.changedTouches) {
				if(!this.outsideClickableGraceZone){
					this.clickableCallback(evt);
				}
			}
			//its a touch event!
			else {
				if(!this.outsideClickableGraceZone){
					this.clickableCallback(evt);
				}
			}
		}
	}


	interactWheel(evt) {
		evt.preventDefault();
		if(this.type!=='wheel') this.setType('wheel');
		clearTimeout(this.ttt);

		let wheel = this.normalizeWheel(evt);

		if(Math.abs(wheel.pixelX) > Math.abs(wheel.pixelY)) {
			this.directionAxis = 'X'
		} else if(Math.abs(wheel.pixelX) < Math.abs(wheel.pixelY)) this.directionAxis = 'Y';


		if(this.moveFreely || this.directionAxis==='X') {
			this.addX( this.clamp(-wheel.pixelX/4, -50,50) );

			this.ttt = setTimeout(()=>this.stepToNearest(), 1000);
		}
		if(this.moveFreely || this.directionAxis==='Y') {
			this.addY(this.clamp(-wheel.pixelY / 3, -50, 50));

			clearTimeout(this.ttt);
			this.stepToNearest();
		}

		this.targetX = -(Math.round(-(this.xx)/this.itemWidth) * this.itemWidth);
	}


	/* ****************
	scroll movement, positioning and bound limitations
	******************/
	addX(posx) {
		this.setXExact(this.xx + posx);
	}


	setXExact(posx) {
		this.xx = posx;
		this.xx=this.xx>0?0:this.xx;
		this.xx=this.xx < this.scrollContentWidth?this.scrollContentWidth:this.xx;
	}


	addY(posx) {
		this.setYExact(this.yy + posx);
	}


	setYExact(posy) {
		this.yy = posy;
		this.yy=this.yy>0?0:this.yy;
		this.yy=this.yy < this.scrollContentHeight?this.scrollContentHeight:this.yy;
	}

	detectDirectionOnce(startX, startY, currentX, currentY) {
		if(this.directionAxis) return;

		if(Math.abs(startX - currentX) > Math.abs(startY-currentY)) {
			this.directionAxis = 'X';
		}
		else if(Math.abs(startX - currentX) < Math.abs(startY-currentY)) {
			this.directionAxis = 'Y';
		}
	}




	/*****************
	 * Set peripheral type and calculate movement physics based on peripheral event
	******************/
	setType (t) {
		this.type = t;
		if(this.type==="wheel") this.calculateMove = this.wheel;
		else if(this.type==="touch") this.calculateMove = this.touch;
		else if(this.type==="mouse") this.calculateMove = this.mouse;
		else if(this.type==="number") this.calculateMove = this.number;
	}


	wheel(){
		if(this.xx + this.yy === this.oldXY) return;
		this.setXExact(this.xx);
		this.setYExact(this.yy);
		this.oldXY = this.xx + this.yy;
		if(this.drawCallback) this.drawCallback(this.xx,this.yy);
	}


	touch() {
		this.vy = this.vy*0.85;
		this.addY(this.vy);
		this.yy = Math.round(this.yy*100)/100;

		if(this.outsideClickableGraceZone) this.touchXSnapMotion();

		if(this.drawCallback) this.drawCallback(this.xx,this.yy);
	}


	touchXFreeMotion() {
		this.vx = this.vx*0.85;
		this.addX(this.vx);
		this.xx = Math.round(this.xx*100)/100;
	}


	touchXSnapMotion() {
		let isRestrictingAxis = (!this.moveFreely && (!this.directionAxis || this.directionAxis==='X'));
		if(this.touching && isRestrictingAxis) {
			this.touchXFreeMotion();
			return;
		}

		let dx = this.targetX - this.xx;
		this.addX( ((dx) * 0.1) );

		if(Math.abs(this.targetX - this.xx)<1)this.xx = this.targetX;

		this.setXExact( Math.round(this.xx*100)/100 );
	}


	number() {
		if(this.drawCallback) this.drawCallback(this.xx,this.yy);
	}





	/*****************
	 * Miscellaneous
	 ******************/
	stepBack() {
		this.setType('touch');
		this.touching = false;
		this.outsideClickableGraceZone = true;
		this.targetX = (Math.round(this.xx/this.itemWidth)*this.itemWidth) + this.itemWidth;
	}


	stepForward() {
		this.setType('touch');
		this.touching = false;
		this.outsideClickableGraceZone = true;
		this.targetX = (Math.round(this.xx/this.itemWidth)*this.itemWidth) - this.itemWidth;
	}


	stepTo(index, smooth) {
		if(!smooth) {
			this.setType('number');
			this.xx = -(index*this.itemWidth);
			return;
		}

		this.setType('touch');
		this.touching = false;
		this.outsideClickableGraceZone = true;
		this.targetX = -(index*this.itemWidth);
	}


	clamp(val, min, max) {
		return val > max ? max : val < min ? min : val;
	}

	stepToNearest() {
		this.setType('touch');
		this.touching = false;
		this.outsideClickableGraceZone = true;
		this.targetX = -(Math.round(-(this.xx)/this.itemWidth) * this.itemWidth);
	}





	/*****************
	 * mouse wheel normalisation
	 * Ref: https://stackoverflow.com/questions/5527601/normalizing-mousewheel-speed-across-browsers#answer-30134826
	 ******************/
	normalizeWheel(/*object*/ event) /*object*/ {
		let sX = 0, sY = 0,       // spinX, spinY
			pX = 0, pY = 0;       // pixelX, pixelY

		// Legacy
		if ('detail'      in event) { sY = event.detail; }
		if ('wheelDelta'  in event) { sY = -event.wheelDelta / 120; }
		if ('wheelDeltaY' in event) { sY = -event.wheelDeltaY / 120; }
		if ('wheelDeltaX' in event) { sX = -event.wheelDeltaX / 120; }

		// side scrolling on FF with DOMMouseScroll
		if ( 'axis' in event && event.axis === event.HORIZONTAL_AXIS ) {
			sX = sY;
			sY = 0;
		}

		pX = sX * this.PIXEL_STEP;
		pY = sY * this.PIXEL_STEP;

		if ('deltaY' in event) { pY = event.deltaY; }
		if ('deltaX' in event) { pX = event.deltaX; }

		if ((pX || pY) && event.deltaMode) {
			if (event.deltaMode == 1) {          // delta in LINE units
				pX *= this.LINE_HEIGHT;
				pY *= this.LINE_HEIGHT;
			} else {                             // delta in PAGE units
				pX *= this.PAGE_HEIGHT;
				pY *= this.PAGE_HEIGHT;
			}
		}

		// Fall-back if spin cannot be determined
		if (pX && !sX) { sX = (pX < 1) ? -1 : 1; }
		if (pY && !sY) { sY = (pY < 1) ? -1 : 1; }

		return {
			spinX  : sX,
			spinY  : sY,
			pixelX : pX,
			pixelY : pY
		};
	}
}
