<template>
	<div id="week-grid" class="absolute inset-0 border-b border-shade-3" :style="`width:${gridWidth}%;height:${gridHeight}px`">
		<svg version="1.1" baseProfile="full" xmlns="http://www.w3.org/2000/svg"
		     :style="`width:100%;height:100%;paint-order: fill;`"
		     :viewBox="`0 0 ${gridWidth2} ${gridHeight}`"
		     preserveAspectRatio="none">
			<defs>
				<pattern id="weekDuration" width="100%" height="80" patternUnits="userSpaceOnUse">
					<rect v-for="(isWeekend,index) in isWeekendArray" :key="index"
					      vector-effect="non-scaling-stroke"
					      shape-rendering="geometricprecision"
					      :x="`${(50 * index)/daysInWeek}%`"
					      :width="`${(50)/daysInWeek}%`"
					      height="80px"
					      :fill="`${isWeekend?'rgba(0,0,0,.03)':'transparent'}`"
					      stroke="#c6c6c6"
					      stroke-width="1" />
				</pattern>
			</defs>


			<rect height="100%" width="50%" fill="url(#weekDuration)" />
			<rect style="transform:translateX(50%)" height="100%" width="50%" fill="url(#weekDuration)" />
		</svg>
	</div>
</template>

<script>
	export default {
		name: 'week-grid',
		updated() {

		},

		data() {
			return {
				isWeekendArray: this.$stores.calendar.isWeekendArray,
				windowWidth: 0
			}
		},

		methods:{
			setInnerWidth() {
				this.windowWidth = window.innerWidth;
			}
		},

		mounted() {
			window.addEventListener('resize', this.setInnerWidth);
			this.setInnerWidth();
		},

		beforeDestroy() {
			window.removeEventListener('resize', this.setInnerWidth);
		},

		computed: {
			daysInWeek() {
				return this.isWeekendArray.length
			},

			gridWidth() {
				return window.$breakpoint.val==='xs'? (this.daysInWeek*100)*2 : 100*2;
			},

			gridWidth2() {
				return window.$breakpoint.val==='xs'? (this.windowWidth*this.daysInWeek)*2 : this.windowWidth;
			},

			gridHeight() {
				return window.store.app.state.slots_per_day * 80;
			}
		}
	}
</script>