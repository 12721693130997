<template>
	<div class="bg-shade-10 flex flex-col h-full text-shade-3">
		<div class="flex-none px-4 py-2 sm:py-4 text-shade-0">
			<router-link :to="{name: 'home'}" class="flex items-center">
				<img class="block w-11/12" :src="$asset('img/logo-white.svg')" />
			</router-link>
		</div>

		<div class="flex flex-col flex-auto overflow-auto">

			<div class="pt-1 sm:pt-2 pb-6 px-4 text-center flex-none" v-if="(app.state.config.group && app.state.config.group.custom_logo) || app.state.config.description">
				<img class="block w-10/12 mb-2" v-if="app.state.config.group && app.state.config.group.custom_logo" :src="app.state.config.group.custom_logo" />
				<p class="text-base font-light pb-0 mb-0" style="line-height: 18px" v-if="app.state.config.description" v-text="app.state.config.description"></p>
			</div>
			<div v-else class="h-[3.1rem]"></div>

			<div class="h-list flex flex-col flex-auto">

<!--                TODO: put this back when details are optional.     -->
<!--				<h-list icon="contact" :title="$t('sidebar.contact.title')" :init-toggle="false">-->
<!--					<div class="" v-if="app.state.config.group">-->
<!--						<div class="flex items-center pb-1" v-if="app.state.config.group.telephone">-->
<!--							<svg-icon name="mobile" class="fill-primary" style="width:20px;height:20px;"></svg-icon>-->
<!--							<a class="txt-reset pl-1 medium text-base text-shade-0 h-shade-4 mt-0" :href="`tel:${app.state.config.group.telephone}`" v-text="app.state.config.group.telephone"></a>-->
<!--						</div>-->
<!--						Client wants this hidden for now -->
<!--						<div class="flex text-left pb-1" v-if="app.state.config.group.address">-->
<!--							<svg-icon name="marker" class="fill-primary" style="width:20px;height:20px;"></svg-icon>-->
<!--							<a class="txt-reset pl-1 medium text-base w-11/12 text-shade-0 h-shade-4 mt-0"-->
<!--							   :href="`http://maps.google.com/?q=${encodeURI(app.state.config.group.address)}`"-->
<!--							   v-text="app.state.config.group.address"></a>-->
<!--						</div>-->
<!--						<router-link class="mt-2 mb-1 btn text-base -primary b-pill block"-->
<!--						             v-if="!$stores.user.isAdmin"-->
<!--						             @click.native="app.hideSidebar"-->
<!--						             to="/contact-organiser"-->
<!--						             v-t="'sidebar.contact.btn'"-->
<!--						>-->
<!--						</router-link>-->
<!--					</div>-->
<!--				</h-list>-->

				<router-link to="/contact-organiser" class="h-list_link">
					<svg-icon name="contact" class="fill-shade-0 relative" size="1.5em" style="left:-.15em;"></svg-icon>
					<div class="flex-auto pl-1" v-t="'sidebar.contact.btn'"></div>
					<svg-icon name="chevron-right" class="text-shade-0" size="20px"></svg-icon>
				</router-link>

				<a :href="$stores.app.prayerResources" target="_blank" class="h-list_link">
					<svg-icon name="resource" class="fill-shade-0 relative" size="1.5em" style="left:-.15em;"></svg-icon>
					<div class="flex-auto pl-1" v-t="'sidebar.prayer_resources.btn'"></div>
					<svg-icon name="chevron-right" class="text-shade-0" size="20px"></svg-icon>
				</a>
				<!--<router-link @click.native="app.hideSidebar" to="/feedback">Feedback</router-link>-->
				<h-list v-if="app.state.themes_total > 0" icon="themes" :title="$t('sidebar.themes.title')" :init-toggle="false">
					<div v-for="theme in themes" :key="theme.id" class="flex items-center mb-1">
						<color-dot :width="16" :height="16" :theme-color="theme.color"></color-dot>
						<div class="font-normal ml-2 text-base m-0" v-text="theme.label"></div>
					</div>
				</h-list>

				<h-list :title="$t('sidebar.key.title')" icon="key" :init-toggle="false">
					<div class="flex items-center mb-1">
						<svg-icon name="exclusive" size="1.25em" class="fill-lighten-4" style="margin-bottom:.1em"></svg-icon>
						<div class="font-normal ml-1 text-base m-0" v-t="'sidebar.key.exclusive'"></div>
					</div>

					<div class="flex items-center mb-1">
						<svg-icon name="exclusive-booked" size="1.25em" class="fill-lighten-4" style="margin-bottom:.1em"></svg-icon>
						<div class="font-normal ml-1 text-base m-0" v-t="'sidebar.key.exclusive_booked'"></div>
					</div>
				</h-list>

				<span class="flex-auto"></span>

				<a :href="$stores.app.donateLink" target="_blank" class="h-list_link">
					<span v-t="'sidebar.donate.btn'"></span>
					<svg-icon name="chevron-right" class="text-shade-0" style="width: 20px;height: 20px"></svg-icon>
				</a>

				<a :href="$stores.app.feedbackLink" target="_blank" class="h-list_link">
					<span v-t="'sidebar.feedback.btn'"></span>
					<svg-icon name="chevron-right" class="text-shade-0" style="width: 20px;height: 20px"></svg-icon>
				</a>

				<a :href="$stores.app.prayerRoomHelpLink" target="_blank" class="h-list_link">
					<span v-t="'sidebar.help.btn'"></span>
					<svg-icon name="chevron-right" class="text-shade-0" style="width: 20px;height: 20px"></svg-icon>
				</a>

				<a v-if="$stores.user.isAdmin" :href="$stores.app.adminPrayerRoomHelpLink" target="_blank" class="h-list_link">
					<span v-t="'sidebar.admin_help.btn'"></span>
					<svg-icon name="chevron-right" class="text-shade-0" style="width: 20px;height: 20px"></svg-icon>
				</a>



				<div class="py-2 px-4">
					<a class="block py-0 mb-0 text-base font-medium text-shade-5 hover:text-shade-2 no-underline"
					   target="_blank"
					   :href="$stores.app.termsAndConditionsLink"
						v-t="'sidebar.terms_conditions.btn'"
					></a>

					<a class="block py-0 text-base font-medium text-shade-5 hover:text-shade-2 no-underline"
					   target="_blank"
					   :href="$stores.app.privacyPolicyLink"
					   v-t="'sidebar.privacy_policy.btn'"
					></a>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import App from '@/stores/App';
	import ColorDot from "./ColorDot";
	import SvgIcon from "./SvgIcon";
	import HList from "./HList";

	export default {
		components: {HList, SvgIcon, ColorDot},
		app: App,
		name: 'side-bar',
		data(){
			return {
				app:App,
				toggleTheme: true,
			}
		},
		computed: {
			themes() {
				return this.$stores.app.state.config.themes.filter((a)=>a.label!=='')
			}
		}
	}
</script>
