<template>
	<div>
		<label class="form-label pb-1" :for="name" v-text="label"></label>
		<select class="form-select w-full" :id="name" :name="name" :value="value" v-on="listeners" v-bind="$attrs">
			<slot></slot>
		</select>

		<span class="error-message"></span>
		<!--<span class="error-message" v-if="form.errors.has(name)" v-text="form.errors.get(name)"></span>-->
	</div>
</template>
<script>
	export default {
		name: 'a-select',
		inheritAttrs: false,
		props: {
			label:{
				type: String,
				required: true
			},
			name: {
				type: String,
				required: true
			},
			value:{}
		},
    computed: {
      listeners() {
        const that = this;
        return {
          ...this.$listeners,
          input(event) {
            that.$emit('input', event.target.value);
          },
          change(event) {
            that.$emit('input', event.target.value);
          }
        };
      }
    }
	}
</script>
