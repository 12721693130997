<template>
	<div class="cal_y-axis border-r border-shade-3 bg-shade-0">
		<div v-for="(row,index) in rows" :key="index" class="cal_y-axis-text" :style="`height:80px;min-height:80px`">
			<span :class="index===0?'pt-1':''" v-text="row"></span>
		</div>
	</div>
</template>

<script>
	import {i18n} from "../../../locales";

  export default {
		name: 'y-axis',
		data() {
			return {
			}
		},

    mounted() {
      // todo: remove "this.$stores.calendar" dependency
      this.$stores.calendar.event.$on('locale-changed', ()=>{
        this.$forceUpdate();
      });
    },

		computed: {
			rows() {
				let increment = (Number(this.$stores.app.state.config.slotlengthmins)/60);
				let is_12hr = this.$stores.app.state.config.is_12hr;

				let times = [];
				let length = (24/increment);
				for(let i=0;i < length;i++) {
					let totalM = ((i*increment)*60);
					let m = totalM%60;
					let h = (totalM - m) / 60;

					if(is_12hr) h = h > 12 ? h-12 : h;
					h = h < 10 ? '0' + h : h;
					m = m < 10 ? '0' + m : m;

					// can't use data.toLocalString() here as admin users need to be able to set am/pm
					if(is_12hr) {
						m = m+(i+1 > length/2?  i18n.t('misc.pm') : i18n.t('misc.am'));
					}

					times.push(h +':'+ m);
				}

				return times;
			},

		},

	}
</script>
