import {getLocale} from "../locales";

window.axios = require('axios');
import Timeout from './Timeout';
window.Timeout = Timeout;


class ApiFetcher {
	static axiosInstance = {};

	constructor() {
		ApiFetcher.axiosInstance = window.axios.create({
			baseURL: process.env.NODE_ENV==='production'
				? '/signup/' + window.signupAppPayload.config.uniqid + '/_api'
				: 'http://tfs.test/signup/beta/_api',

			withCredentials: true,
			transformResponse:[].concat(
				window.axios.defaults.transformResponse,
				(data) =>{
					Timeout.reset();
					return data;
				})
		});

		// only implement if not local environment
		if(process.env.NODE_ENV==='production')
			ApiFetcher.axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	}


	get(url, params={}) {
		params.language = getLocale();
		
		return new Promise((resolve, reject) => {
			ApiFetcher.axiosInstance.get(url, {
				params: params
			})
				.then((response) => {
					console.log(response.data);
					resolve(response.data, response)
				})
				.catch((error) => {
					this._errorNotification(error);
					reject(error);
				})
		});
	}


	post(url, data) {
		url += (window.location.search === ''?'?':'&') + 'language=' + getLocale();
		
		return new Promise((resolve, reject) => {
			let formData = new FormData();
			let arrayOfKeys = Object.keys(data);
			if (arrayOfKeys.length > 0) arrayOfKeys.map((a) => formData.append(a, data[a]));

			ApiFetcher.axiosInstance.post(url, formData)
				.then((response) => {
					console.log(response.data);
					resolve(response.data, response)
				})
				.catch((error) => {
					this._errorNotification(error);
					reject(error);
				})
		});
	}


	_errorNotification(error) {
		if (error.response) {
			// The request was made and the server responded with a status code that falls out of the range of 2xx
			if(error.response.status === 500) {
				window.$toast.error({
					body: 'Oops! The server produced an error. Please reload and try again.'
				});
			}
			if(error.response.status === 'ECONNABORTE') {
				window.$toast.error({
					body: 'No internet, Please check your internet connection and try again.'
				});
			}

			return;
		}
		if (error.request) {
			// The request was made but no response was received. Most likely a 'No Network Error' or perhaps '500 Network Error'.

			window.$toast.error({
				body: 'Please check your internet connection. A request was made but no response was received.'
			});
			return;
		}

		if(!error.request) {
			// Something happened in 'setting up' the request that triggered an Error
			window.$toast.error({
				body: "Oops! The browser couldn't send the request. Please reload and try again."
			});
			return;
		}

		window.$toast.error({
			body: 'There was an error. Please try again.'
		});
	}
}


export default ApiFetcher;