<template>
	<form class="relative" @input="slotAttribute.savedState=0">
	<modal-shell max="600">

		<template slot="toolbar">
			<router-link to="/" class="sm:!hidden">
				<svg-icon name="chevron-left" class="text-shade-0 block mr-1" size="1.8em"></svg-icon>
			</router-link>

			<div class="flex-none px-1">
				<h5 class="text-h5 m-0 p-0 top-0 font-semibold font-heading">
					<span v-if="slotAttribute.is_recurring" v-t="'modals.attributes.title_series'"></span>
					<span v-else v-t="'modals.attributes.title'"></span>
				</h5>
			</div>
		</template>


		<template slot="toolbarRight">
			<template v-if="$stores.user.isAdmin && $router.currentRoute.name !== 'edit-calendar-slots'">
				<router-link :to="{name: 'edit-calendar-slots', params:{date: date}}">
					<svg-icon name="gear" class="fill-shade-0 block mr-1" size="1.8em"></svg-icon>
				</router-link>
			</template>

			<router-link :to="{ name: 'home'}" class="xs-hide">
				<svg-icon name="close" class="fill-shade-0 block mr-1" size="1.8em"></svg-icon>
			</router-link>
		</template>


		<div class="bg-shade-0 h-full flex flex-col p-0">
			<div class="p-3 py-6 flex-auto space-y-3">


				<!-- delete booking view -->
				<template v-if="isDeleteView">
					<div>
						<h5 class="form-label pb-1" v-t="'modals.attributes.delete_text'"></h5>
						<a-radio-list v-model="deleteSelected" :options="$stores.calendar.deleteSlotAttributeOptions"></a-radio-list>
					</div>
				</template>

				<template v-else-if="isRepeatSelectView">
					<h5 class="form-label pb-1" v-t="'modals.repeat_options.frequency'"></h5>
					<a-radio-list :options="$stores.calendar.frequencyOptions" v-model.number="frequency"></a-radio-list>

					<template v-if="slotAttribute.recur_type!==0">
						<h5 class="form-label pb-1" v-t="'modals.repeat_options.duration'"></h5>
						<a-number :min="0" v-model.number="slotAttribute.recur_count" :valueDisplay="frequencyDurationText"></a-number>
					</template>
				</template>

				<template v-else>
					<a-checkbox name="unavailable" :label="$t('modals.attributes.is_unavailable')"
					            v-model="slotAttribute.is_unavailable"></a-checkbox>
					<p class="text-base" v-t="'modals.attributes.is_unavailable_help'"></p>

					<template v-if="!slotAttribute.is_unavailable">
						<a-checkbox name="exclusivable" :label="$t('modals.attributes.is_exclusive')"
						            v-model="slotAttribute.is_exclusivable"></a-checkbox>

						<a-select class="mt-2" name="theme" label="Theme" v-model.number="slotAttribute.theme">
							<option :value="0" v-t="'modals.attributes.no_theme'"></option>
							<option v-for="(t,index) in $stores.app.state.theme_options" :key="index"
							        v-text="t.label"
							        :value="Number(t.color)"
							        :class="`text-theme-${t.color}`"></option>
						</a-select>
					</template>

					<div v-if="!isEditing">
						<label class="form-label pb-1" v-t="'modals.change_duration.label'"></label>
						<a-number :min="$stores.app.state.slotlengthmins_number"
						          :max="60*24"
						          :valueDisplay="valueDisplay"
						          :step="$stores.app.state.slotlengthmins_number"
						          v-model="slotAttribute.duration">
						</a-number>

						<label class="form-label pb-1" v-t="'modals.repeat_options.label'"></label>

						<div class="flex justify-between items-center">
							<span class="font-medium" v-if="slotAttribute.recur_type===0" v-t="'modals.repeat_options.frequencies[0]'"></span>
							<span v-else class="font-medium" v-text="frequencyDurationText()"></span>
							<button @click="isRepeatSelectView=!isRepeatSelectView" class="btn-black -xxs" v-t="'modals.edit_btn'"></button>
						</div>
					</div>
				</template>
			</div>
		</div>

		<template slot="footer">
			<div class="flex space-x-2">
				<div v-if="isRepeatSelectView || isDeleteView" class="flex w-full space-x-2">
					<button @click.prevent="isRepeatSelectView=isDeleteView=false" class="btn-grey -sm w-full" v-t="'modals.back_btn'"></button>
					<button v-if="isDeleteView" class="btn-primary -sm w-full"
					        @click.prevent="onSubmitDelete()" v-t="'modals.delete_btn'"></button>
				</div>


				<button v-else-if="isEditing && slotAttribute.is_recurring" class="btn-grey -sm w-full"
				        @click.prevent="isDeleteView=true" v-text="$t('modals.delete_btn') + '...'">
				</button>

				<button v-else-if="isEditing" class="btn-primary -sm w-full"
				        @click.prevent="onSubmitDelete()" :disabled="slotAttribute.savedState===1" v-text="deleteBtnText"></button>

				<button v-if="!isDeleteView &! isRepeatSelectView" class="btn-primary -sm w-full"
				        @click.prevent="saveToServer()"
				        :disabled="slotAttribute.savedState===1"
				        v-text="saveBtnText">
				</button>

			</div>
		</template>
	</modal-shell>
	</form>
</template>

<script>
	import SvgIcon from "../components/SvgIcon";
	import ModalShell from "./ModalAShell";
	import ACheckbox from "../components/inputs/ACheckbox";
	import ASelect from "../components/inputs/ASelect";
	import ANumber from "../components/inputs/ANumber";
	import {SlotAttribute} from "../models/SlotAttribute";
	import ARadioList from "../components/inputs/ARadioList";
	import Datetime from "../Datetime";

	export default {
		components: {
			ARadioList,
			ANumber,
			ASelect,
			ACheckbox,
			ModalShell,
			SvgIcon,
		},
		name: 'modal-edit-calendar-slot',
		props: ['date'],
		data() {
			return {
				deleteSelected: 0,
				isEditing: false,
				isDeleteView: false,
				isRepeatSelectView: false,
				slotAttribute: {}
			}
		},
		created() {
			this.readOnlyOriginalSlot = this.$stores.slots.find(this.date);
			// Create new slotAttribute instance so that we don't effect the current slot.slotattrubutes state.
			if(this.readOnlyOriginalSlot.hasAttributes) {
				this.isEditing = true;
				// update existing
				this.slotAttribute = new SlotAttribute(
					this.readOnlyOriginalSlot.slotattributes.id,
					this.readOnlyOriginalSlot.slotattributes.start_time,
					this.readOnlyOriginalSlot.slotattributes.end_time,
					this.readOnlyOriginalSlot.slotattributes.is_exclusivable,
					this.readOnlyOriginalSlot.slotattributes.is_unavailable,
					this.readOnlyOriginalSlot.slotattributes.theme,
					this.readOnlyOriginalSlot.slotattributes.slot_id,
					this.readOnlyOriginalSlot.slotattributes.recur_count,
					this.readOnlyOriginalSlot.slotattributes.recur_type,
				);

			} else {
				this.readOnlyOriginalSlot = {slotattributes:{}};
				this.isEditing = false;

				// create
				this.slotAttribute = SlotAttribute.createEmpty();
				this.slotAttribute.start_time = Datetime.createFromStringTimestamp(this.date).formatToDatetimeString() + ':00';
			}

			console.log('umm',this.readOnlyOriginalSlot);
		},

		methods: {
			valueDisplay(val) {
				let time = Datetime.createFromStringTimestamp(this.date).addHours(val/60);

				if(this.$stores.app.state.config.is_12hr) time = time.formatToTimeAMPM();
				else time = time.formatToTime24Hour();

				if(val/60===1) {
					return this.$t('modals.change_duration.duration_hour', {D1:val/60, D2:time});
				}else {
					return this.$t('modals.change_duration.duration_hours', {D1:val/60, D2:time});
				}
			},

			saveToServer() {
				this.slotAttribute.saveToServer().then(()=>{
					this.$router.push({ name: 'home'});
				});
			},

			onSubmitDelete() {
				let deleteType = this.$stores.calendar.deleteOptions[this.deleteSelected][0];

				this.slotAttribute.remove(deleteType).then(()=>{
					this.isDeleteView = false;
					this.$router.push({ name: 'home'});
				});
			},

			frequencyDurationText(){
				return this.$stores.calendar.frequencyDurationText(this.slotAttribute.recur_type, this.slotAttribute.recur_count);
			}
		},

		computed: {
			saveBtnText() {
				let txt = '';
				switch(this.slotAttribute.savedState) {
					case (-1): txt = this.$t('modals.attributes.submit_state.error'); break;
					case (0): txt = this.$t('modals.attributes.submit_state.default'); break;
					case (1): txt = this.$t('modals.attributes.submit_state.loading'); break;
					case (2): txt = this.$t('modals.attributes.submit_state.loaded'); break;
				}
				return txt;
			},

			deleteBtnText() {
				let txt = '';
				switch(this.slotAttribute.deleteState) {
					case (-1): txt = this.$t('modals.attributes.delete_state.error'); break;
					case (0): txt = this.$t('modals.attributes.delete_state.default'); break;
					case (1): txt = this.$t('modals.attributes.delete_state.loading'); break;
					case (2): txt = this.$t('modals.attributes.delete_state.loaded'); break;
				}
				return txt;
			},


			frequency: {
				get() {
					return this.slotAttribute.recur_type;
				},
				set(val) {
					if(val === 0) this.slotAttribute.recur_count = 0;
					this.slotAttribute.recur_type = val;
				}
			},
		},
	}
</script>
