<template>
	<div v-show="isActive" class="absolute-fills">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'a-tab',
		props: {
			id: { required: true },
			name: { required: true },
			selected: { default: false }
		},

		data() {
			return {
				isActive: false
			};
		},

		computed: {
			href() {
				return '#' + this.id;
			}
		},

		mounted() {
			this.isActive = this.selected;
		}
	}
</script>

