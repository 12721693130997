<template>
	<div>
		<div v-for="(toastGroup, key) in toastsGroupedByPosition" :key="key" :class="`fixed ${key} w-full z-70`">
			<toast-item v-for="toast in toastGroup" :key="toast.id" :toast="toast"></toast-item>
		</div>
	</div>


</template>

<script>
	import ToastItem from "./ToastItem";
	import Toast from './Toast'

	export default {
		components: {ToastItem},
		name:'toast-controller',
		data() {
			return {
				toastStore: Toast
			}
		},

		methods:{
			position(toast) {
				if(toast.position === undefined) return `${this.toastStore.defaultPosition}`;
				if(toast.position === 'left') return 'left-0';
				if(toast.position === 'right') return 'right-0';
				if(toast.position === 'top') return 'top-0';
				if(toast.position === 'bottom') return 'bottom-0';

				throw new Error('Toast positions must be either undefined, top, bottom, left or right');
			},
		},
		computed: {
			toastsGroupedByPosition() {
				let obj = {
					'top-0':this.topToasts,
					'bottom-0':this.bottomToasts,
					'left-0':this.leftToasts,
					'right-0':this.rightToasts
				};

				obj[this.toastStore.defaultPosition] = this.defaultToasts;
				return obj;
			},

			defaultToasts(){
				return this.toastStore.toasts.filter(()=>this.toastStore.defaultPosition);
			},

			topToasts(){
				return this.toastStore.toasts.filter((c)=>c.position==='top');
			},

			bottomToasts(){
				return this.toastStore.toasts.filter((c)=>c.position==='bottom');
			},

			leftToasts(){
				return this.toastStore.toasts.filter((c)=>c.position==='left');
			},

			rightToasts(){
				return this.toastStore.toasts.filter((c)=>c.position==='right');
			}
		},
	}
</script>

<style>

</style>
