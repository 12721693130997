<template>
	<div class="flex flex-col">
		<div class="bg-shade-2">
			<div class="container container-inner flex justify-start flex-wrap mx-auto -mx-1 sm:-mx-4">
				<div v-for="tab in tabs" :key="tab.id" class="mb-3 px-1 sm:px-4" @click="selectTab(tab)">
					<a :href="tab.href" :class="{'!border-primary': tab.isActive }" class="px-0.5 font-bold text-shade-9 font-heading hover:no-underline border-b-3 border-shade-2">{{ tab.name }}</a>
				</div>
			</div>
		</div>
		<div class="relative">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'a-tabs',
		data() {
			return {
				tabs: [],
				selectedTab: ''
			};
		},

		created() {
			this.tabs = this.$children;
		},

		mounted() {
			let tab = this.tabs.find((tab)=>tab.isActive);
			this.$emit('selectedTab', tab.id);
		},

		methods: {
			selectTab(selectedTab) {
				this.tabs.forEach(tab => {
					tab.isActive = (tab.href == selectedTab.href);
				});

				this.$emit('selectedTab', selectedTab.id);
				this.selectedTab = selectedTab.id;
			}
		}
	}
</script>
