<template>
	<div>
		<div ref="dialog" v-show="visibility" class="relative">
			<div class="absolute bg-shade-1 p-2 shadow-2 rounded" style="top: 2.5rem">
				<input class="form-range -hue" type="range" v-model="hue" min="0" max="358" step="1" @input="setValueFromSliders">
				<input class="form-range -saturation" type="range" v-model="saturation" min="0" max="100" step="1" @input="setValueFromSliders">
				<input class="form-range -lightness" type="range" v-model="lightness" min="0" max="90" step="1" @input="setValueFromSliders">
				<input ref="test" class="form-input" :id="name" :name="name" :value="outputValue" @change="setValueFromHex($event.target.value, true)">
			</div>
		</div>

		<label class="form-label" :for="name" v-text="label"></label>
		<p class="text-base pt-0 mt-1 mb-1 sm:w-9/12" v-if="helpText" v-text="helpText"></p>
		<div class="flex items-end cursor-pointer">
			<div ref="dialogToggle" @click="toggleDialog" class="rounded border-2 border-shade-10 mr-1" :style="`width:3rem;height:3rem;background:${outputValue}`"></div>
		</div>
		<span class="error-message"></span>
	</div>
</template>
<script>
	export default {
		name: 'a-color-picker',
		inheritAttrs: false,
		data() {
			return {
				visibility: false,
				hue: 0,
				saturation: 0,
				lightness: 0,
				internalValue: undefined,
			}
		},
		props: {
			label:{
				type: String,
				required: true
			},
			helpText:{
				type: String,
				required: false
			},
			name: {
				type: String,
				required: true
			},
			value: {
				type: String
			},
		},
		created() {
			window.addEventListener('click', this.outsideEventListener);
		},
		beforeDestroy() {
			window.removeEventListener('click', this.outsideEventListener);
		},
		watch:{
			'value':{
				handler(newval, oldval){
					if(newval !== oldval) {
						this.setValueFromHex(newval, true);
					}
				},
				immediate: true
			}
		},
		methods: {
			outsideEventListener(e) {
				console.log("outsideEventListener");
				if(this.visibility) {
					if(! this.$refs.dialog.contains(e.target) && ! this.$refs.dialogToggle.contains(e.target)) {
						this.visibility = false;
					}
				}
			},
			setSliders() {
				let hsl = window.themify.hexToHSL(this.outputValue);
				this.hue = hsl.h;
				this.saturation = hsl.s;
				this.lightness = hsl.l;
			},

			toggleDialog() {
				this.setSliders();
				this.visibility = !this.visibility;
			},

			setValueFromSliders() {
				this.internalValue = window.themify.HSLToHex(this.hue, this.saturation,this.lightness);
				this.setValue();
			},

			setValue() {
				document.documentElement.style.setProperty('--color-picker-saturation', `hsl(${this.hue}, 100%, 50%)`);
				document.documentElement.style.setProperty('--color-picker-lightness', `hsl(${this.hue}, ${this.saturation}%, 50%)`);
				window.cssVars({onlyLegacy: true, variables: {
					'color-picker-saturation': `hsl(${this.hue}, 100%, 50%)`,
					'color-picker-lightness': `hsl(${this.hue}, ${this.saturation}%, 50%)`
				}})

				window.themify.setFromHex(this.outputValue);
				this.$emit('input', this.internalValue);
			},

			setValueFromHex(hex, setSlider) {
				if(/^#([0-9a-f]{6})$/i.test(hex)){
					this.internalValue = hex;
				} else {
					this.internalValue = undefined;
				}

				if(setSlider) this.setSliders();
				this.setValue();
			}
		},
		computed: {
			outputValue() {
				return  this.internalValue || window.themify.defaultHex;
			}
		}
	}
</script>
