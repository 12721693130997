<template>
	<div>
	<label class="form-label pb-1" :for="name" v-text="label"></label>
	<p class="text-base mb-4 sm:w-9/12" v-if="helpText" v-text="helpText"></p>

	<div class="form-textarea vorder p-0 relative" style="width: 120px;height:120px">
		<div class="overflow-hidden bg-shade-6 h-full">
			<!-- image preview -->
			<div v-if="!isLoading" class="cursor-pointer absolute inset-0 bg-cover bg-center" :style="`background-image: url(${file})`"></div>

			<!-- camera icon and loader -->
			<div class="absolute inset-0 flex items-center justify-center">
				<div v-if="isLoading" class="mx-auto loader" style="width: 3rem;height:3rem;border-width: 3px"></div>
				<svg-icon v-if="!file && !isLoading" name="camera" size="3rem" class="fill-shade-3 opacity-90" />
			</div>

			<!-- clickable and droppable area -->
			<input ref="file" :id="name" type="file" class="cursor-pointer absolute inset-0 opacity-0" @change="uploadFile" style="width: 120px;height:120px" />
		</div>

		<div @click="removeFile" v-if="file && !isLoading" class="cursor-pointer absolute rounded-circle bg-shade-0 border-2 border-shade-3 flex items-center justify-center" style="top:-1rem;right:-1rem;width:2rem;height:2rem;">
			<svg-icon name="close" size="1.5rem" class="fill-shade-4" />
		</div>
	</div>

	<span class="error-message"></span>
	</div>
</template>
<script>
import SvgIcon from "@/components/SvgIcon";
import app from "@/stores/App";
export default {
	name: 'file-upload-and-save-to-config',
	components: {SvgIcon},
	props:{
		name:{
			type: String,
			required: true
		},
		label:{
			type: String,
			required: true
		},
		helpText:{
			type: String,
			required: false
		}
	},
	data() {
		return {
			isLoading: false,
			file: ''
		}
	},
	created() {
		this.file = app.state.config.group.custom_logo
	},
	methods: {
		uploadFile(){
			if(this.$refs.file.files.length === 0) return;

			this.isLoading = true;

			window.apiFetcher.post('/customlogo', {logo: this.$refs.file.files[0]})
				.then((response) => {
					console.log('then',response);
					this.file = response.url;
					app.state.config.group.custom_logo = this.file;
					this.isLoading = false;
					this.$toast.good({body: this.$t('admin_settings.friendsfamily.custom_logo.success_upload')})
				})
				.catch((error) => {
					this.isLoading = false;
					console.log('catch',error, error.response);
					if(error.response && error.response.data && error.response.data.errors && error.response.data.errors.length>0) {
						window.$toast.error({body: error.response.data.errors[0].message});
					}else {
						window.$toast.error({body: this.$t('admin_settings.friendsfamily.custom_logo.errors.generic_upload')});
					}
				});
		},

		removeFile() {
			this.isLoading = true;
			window.apiFetcher.post('/customlogo', {remove: 1})
				.then(() => {
					this.isLoading = false;
					this.file = '';
					app.state.config.group.custom_logo = undefined;
					this.$toast.good({body: this.$t('admin_settings.friendsfamily.custom_logo.success_remove')});
				})
				.catch((error) => {
					this.isLoading = false;
					if(error.response && error.response.data && error.response.data.errors && error.response.data.errors.length>0) {
						window.$toast.error({body: error.response.data.errors[0].message});
					}else {
						window.$toast.error({body: this.$t('admin_settings.friendsfamily.custom_logo.errors.generic_remove')});
					}
				});
		}
	}
}
</script>