<template>
	<div :class="`calendar-navigation hide-print ${isBottom?'-fixed p-1':'flex items-center justify-between'}`">

		<div :class="`${isBottom?'flex justify-between':''}`">
			<button e2e="navStepBack" class="btn-grey -xs rounded-circle !px-1.7"
			        @click="$stores.calendar.stepBack()">
				<svg-icon class="block text-current" name="chevron-left" size="1.6em"></svg-icon>
			</button>
			<button v-if="$stores.calendar.isTodayWithinEventRange"
			        e2e="navStepToday" class="btn-grey -xs mx-2"
			        @click="$stores.calendar.stepToToday(true)"
			        v-t="$breakpoint.val==='xs'?'topbar.today':'topbar.this_week'">
			</button>
			<button e2e="navStepForward" class="btn-grey -xs rounded-circle !px-1.7"
			        @click="$stores.calendar.stepForward()">
				<svg-icon :class="`block text-current`" name="chevron-right" size="1.6em"></svg-icon>
			</button>
		</div>

	</div>
</template>

<script>
	import SvgIcon from "../SvgIcon";
	export default {
		props:['position'],
		name: 'calendar-navigation',
		components: {SvgIcon},
		computed:{
			isBottom() {
				if(this.position === 'bottom') return true;
				else return false;
			}
		},
	}
</script>