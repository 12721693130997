import Datetime from "../Datetime";

export class SlotBooking {
	static createEmpty() {
		return new SlotBooking(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,undefined, undefined, undefined, undefined);
	}

	constructor(id,	start_time, end_time, is_owner, name, is_anonymous, is_exclusive, slot_id, recur_type, recur_count, email, mobile, duration) {
		this.id = id;
		this.name = name;
		if(this.name === '') this.name = 'Unknown';

		this.email = email;
		if(window.store.app.state.config.has_mobile) this.mobile = mobile || '';
		this.duration = duration;

		this.start_time = start_time;
		this.end_time = end_time;
		this.is_owner = is_owner;
		this.is_anonymous = is_anonymous;
		this.is_exclusive = is_exclusive;

		this.recur_type = Number(recur_type) || 0;
		this.recur_count = Number(recur_count) || 0;

		if(slot_id) {
			this.slot_id = slot_id;
			SlotBooking.getRootSlot = () => {
				//todo - is this a good idea? Could create inconsistencies with server.
			}
		}
	}

	setDuration() {
		if(!this.end_time || !this.start_time) {
			console.error('SlotBooking cant setDuration due to missing end_time or start_time');
			return;
		}
		this.duration = Datetime.createFromServerString(this.start_time).diffMinutes(Datetime.createFromServerString(this.end_time));
	}

	get isRecurring() {
		return this.recur_type===1 || this.recur_type===2 || this.slot_id;
	}

	get iCalLink() {
		if(!this.id) {
			console.error('SlotBooking cant getICalLink due to missing id');
			return undefined;
		}
		return `${window.rootUrl}/_api/export?id=${this.id}`;
	}

	get googleCalLink() {
		if(!this.id) {
			console.error('SlotBooking cant getGoogleCalLink due to missing id');
			return undefined;
		}

		let origin = window.location.origin.replace(window.location.protocol+'//', '');
		return `https://www.google.com/calendar/render?cid=webcal://${origin}${window.rootUrl}/_api/export?id=${this.id}`;
	}

	get durationAsHours() {
		return this.duration/60;
	}

	printName(prefix = '(A)') {
		let name = this.name;

		if(!this.is_anonymous && !window.store.app.state.config.is_anonymous){
			return name;
		}

		if(
			window.store.user.isAdmin
			|| ((this.is_anonymous || window.store.app.state.config.is_anonymous) && this.is_owner)
		) {
			return prefix+' '+name;
		}

		return prefix;
	}


	get toObject() {
		let obj = {
			name: this.name,
			email: this.email,
			exclusive: this.is_exclusive?1:0,
			anonymous: this.is_anonymous?1:0,
			id : this.id,
			mobile : this.mobile,
			recur_type : this.recur_type,
			recur_count : this.recur_count,
			duration : this.duration,
			start_time : this.start_time,
			end_time : this.end_time,
		};

		return JSON.parse(JSON.stringify(obj));
	}


	get toObjectForCreate() {
		let obj = {
			name: this.name,
			email: this.email,
			exclusive: this.is_exclusive?1:0,
			anonymous: this.is_anonymous?1:0,
			duration: this.duration,
			start_time: this.start_time,
			end_time: this.end_time
		};
		if(!obj.mobile) obj.mobile = this.mobile;
		if(this.recur_type > 0) {
			obj.recur_type = this.recur_type;
			obj.recur_count = this.recur_count;
		}

		return JSON.parse(JSON.stringify(obj));
	}


	get toObjectForUpdate() {
		let obj = {
			id: this.id,
			name: this.name,
			email: this.email,
			exclusive: this.is_exclusive?1:0,
			anonymous: this.is_anonymous?1:0,
			duration: this.duration
		};
		if(this.mobile) obj.mobile = this.mobile;

		return JSON.parse(JSON.stringify(obj));
	}


	get toObjectForDelete() {
		let obj = {
			id: this.id
		};

		return JSON.parse(JSON.stringify(obj));
	}
}
