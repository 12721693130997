<template>
	<form method="post" @input="$stores.app.state.settingsLoaded = 0" @submit.prevent="onSubmit">
		<div>
			<a-tabs @selectedTab="(val)=>tabSelected=val">
				<a-tab id="general" :name="$t('admin_settings.general.title')" selected="true">
					<div class="container-inner py-6 mb-6 space-y-3">
						<a-select class="w-full" name="display_times" :label="$t('admin_settings.general.display_times')" v-model="slaveConfig.is_12hr">
							<option :value="true" v-t="'admin_settings.general.am_pm'"></option>
							<option :value="false" v-text="$t('admin_settings.general.number_hour', {'D1':24})"></option>
						</a-select>

						<a-select class="w-full" name="calendar_granularity" :label="$t('admin_settings.general.calendar_granularity')" v-model="slaveConfig.slotlengthmins">
							<option value="30" v-text="$t('admin_settings.general.number_minutes', {'D1':30})"></option>
							<option value="60" v-text="$t('admin_settings.general.number_hour', {'D1':1})"></option>
							<option value="120" v-text="$t('admin_settings.general.number_hours', {'D1':2})"></option>
							<option value="360" v-text="$t('admin_settings.general.number_hours', {'D1':6})"></option>
						</a-select>

						<div class="pt-2">
							<a-checkbox class="mb-2" v-model="slaveConfig.is_anonymous" name="is_anonymous" :label="$t('admin_settings.general.is_anonymous')"></a-checkbox>
							<a-checkbox class="mb-2" v-model="slaveConfig.is_exclusivable" name="allow_exclusive" :label="$t('admin_settings.general.allow_exclusive')"></a-checkbox>
							<a-checkbox class="mb-2" v-model="slaveConfig.is_autoexclusive" name="auto_exclusive" :label="$t('admin_settings.general.auto_exclusive')"></a-checkbox>
							<a-checkbox class="mb-2" v-model="slaveConfig.require_email" name="require_exclusive_booking" :label="$t('admin_settings.general.require_exclusive_booking')"></a-checkbox>
							<a-checkbox class="mb-2" v-model="slaveConfig.require_login" name="require_login" :label="$t('admin_settings.general.require_login')"></a-checkbox>
							<a-checkbox class="mb-2" v-model="slaveConfig.has_mobile" name="show_mobile_field" :label="$t('admin_settings.general.show_mobile_field')"></a-checkbox>
						</div>
					</div>
				</a-tab>

				<template v-if="app.state.config.has_advancedfeatures">
					<a-tab id="friendsfamily" :name="$t('admin_settings.friendsfamily.title')">
						<div class="container-inner py-6 mb-6">
							<p class="form-label" v-t="$t('admin_settings.friendsfamily.description')"></p>
							<br />
							<file-upload-and-save-to-config
								name="file-upload"
								:label="$t('admin_settings.friendsfamily.custom_logo.label')"
								:help-text="$t('admin_settings.friendsfamily.custom_logo.help_text')"/>
							<br />
							<br />
							<a-color-picker
								:label="$t('admin_settings.friendsfamily.color_picker.label')"
								:help-text="$t('admin_settings.friendsfamily.color_picker.help_text')"
								name="color-picker"
								v-model="custom_colour" />
						</div>
					</a-tab>
				</template>

				<a-tab id="messages" :name="$t('admin_settings.messages.title')">
					<div class="container-inner py-6 mb-6 space-y-3">
						<a-textarea v-model="slaveConfig.message" name="message" :label="$t('admin_settings.messages.message')"></a-textarea>

						<div>
							<p class="form-label pb-1" v-t="'admin_settings.messages.email_reminder'"></p>
							<div class="flex">
								<a-checkbox class="w-6/12 sm:w-4/12 mb-2" v-model="slaveConfig.reminder_1hr" name="reminder_1hr" :label="$t('admin_settings.messages.reminder_1hr')"></a-checkbox>
								<a-checkbox class="w-6/12 sm:w-4/12 mb-2" v-model="slaveConfig.reminder_12hrs" name="reminder_12hrs" :label="$t('admin_settings.messages.reminder_12hrs')"></a-checkbox>
							</div>
							<div class="flex">
								<a-checkbox class="w-6/12 sm:w-4/12 mb-2" v-model="slaveConfig.reminder_24hrs" name="reminder_24hrs" :label="$t('admin_settings.messages.reminder_24hrs')"></a-checkbox>
								<a-checkbox class="w-6/12 sm:w-4/12 mb-2" v-model="slaveConfig.reminder_1week" name="reminder_1week" :label="$t('admin_settings.messages.reminder_1week')"></a-checkbox>
							</div>
						</div>

						<a-textarea v-model="slaveConfig.message_reminder" name="message_reminder" :label="$t('admin_settings.messages.message_reminder')"></a-textarea>

						<a-textarea v-if="app.state.config.signup_smsreminder_24hrs" v-model="slaveConfig.message_smsreminder" name="message_smsreminder" :label="$t('admin_settings.messages.message_smsreminder')"></a-textarea>
					</div>
				</a-tab>
				<a-tab id="themes" :name="$t('admin_settings.themes.title')">
					<div class="container-inner py-6 mb-6">

						<p class="form-label pb-1" v-t="'admin_settings.themes.description[0]'"></p>
						<p class="mb-5" v-t="'admin_settings.themes.description[1]'"></p>

						<div v-for="(theme, index) in slaveConfig.themes" :key="theme.id" :class="`theme-input-${theme.color} ${index===0?'rounded-t':''} ${index===slaveConfig.themes.length-1?'rounded-b':''}`">
							<input :class="`${theme.label!==''?'bold':'italic'} w-full p-2 border-0 bg-transparent text-theme-${theme.color}-dark p m-0 top-0`"
							       type="text" v-model="theme.label" :placeholder="$t('admin_settings.themes.placeholder')">
						</div>
					</div>
				</a-tab>
				<a-tab id="export" :name="$t('admin_settings.export.title')">
					<div class="container-inner py-6 mb-6 space-y-1">

						<p class="form-label pb-1" v-t="'admin_settings.export.description[0]'"></p>
						<p v-t="'admin_settings.export.description[1]'"></p>

					</div>
				</a-tab>
			</a-tabs>
			<div class="container-inner pb-6 mb-6 flex space-x-3">
				<router-link to="/" class="btn-grey flex-auto" v-t="'admin_settings.cancel'"></router-link>

				<button v-if="tabSelected!=='export'" class="btn-primary flex-auto" type="submit" :disabled="$stores.app.state.settingsLoaded===1" v-text="btnText"></button>
				<a v-else class="btn-primary flex-auto" :href="`${apiBaseUrl}/csv`" v-t="'admin_settings.export.btn'"></a>
			</div>
		</div>
	</form>
</template>

<script>
	import Page from '@/stores/Page';
	import ATabs from "../components/ATabs";
	import ATab from "../components/ATab";
	import ASelect from "../components/inputs/ASelect";
	import ACheckbox from "../components/inputs/ACheckbox";
	import ATextarea from "../components/inputs/ATextarea";
	import ApiFetcher from "../ApiFetcher";
	import App from "@/stores/App";
	import AColorPicker from "@/components/inputs/AColorPicker";
	import FileUploadAndSaveToConfig from "@/components/inputs/file-upload-and-save-to-config";

	export default {
		components: {
			FileUploadAndSaveToConfig,
			AColorPicker,
			ATextarea,
			ACheckbox,
			ASelect,
			ATab,
			ATabs},
		name: 'page-settings',
		data(){
			return {
				test: undefined,
				app:App,
				page: Page,
				title: this.$t('admin_settings.title'),
				slaveConfig: {},
				tabSelected: '',
				apiBaseUrl: ''
			}
		},

		created() {
			this.page.title=this.title;

			// clone the config object so that we don't manipulate the original source
			// and make it dirty if the user clicks cancel instead of saving
			this.slaveConfig = JSON.parse(JSON.stringify(this.$stores.app.state.config));

			this.apiBaseUrl = ApiFetcher.axiosInstance.defaults.baseURL;
		},

		beforeDestroy() {
			var val = this.$stores.app.state.config.group.custom_colour;

			// reset to default if nothing is set
			if(!val || val === '') val = window.themify.defaultHex;

			// remove # if it's there
			val = val.replace('#', '');

			//User did not save settings, resetting value.
			window.themify.setFromHex('#'+val);
		},

		methods: {
			onSubmit() {
				this.$stores.app.saveToServer(this.slaveConfig).then(()=>{
					this.$stores.app.state.settingsLoaded = 0;

					this.$router.push({name: 'home'});
					window.$toast.good({
						body: this.$t('admin_settings.success')
					});
				})
			}
		},

		computed: {
			// add and remove '#' for the
			custom_colour: {
				get() {
					if(this.slaveConfig.group.custom_colour) return '#' + this.slaveConfig.group.custom_colour.replace('#', '');
					return undefined;
				},
				set(val){
					if(val) this.slaveConfig.group.custom_colour = val.replace('#', '');
					else this.slaveConfig.group.custom_colour = val;
				}
			},
			btnText() {
				let txt = '';
				switch(this.$stores.app.state.settingsLoaded) {
					case (-1): txt = this.$t('admin_settings.submit_state.error'); break;
					case (0): txt = this.$t('admin_settings.submit_state.default'); break;
					case (1): txt = this.$t('admin_settings.submit_state.loading'); break;
					case (2): txt = this.$t('admin_settings.submit_state.loaded'); break;
				}
				return txt;
			}
		},
	}
</script>
