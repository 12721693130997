import Datetime from "../Datetime";
import UserRepo from "./UserRepo";

let app = {
	state: {
		loaded: 0,
		settingsLoaded: 0,
		config:{
			group:{},
		},

		//additional state settings
		get themes_total() {
			if(!app.state.config.themes) return 0;
			return app.state.config.themes.reduce((a,c) => {
				return a + (c.label != ''?1:0);
			},0)
		},

		get theme_options() {
			if(!app.state.config.themes) return 0;
			let cc = app.state.config.themes.reduce((a,c)=>{
				console.log();
				if(c.label!=='') a.push(c);
				return a;
			}, []);
			console.log(cc);

			return cc;
		},

		is_8_day: false,
		set_is_8_day() {
			if(!app.state.config) return false;

			let start = Datetime.createFromServerString(app.state.config.start_time).setHours(0).setMinutes(0);
			let end = Datetime.createFromServerString(app.state.config.end_time).setHours(23).setMinutes(59);
			let days = start.diffDays(end, true);

			let result = days > 7 && days <= 8;
			app.state.is_8_day = result;

			return result;
		},

		get slotlengthmins_number() {
			if(!app.state.config) return 0;
			return Number(app.state.config.slotlengthmins);
		},

		get slots_per_day() {
			if(!app.state.config) return 0;
			return 24/(Number(app.state.config.slotlengthmins)/60);
		},

		get slot_daily_times_array() {
			if(!app.state.config) return 0;
			let array = [];

			for (let i = 0; i < app.state.slots_per_day; i++) {
				let s = "0" + i*app.state.slotlengthmins_number/60;

				array.push(s);
			}

			return array;
		},

	},

	prayerResources: 'https://www.24-7prayer.com/how-to-pray/help-me',
	donateLink: 'https://www.24-7prayer.com/donate',
	feedbackLink: 'https://www.24-7prayer.com/about/about-us/contact',
	privacyPolicyLink: 'https://www.24-7prayer.com/privacy-policy',
	termsAndConditionsLink: 'https://signup.24-7prayer.com/prayer-signup-terms',
	forgotPassword: 'https://accounts.24-7prayer.com/forgot-password',
	
	get prayerRoomHelpLink() {
		return 'https://www.24-7prayer.com/prayer-room-help';
	},
	get adminPrayerRoomHelpLink() {
		return 'https://www.24-7prayer.com/admin-prayer-room-help';
	},


	referrer: {},


	//shell settings and methods
	showAdminFlashMessage: true,
	hasGuestAgreedPrivacyPolicyOnce: false,
	isShowingSidebar: false,
	isSiteBlurred: false,
	isMainBlurred: false,
	blurAll() { app.isSiteBlurred = true; },
	focusAll() { app.isSiteBlurred = false; },
	showSidebar(){
		app.focusAll();
		app.isMainBlurred = true;
		app.isShowingSidebar=true;
	},
	hideSidebar(){
		app.focusAll();
		app.isMainBlurred = false;
		app.isShowingSidebar=false;
	},

	getFromServer() {
		return new Promise((resolve, reject)=>{
			this.state.loaded = 1;
			window.apiFetcher.get('/payload', {})
				.then((response) => {
					this.parsePayload(response);
					this.state.loaded = 2;
					resolve();
				})
				.catch(() => {
					this.state.loaded = -1;
					reject();
				})
		});
	},

	parsePayload(response) {
		this.state.loaded = 1;
		response.config.themes = this.parseThemes(response.config.themes);
		app.state.config = Object.assign({},app.state.config, response.config);

		let interval = app.state.slotlengthmins_number;
		app.state.config.start_time =
			Datetime.createFromServerString(app.state.config.start_time)
				.toNearestMinute(interval)
				.formatToDatetimeString();

		let end = Datetime.createFromServerString(app.state.config.end_time).toNearestMinute(interval);
		if(end.getHours() === 0 && end.getMinutes() === 0) end.addMinutes(-1);
		app.state.config.end_time = end.formatToDatetimeString();

		console.log(app.state.config.start_time, app.state.config.end_time);

		app.state.set_is_8_day();

		delete app.state.config.is_admin; // we will remove this and store it in 'user' instead.

		UserRepo.updateUserFromRequest(response);

		window.store.calendar.setCalendarDateScope();

		if(response.events) window.store.slots.parseEventsToTimeStamp(response.events);
		this.state.loaded = 2;
	},

	saveToServer(data) {
		let payload = {
			slotlength: data.slotlengthmins,
			timeformat: data.is_12hr==='true'?1:0,
			autoexclusive: data.is_autoexclusive?'1':'',
			exclusivable: data.is_exclusivable?'1':'',
			anonymous: data.is_anonymous?1:0,
			hasmobile: data.has_mobile?'1':'',
			requireemail: data.require_email?'1':'',
			requirelogin: data.require_login?'1':'',
			message: data.message?data.message:'',
			message_reminder: data.message_reminder?data.message_reminder:'',
			message_smsreminder: data.message_smsreminder?data.message_smsreminder:'',
			reminder_1hr: data.reminder_1hr?'1':'',
			reminder_12hrs: data.reminder_12hrs?'1':'',
			reminder_24hrs: data.reminder_24hrs?'1':'',
			reminder_1week: data.reminder_1week?'1':'',
			custom_colour: data.group && data.group.custom_colour ? data.group.custom_colour:'',
		};

		if(data.themes) data.themes.map(a => {
			if(a.label && a.label !=='') payload[`theme[${a.id+1}]`] = a.label;
		});

		this.state.settingsLoaded = 1;

		return new Promise((resolve, reject) => {
			window.apiFetcher.post('/savesettings', payload)
				.then((response) => {
					this.parsePayload(response);
					this.state.settingsLoaded = 2;
					resolve();
					
					// remove all slots - needed for when we change duration.
					window.store.slots.empty();
					this.getFromServer();
					
				})
				.catch((error) => {
					console.log(error);
					this.state.settingsLoaded = -1;
					reject(error);
				})
		});
	},


	parseThemes(newThemesObject) {
		// let colors = ['purple', 'blue', 'turquoise', 'green', 'yellow', 'orange'];

		return new Array(6).fill('').map((a, b) =>{
			return {
				id: b,
				color: b+1,
				label: newThemesObject[b+1] || ''
			}
		});
	},


	redirectStore(navigationObj, returnObjOnly) {
		localStorage.setItem('247-prayer-redirect', window.store.router.currentRoute.path);
		if (!returnObjOnly) window.store.router.push(navigationObj);
		else return navigationObj;
	},

	redirectFromStored(returnOnly, defaultUrl) {
		defaultUrl = defaultUrl || '/';
		let redirect = localStorage.getItem('247-prayer-redirect') || false;
		localStorage.removeItem('247-prayer-redirect');

		if(redirect) {
			if (!returnOnly) window.store.router.push(redirect);
			else return redirect;
		} else {
			if (!returnOnly) window.store.router.push(defaultUrl);
			else return defaultUrl;
		}
	}
};

export default app;
