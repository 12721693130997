<template>
	<div id="time-line" class="cal_time-line hide-print" :style="`transform: translateY(${translateY}px);`"></div>
</template>

<script>

	import Datetime from "@/Datetime";

	export default {
		name: 'time-line',

		data() {
			return {
				time: 0,
				translateY: 0
			}
		},

		mounted() {
			this.createTimeInterval();
		},

		beforeDestroy() {
			clearInterval(this.interval);
		},

		methods: {
			createTimeInterval() {
				this.interval = setInterval(this.setTimeLine, 1000);
				this.setTimeLine();
			},

			setTimeLine() {
				this.setTime();
				this.setPosition();
			},

			setTime() {
				this.time = Datetime.now();
			},

			setPosition() {
				let cellHeight = 80;
				let slots_per_day = this.$stores.app.state.slots_per_day;
				let slotlengthmins_number = this.$stores.app.state.slotlengthmins_number;
				let hour = this.time.getHours();
				let minute = this.time.getMinutes();

				let hourToPX = window.scale(hour, 0, 24, 0, slots_per_day * cellHeight);
				let minuteToPX = window.scale(minute, 0, slotlengthmins_number, 0, cellHeight);

				this.translateY = hourToPX + minuteToPX;
			}
		}
	}
</script>