import Toast from './components/Toast/Toast';
import App from "./stores/App";
import Datetime from "./Datetime";

export default {
	checkUser(to, from, next){
		if(window.store.user.isLoggedIn){
			next();
			return true;
		}

		next({path: from.path});
		Toast.error({body: 'You do not have user access'});
		return false;
	},
	checkAdmin(to, from, next) {
		if(window.store.user.isAdmin){
			next();
			return true;
		}

		next({path: from.path});
		Toast.error({body: 'You do not have admin access'});
		return false;
	},

	checkUserAndNotAdmin(to, from, next) {
		if(window.store.user.isLoggedIn && !window.store.user.isAdmin){
			next();
			return true;
		}

		if(window.store.user.isAdmin){
			next({path: from.path});
			Toast.error({body: 'Admins have no need to access this area'});
			return false;
		}

		next({path: from.path});
		Toast.error({body: 'You do not have user access'});
		return false;
	},

	checkNotAdmin(to, from, next) {
		if(window.store.user.isAdmin){
			next({path: from.path});
			Toast.error({body: 'Admins have no need to access this area'});
			return false;
		}

		next();
		return true;
	},

	beforeViewSlot(to, from, next) {
		let dateParam = Datetime.createFromStringTimestamp(to.params.date);

		let checks = [
			{
				message: 'The date is invalid, please try again',
				passed: dateParam.isValid()
			},

			{
				message: 'The requested date is not within the event period, please try again',
				passed: dateParam.isAfterOrSame( Datetime.createFromServerString(App.state.config.start_time)) && dateParam.isBeforeOrSame(Datetime.createFromServerString(App.state.config.end_time))
			}
		];

		let c = this.runChecks(checks);

		if(c.passed) {
			next();
		}else {
			Toast.error({body: c.error.message});
			next({path: from.path});
		}
	},

	beforeEnterEditBooking(to, from, next) {
		let checks = [
			{
				message: 'We can not find this booking',
				passed: window.store.slots.state.slotIds.find((a)=>a === to.params.id)
			},

			{
				message: 'You do not have access to edit this booking',
				passed: ()=>{
					let findBooking = window.store.slots.findIdBySlotTimestamp(to.params.date, to.params.date);
					return window.store.user.isAdmin || findBooking.is_owner;
				}
			}
		];

		let c = this.runChecks(checks);

		if(c.passed) {
			next();
		}else {
			Toast.error({body: c.error.message});
			next({path: from.path});
		}
	},

	beforeEnterEditCalendarSlot(to,from,next) {
		if(this.checkAdmin(to,from,next)) {
			let date = Datetime.createFromStringTimestamp(to.params.date);

			let checks = [
				{
					message: 'The date is invalid, please try again',
					passed: date.isValid()
				},
				{
					message: 'The requested date is not within the event period, please try again',
					passed: date.isAfterOrSame( Datetime.createFromServerString(App.state.config.start_time)) && date.isBeforeOrSame(Datetime.createFromServerString(App.state.config.end_time))
				}
			];

			let c = this.runChecks(checks);

			if(c.passed) {
				next();
			}else {
				Toast.error({body: c.error.message});
				next({path: from.path});
			}
		}
	},

	beforeEnterBooking(to, from, next) {
		console.log(to.params.from, to.params.to);
		let fromParam = Datetime.createFromStringTimestamp(to.params.from);
		let toParam = Datetime.createFromStringTimestamp(to.params.to);

		let checks = [
			{
				message: 'The "from" or "to" date is invalid, please try again',
				passed: toParam.isValid() && fromParam.isValid()
			},

			{
				message: 'The "from" date starts after the "to" date, please try again',
				passed: fromParam.isBeforeOrSame(toParam)
			},

			{
				message: 'The requested dates are not within the event period, please try again',
				passed: fromParam.isAfterOrSame(Datetime.createFromServerString(App.state.config.start_time)) && toParam.isBeforeOrSame(Datetime.createFromServerString(App.state.config.end_time))
			}
		];

		let c = this.runChecks(checks);

		if(c.passed) {
			next();
		}else {
			Toast.error({body: c.error.message});
			next({path: from.path});
		}
	},



	runChecks(checks) {
		for(let i = 0; i < checks.length; i++) {
			if(!checks[i].passed) return {passed: false, error: checks[i] };
		}

		return {passed: true};
	}
}
