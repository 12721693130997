<template>
	<div ref="xAxisContainer" style="width:100%;height:68px;will-change: transform;">

		<div class="absolute top-0 left-0 flex w-full text-h5" v-for="(week, index) in visibleGrid" :key="index" :style="contentStyle(week.x)">

			<div class="z-20 cal_x-axis-item"  v-for="(day, dayIndex) in week.days" :key="dayIndex" :style="`width: ${100/week.days.length}%`" @click="scrollToDate(day.fullDate)">
				<div class="capitalize" v-text="day.weekday"></div>
				<div :class="dayDigitStyles(day)" v-text="day.date"></div>
			</div>

		</div>

	</div>
</template>

<script>
  import Datetime from "@/Datetime";

	export default {
		name: 'x-axis',

		data() {
			return {
				startX: 0,
				visibleGrid: {},
			}
		},

		watch: {
			'$stores.calendar.startX'(newVal, oldVal) {
				if (newVal !== oldVal) this.updateLayout();
			},
			'$stores.calendar.xPercent'() {
				this.translateX();
			},
			'$breakpoint.val'() {
				this.oldStartOfWeekDateString = undefined;
				this.translateX();
			}
		},

		mounted() {
			this.updateLayout();
			this.$stores.calendar.event.$on('numberOfDaysInView-changed', this.updateLayout);

			// todo: remove "this.$stores.calendar" dependency
			this.$stores.calendar.event.$on('locale-changed', () => {
				this.updateLayout(true);
				this.$forceUpdate();
			});
		},

		beforeDestroy() {
			this.$stores.calendar.event.$off('numberOfDaysInView-changed', this.updateLayout);
		},

		updated() {
			console.log('xAxis updated');
		},

		methods: {
			scrollToDate(date) {
				if(this.$breakpoint.val !== 'xs') return;
				this.$stores.calendar.scrollToDate(date, true);
			},
			translateX() {
				if (!this.$refs['xAxisContainer']) return;

				if (this.$stores.calendar.numberOfDaysInView === 7)
					this.$refs['xAxisContainer'].style.transform = 'translateX(' + this.$stores.calendar.xPercent + '%)';
				else
					this.$refs['xAxisContainer'].style.transform = '';
			},
			updateLayout(forceUpdate = false) {
				this.startX = Math.floor(this.$stores.calendar.startX / this.$stores.calendar.numberOfDaysInView);
				this.setXAxisVisibleGrid(forceUpdate);
				this.translateX();
			},

			weekday(date) {
				let result = '';
				switch (this.$breakpoint.val) {
					case 'xs':
					case 'sm':
						result = date.getDayShortName();
						break;
					case 'md':
					case 'lg':
						result = date.getDayLongName();
						break;
				}
				return result;
			},

			dayDigitStyles(styles) {
				let style = 'cal_x-axis-item-num';

				if(styles.isToday) style += ' text-shade-0 bg-primary font-medium';
				else if(this.$breakpoint.val==='xs' && styles.isCurrent) style += ' bg-shade-2-dark';

				return style;
			},

			contentStyle(x) {
				if(this.$stores.calendar.numberOfDaysInView === 1) {
					return `width:100%;will-change: transform;`
				} else {
					return `transform:translateX(${(100 * x)}%);width:100%;will-change: transform;`
				}
			},


			setXAxisVisibleGrid(forceUpdate = false) {
				console.time('setXAxisVisibleGrid');
				if (!this.$stores.calendar.currentDate) return {};
				let startOfWeek;
				if (this.$stores.calendar.totalDays <= 8) {
					startOfWeek = this.$stores.calendar.originalStartDate.clone().setClock(0, 0);
				}
				else startOfWeek = this.$stores.calendar.currentDate.clone().startOfWeek().setClock(0, 0);

				let dateString = startOfWeek.formatToDateString();
				let startWeekTime = startOfWeek.getTime();
				let isCurrent = this.$stores.calendar.currentDate.getTime();
				let todaysDateString = 'day-' + Datetime.today().formatToDateString();
				let daysNumber = this.$parent.is_8_day ? 8 : 7;

				// check if the start of the week has changed.
				// This improves performance by avoiding a re-render of the component if it is not needed.
				if(!forceUpdate){
					if (Object.keys(this.visibleGrid).length > 0 && dateString === this.oldStartOfWeekDateString) {
						if (this.$stores.calendar.numberOfDaysInView === 1) {
							let newWeek = {};
							Object.keys(this.visibleGrid).forEach((weekString) => {
								let days = {};
								Object.keys(this.visibleGrid[weekString].days).forEach((key) => {
									let day = this.visibleGrid[weekString].days[key];
									day.isToday = key === todaysDateString;
									day.isCurrent = day.time === isCurrent;
									days[key] = day;
								});
								newWeek[weekString] = {days: days};
							});
							this.visibleGrid = newWeek;
						}
						return;
					}
				}
				this.oldStartOfWeekDateString = dateString;


				let weeks = {};
				weeks[dateString] = {x: this.startX, days: {}};

				if (this.$stores.calendar.numberOfDaysInView === 7) {
					weeks[startOfWeek.addWeeks(1).formatToDateString()] = {x: this.startX + 1, days: {}};
					startOfWeek.addWeeks(-1); // reset
				}


				Object.keys(weeks).forEach((week, weekIndex) => {
					for (let day = 0; day < daysNumber; day++) {
						let d = startOfWeek.setTime(startWeekTime).addWeeks(weekIndex).addDate(day);
						let fullDate = d.formatToDateString();
						let key = 'day-' + fullDate;
						let date = d.getDate();
						let time = d.getTime();

						weeks[week].days[key] = {
							weekday: this.weekday(d),
							date: window.padd(date),
							fullDate: fullDate,
							isToday: key === todaysDateString,
							isCurrent: isCurrent === time,
							time: time
						};
					}
				});

				this.visibleGrid = weeks;
				console.timeEnd('setXAxisVisibleGrid');
			},
			//
		}

	}
</script>