export class Polyfillers {
	constructor(w) {
		this.matches(w);
		this.closest(w);
		this.padd(w);
		this.scale(w);
	}

	scale(w) {
		w.scale = (num, in_min, in_max, out_min, out_max) => {
			return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
		};
	}

	padd(w) {
		w.padd = function(num) {
			return ("00" + (num)).substr(-2,2);
		}
	}

	matches() {
		const ElementPrototype = window.Element.prototype;

		if (typeof ElementPrototype.matches !== 'function') {
			ElementPrototype.matches = ElementPrototype.msMatchesSelector || ElementPrototype.mozMatchesSelector || ElementPrototype.webkitMatchesSelector || function matches(selector) {
				const elements = (element.document || element.ownerDocument).querySelectorAll(selector);
				let element = this;
				let index = 0;

				while (elements[index] && elements[index] !== element) {
					++index;
				}

				return Boolean(elements[index]);
			};
		}
	}

	closest() {
		const ElementPrototype = window.Element.prototype;

		if (typeof ElementPrototype.closest !== 'function') {
			ElementPrototype.closest = function closest(selector) {
				let element = this;

				while (element && element.nodeType === 1) {
					if (element.matches(selector)) {
						return element;
					}

					element = element.parentNode;
				}

				return null;
			};
		}
	}

}


export class Velocity {
	constructor() {
		this.positionQueue = []
		this.timeQueue = []
	}

	reset() {
		this.positionQueue.splice(0)
		this.timeQueue.splice(0)
	}
	pruneQueue(ms) {
		//pull old values off of the queue
		while(this.timeQueue.length && this.timeQueue[0] < (Date.now() - ms)) {
			this.timeQueue.shift()
			this.positionQueue.shift()
		}
	}

	updatePosition(position) {
		this.positionQueue.push(position)
		this.timeQueue.push(Date.now())
		this.pruneQueue(50)
	}
	getVelocity() {
		this.pruneQueue(1000)
		var length = this.timeQueue.length
		if(length < 2) return 0

		var distance = this.positionQueue[length-1] - this.positionQueue[0]
			, time = (this.timeQueue[length-1] - this.timeQueue[0]) / 1000

		return distance / time
	}
}


export const requestAnimFrame = (function() {
	return  window.requestAnimationFrame       ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame    ||
		window.oRequestAnimationFrame      ||
		window.msRequestAnimationFrame     ||
		function(/* function */ callback){
			window.setTimeout(callback, 1000 / 60);
		};
})();
export const requestInterval = function(fn, delay) {
	if( !window.requestAnimationFrame       &&
		!window.webkitRequestAnimationFrame &&
		!(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
		!window.oRequestAnimationFrame      &&
		!window.msRequestAnimationFrame)
		return window.setInterval(fn, delay);

	var start = new Date().getTime(),
		handle = new Object();

	var requestAnimFrame = window.requestAnimationFrame       ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame    ||
		window.oRequestAnimationFrame      ||
		window.msRequestAnimationFrame     ||
		function(/* function */ callback){
			window.setTimeout(callback, 1000 / 60);
		};

	function loop() {
		var current = new Date().getTime(),
			delta = current - start;

		if(delta >= delay) {
			fn.call();
			start = new Date().getTime();
		}

		handle.value = requestAnimFrame(loop);
	}

	handle.value = requestAnimFrame(loop);
	return handle;
}
export const clearRequestInterval = function(handle) {
	window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
		window.webkitCancelAnimationFrame ? window.webkitCancelAnimationFrame(handle.value) :
			window.webkitCancelRequestAnimationFrame ? window.webkitCancelRequestAnimationFrame(handle.value) : /* Support for legacy API */
				window.mozCancelRequestAnimationFrame ? window.mozCancelRequestAnimationFrame(handle.value) :
					window.oCancelRequestAnimationFrame	? window.oCancelRequestAnimationFrame(handle.value) :
						window.msCancelRequestAnimationFrame ? window.msCancelRequestAnimationFrame(handle.value) :
							clearInterval(handle);
};