<template>
	<transition name="fade" @enter="$stores.app.blurAll" @leave="$stores.app.focusAll" v-on:after-leave="afterLeave">
		<div v-if="show" class="fixed inset-0 flex items-center justify-center h-full">
			<div class="bg-darken-5 fixed inset-0"></div>

			<div class="z-40 flex flex-col bg-shade-2 sm:mx-6 bg-shade-2 rounded ani-height" :style="`max-width:350px;`">

				<div class="toolbar -small rounded-t" ref="toolbar">
					<h4 class="text-h3 font-heading mt-2 text-shade-0 text-center">hello</h4>
				</div>

				<div class="overflow-auto flex-auto h-full sm:h-auto">
					<div class="h-full sm:h-auto" ref="content">

						<div class="bg-shade-0 h-full flex flex-col p-0">
							<div class="p-2 px-4 pb-1 flex-auto"
							     v-if="content" v-html="content">
							</div>
						</div>

					</div>
				</div>
				<div ref="footer" v-if="footer" v-html="footer"></div>
				<div v-if="footerComponent" :is="footerComponent"></div>
			</div>

		</div>
	</transition>
</template>

<script>
	window.showInlineModal = (object)=>{
		let event = new CustomEvent('showInlineModal', {
			bubbles: true,
			detail: object || {}
		});
		document.dispatchEvent(event);
	};

	window.closeInlineModal= ()=>{
		let event = new CustomEvent('closeInlineModal', {
			bubbles: true,
		});
		document.dispatchEvent(event);
	};






	export default {
		name: 'inline-modal-controller',
		data() {
			return {
				show: false,
				title: "",
				content: "",
				footer: "",
				footerComponent: false
			}
		},
		mounted(){
			document.addEventListener('showInlineModal', (e)=>{
				this.open(e.detail);
			}, false);

			document.addEventListener('closeInlineModal', ()=>{
				this.close();
			}, false);
		},

		methods:{
			open(obj){
				console.log(obj.footerComponent);
				this.show = true;
				this.title = obj.title;
				this.content = obj.content;
				this.footer = obj.footer;
				this.footerComponent = obj.footerComponent;
			},
			close(){
				this.show = false;
			},
			afterLeave(){
				this.title = false;
				this.content = false;
				this.footer = false;
				this.footerComponent = false;
			}
		}
	}
</script>