<template>
	<div class="h-list_dropdown" :class="{'active':toggle}">
		<div class="flex items-center pl-4 pr-2 cursor-pointer" @click="toggle = !toggle">
			<svg-icon v-if="icon" :name="icon" class="text-current text-shade-0 mr-1 relative" style="width:1.5em;height:1.5em;left:-.15em;"></svg-icon>
			<p class="h-list_dropdown-title" v-text="title"></p>
			<svg-icon v-if="toggle" name="chevron-up" class="text-shade-0" style="width: 20px;height: 20px"></svg-icon>
			<svg-icon v-if="!toggle" name="chevron-down" class="text-shade-0" style="width: 20px;height: 20px"></svg-icon>
		</div>
		<div class="h-list_dropdown-content" v-if="toggle">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import SvgIcon from "./SvgIcon";
	export default {
		name: 'h-list',
		components: {SvgIcon},
		data() {
			return {
				toggle: false
			}
		},
		props: {
			initToggle: {
			},
			title: {
				required: true
			},
			icon: {
				default: false
			}
		},
		mounted() {
			if(this.initToggle) this.toggle = this.initToggle;
		}
	}
</script>
