import Datetime from "./Datetime";

let Timeout = {
	endDate: false,
	warnDate: false,
	warnSeconds: 60,
	timeout_amount: false,
	warnTriggered: false,
	endTriggered: false,

	init(endDateCallback, warnDateCallback) {
		Timeout.endDateCallback = endDateCallback;
		Timeout.warnDateCallback = warnDateCallback;

		Timeout.reset();
		Timeout.start();
	},

	reset() {
		Timeout.timeout_amount = window.store.app.state.config.login_timeout;

		Timeout.endDate  = Datetime.now().addSeconds(Timeout.timeout_amount);
		Timeout.warnDate = Datetime.now().addSeconds(Timeout.timeout_amount - Timeout.warnSeconds);

		Timeout.endTriggered = false;
		Timeout.warnTriggered = false;
	},

	start() {
		setInterval(()=>{
			if(!Timeout.timeout_amount) return;

			let endDiff = Datetime.now().diffMilliseconds(Timeout.endDate);
			let warnDiff = Datetime.now().diffMilliseconds(Timeout.warnDate);

			if(!Timeout.endTriggered && endDiff <= 0 && Timeout.endDateCallback) {
				Timeout.endDateCallback();
				Timeout.endTriggered = true;
			}
			else if (!Timeout.warnTriggered && warnDiff <= 0 && Timeout.warnDateCallback) {
				Timeout.warnDateCallback();
				Timeout.warnTriggered = true;
			}
		}, 2000);
	},

};

window.Timeout = Timeout;

export default Timeout;
