const en = require('./en.json');
const es = require('./es.json');
const nl = require('./nl.json');
const fr = require('./fr.json');
const de = require('./de.json');

const locale_options = {
	en: 'English',
	es: 'Español',
	nl: 'Nederlands',
	de: 'Deutsch',
	fr: 'Français'
}

const locale_keys = [
	'en',
	'es',
	'nl',
	'de',
	'fr'
]

module.exports = {
	locale_options,
	locale_keys,
	en,
	es,
	nl,
	de,
	fr
};
