export class NonBlockingQueue {
	static queue = [];
	static interval = false;

	constructor(taskFunction) {
		NonBlockingQueue.queue.push(taskFunction);

		if(!NonBlockingQueue.interval) {
			NonBlockingQueue.interval = window.setInterval(()=>{
				if(NonBlockingQueue.queue.length > 0) {
					let task = NonBlockingQueue.queue[0];
					task();
					NonBlockingQueue.queue.shift();
				}
			}, 0);
		}
	}
}
