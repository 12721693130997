<template>
	<div>
		<button class="btn -xxs">Click me</button>
		<button class="btn-black -xxs">Click me</button>
		<button class="btn-primary -xxs">Click me</button>
		<button class="btn-grey -xxs">Click me</button>
		<button class="btn-white -xxs">Click me</button>
		<br/><br/>
		<button class="btn -xs">Click me</button>
		<button class="btn-black -xs">Click me</button>
		<button class="btn-primary -xs">Click me</button>
		<button class="btn-grey -xs">Click me</button>
		<button class="btn-white -xs">Click me</button>
		<br/><br/>
		<button class="btn -sm">Click me</button>
		<button class="btn-black -sm">Click me</button>
		<button class="btn-primary -sm">Click me</button>
		<button class="btn-grey -sm">Click me</button>
		<button class="btn-white -sm">Click me</button>
		<br/><br/>
		<button class="btn">Click me</button>
		<button class="btn-black">Click me</button>
		<button class="btn-primary">Click me</button>
		<button class="btn-grey">Click me</button>
		<button class="btn-white">Click me</button>
		<br/><br/>
		<button class="btn -lg">Click me</button>
		<button class="btn-black -lg">Click me</button>
		<button class="btn-primary -lg">Click me</button>
		<button class="btn-grey -lg">Click me</button>
		<button class="btn-white -lg">Click me</button>
		<br/><br/>
		<hr />

		<button class="btn">Click me</button>
		<button class="btn -primary -sm">Click me</button>
		<button class="btn -primary">Click me</button>
		<button class="btn -primary -lg">Click me</button>
		<button class="btn -secondary">Click me</button>
		<div class="bg-primary inline-block p-2">
			<button class="btn -outline-light">Click me</button>
		</div>
		<button class="btn -outline-dark">Click me</button>
		<br />
		<button class="btn -bad">Click me</button>
		<button class="btn -good">Click me</button>
		<button class="btn -info">Click me</button>
		<button class="btn -warning">Click me</button>

		<div class="py-3" style="max-width:300px">
			<a-input name="name" label="Label"></a-input>
			<a-select name="theme" label="Label">
				<option>test</option>
			</a-select>
			<a-textarea label="Label"></a-textarea>
			<a-checkbox name="test" label="This is a checkbox"></a-checkbox>
			<a-radio-list class="my-3" :options="[[0, 'test 2'],[1, 'test 2'], [3, 'test 3']]"></a-radio-list>
			<a-number :min="0" :max="10" :value="0"></a-number>
		</div>

		<div class="container-2 ckeditor-content">
			<h1 class="text-h1">header</h1>
			<h2 class="text-h2">header</h2>
			<h3 class="text-h3">header</h3>
			<h4 class="text-h3">header</h4>
			<h5 class="text-h5">header</h5>
			<h6 class="text-h6">header</h6>

			<blockquote class="blockquote">blockquote</blockquote>
			<blockquote class="blockquote -large">bold blockquote</blockquote>

			<p class="highlight">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque eaque error nam totam. A consequatur esse et eveniet nihil nobis odio officia optio placeat quo. Aliquid eius eligendi molestiae similique!</p>
			<p class="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque eaque error nam totam. A consequatur esse et eveniet nihil nobis odio officia optio placeat quo. Aliquid eius eligendi molestiae similique!</p>
			<p class="">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque eaque error nam totam. A consequatur esse et eveniet nihil nobis odio officia optio placeat quo. Aliquid eius eligendi molestiae similique!</p>
			<p class="text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque eaque error nam totam. A consequatur esse et eveniet nihil nobis odio officia optio placeat quo. Aliquid eius eligendi molestiae similique!</p>


			<h1>Projects with a purpose</h1>

			<p class="highlight">Welcome to The <b>Type Nugget Beta</b> - the online typesetting tool that will eventually make all of your online typesetting dreams come true.</p>

			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; <b>it’s all content-editable</b>).</p>

			<p>Click ‘Generate Code’ to generate a tidy, accessible CSS stylesheet that you can implement with zero fiddling. Spending less time manually coding and more time <i>swiftly creating hella fine web type</i> painlessly via a pleasant user interface? I don’t know about you, but I am all about that life.</p>

			<blockquote class="blockquote font-bold" cite="#">
				If you're not told you're crazy, you're not thinking big enough. Crazy is a compliment.
			</blockquote>

			<blockquote class="blockquote" cite="#">
				If you're not told you're crazy, you're not thinking big enough. Crazy is a compliment.
			</blockquote>

			<blockquote class="blockquote text-primary -large" cite="#">
				If you're not told you're crazy, you're not thinking big enough. Crazy is a compliment.
			</blockquote>

			<blockquote class="blockquote secondary" cite="#">
				If you're not told you're crazy, you're not thinking big enough. Crazy is a compliment.
				<footer><a href="#">Linda Rottenberg</a></footer>
			</blockquote>

			<p>Click ‘Generate Code’ to generate a tidy, accessible CSS stylesheet that you can implement with zero fiddling. Spending less time manually coding and more time <i>swiftly creating hella fine web type</i> painlessly via a pleasant user interface? I don’t know about you, but I am all about that life.</p>

			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; <b>it’s all content-editable</b>).</p>

			<hr />

			<p>Create a free login and we’ll save your theme every time you generate, so when you log in you can pick up where you left off.</p>

			<p>Type Nugget has been built on the input from the community, and we want to make it the best web tool that we possibly can for you -
				<a href="#">take our two minute survey</a> and tell us what you what you’d love to see. Oh yeah, you'll need to right click on that link to open it.</p>

			<h2>H2: What does Type Nugget do so far?</h2>
			<p>Type Nugget Beta currently features the following:</p>

			<ol>
				<li>A live browser preview so you can design a pixel-perfect stylesheet</li>
				<li>Generate tidy, usable CSS</li>
				<li>Heaps more webfonts
					<ol>
						<li>Breakpoints for responsive styles</li>
						<li>Browse and load from galleries of user-created themes</li>
						<li>Finer controls and UI improvements</li>
					</ol>
				</li>
				<li>Save your progress when you create a free login</li>
			</ol>

			<h3>H3: Be part of the Type Nugget Revolution</h3>
			<p>We’re really excited about the future. Fully responsive out of the gate, and built with relative units, Type Nugget in its purest form will generate semantically sound, browser compatible, maintainable, and accessible LESS, SASS, or CSS ready to download or link directly to your site.</p>

			<h4>H4: What’s still to come?</h4>

			<p>We are pretty excited to have the following in development:</p>

			<ul>
				<li>Breakpoints for responsive styles</li>
				<li>Browse and load from galleries of user-created themes</li>
				<li>Finer controls and UI improvements</li>
				<li>Heaps more webfonts
					<ul>
						<li>Breakpoints for responsive styles</li>
						<li>Browse and load from galleries of user-created themes</li>
						<li>Finer controls and UI improvements</li>
					</ul>
				</li>
				<li>Your ideas here...</li>
			</ul>

			<h5>H5: This sounds fantastic, but what can I do to make it more fantastic?</h5>

			<p>My thoughts exactly. What’s great about being in Beta is that it’s the perfect time for you and we’re asking for your ideas to help us make Type Nugget even crispier.</p>

			<table>
				<thead>
				<tr>
					<td>name</td>
					<td>Number</td>
					<td>Country</td>
					<td>Score</td>
				</tr>
				</thead>
				<tr>
					<td>James</td>
					<td>140</td>
					<td>Africa</td>
					<td>432432432</td>
				</tr>
				<tr>
					<td>Jon</td>
					<td>233</td>
					<td>Corfu</td>
					<td>43343242</td>
				</tr>
				<tr>
					<td>Peter</td>
					<td>283</td>
					<td>America</td>
					<td>548289</td>
				</tr>
				<tr>
					<td>Luke</td>
					<td>553</td>
					<td>Russia</td>
					<td>95438</td>
				</tr>
			</table>

			<p>Give it a whirl and <a href="#">send us any thoughts you may have</a>! We’d love to hear your ideas on anything from usability, to new dev features, webfonts, or your favourite late-night hot sandwich recipes, etc. We’ll get back to you, and for every good idea that comes our way we will think of you while humming the tune of the classic Golden Girls theme song ‘Thank you for being a friend’.</p>

			<h6>H6: Get your (oven) mitts on</h6>

			<p>That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget of CSS.</p>


			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; it’s all content-editable).</p>
			<p><img class="wysiwyg-left" src="http://loremflickr.com/320/240/pattern" alt=""></p>
			<p>That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget. I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget of CSS. That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget. I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget of CSS.</p>
			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; it’s all content-editable).</p>
			<p>That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget of CSS.</p>
			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; it’s all content-editable).</p>
			<p><img class="wysiwyg-right" src="http://loremflickr.com/320/240/pattern" alt=""></p>
			<p>That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget of CSS.</p>
			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; it’s all content-editable).</p>
			<p>That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget of CSS.</p>
			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; it’s all content-editable).</p>
			<p>That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget.</p>
			<p><img class="wysiwyg-center" src="http://loremflickr.com/1300/500/pattern" alt=""></p>
			<p>Configure some properties in the panel on the right and it’ll change this preview text live in browser (also go ahead and type in these paragraphs; it’s all content-editable).</p>
			<p>That’s it, I’ve fulfilled my preview text panel filler-text quota. So now there’s no time to lose - get around it already and see how easy it is to bake yourself a piping hot nugget of CSS.</p>
		</div>
	</div>
</template>

<script>
import ARadioList from "@/components/inputs/ARadioList";
import ANumber from "@/components/inputs/ANumber";
import ACheckbox from "@/components/inputs/ACheckbox";
import AInput from "@/components/inputs/AInput";
import ASelect from "@/components/inputs/ASelect";
import ATextarea from "@/components/inputs/ATextarea";
export default {
	name:'styles',
	components: {ATextarea, ASelect, AInput, ACheckbox, ANumber, ARadioList}
}
</script>