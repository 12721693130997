<template>
	<div>
		<div v-if="userBookingsRepo.loadingState===-1" class="container-inner">
			<h4 class="text-h3 mt-2" v-t="'bookings.error'"></h4>
		</div>
		<div v-else-if="userBookingsRepo.loadingState===1" class="container-inner">
			<h4 class="text-h3 mt-2" v-t="'bookings.loading'"></h4>
		</div>
		<div v-else-if="userBookingsRepo.loadingState===2 && userBookingsByDateKeys.length===0"
		     class="container-inner py-2">
			<h4 class="text-h3 mt-2" v-t="'bookings.empty'"></h4>
		</div>


		<div v-else-if="userBookingsByDateKeys.length>0" :key="date" v-for="date in userBookingsByDateKeys">
			<div class="container-inner py-2 bg-shade-1 border-t-2 border-b-2 border-shade-2">
				<h5 class="text-h5 top-0 m-0 p-0 text-shade-7" v-text="formatDate(date)"></h5>
			</div>

			<div v-for="(booking, index) in userBookingsByDate[date]" :key="index" :class="`container-inner py-1 border-shade-2 ${index!==userBookingsByDate[date].length-1?'border-b':'mb-4'}`">
				<div class="flex justify-between items-center">
					<div class="flex-auto">
						<template v-if="booking.isAllDay">
							<span v-t="'bookings.all_day'"></span>
						</template>
						<template v-else>
							<span v-text="booking.startTime"></span>
							  -
							<span v-text="booking.endTime"></span>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Page from '@/stores/Page';
	import Datetime from "../Datetime";
	import UserBookingRepo from "../stores/UserBookingRepo";

	export default {
		name: 'bookings',
		data(){
			return {
				page: Page,
				title: this.$t('bookings.title'),
				userBookingsRepo: UserBookingRepo.state
			}
		},
		mounted() {
			this.page.title=this.title;

			UserBookingRepo.getFromServer().then(()=>{
				console.log('test',UserBookingRepo.state);
			});
		},
		computed: {
			userBookingsByDateKeys() {
				return Object.keys(UserBookingRepo.state.userBookingsByDate);
			},
			userBookingsByDate() {
				return UserBookingRepo.state.userBookingsByDate;
			},
		},
		methods: {
			formatDate(date) {
				return Datetime.createFromDateString(date).formatToLongDateString();
			}
		}
	}
</script>
