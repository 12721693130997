<template>
	<div>
		<label class="form-label pb-1" :for="name" v-text="label"></label>
		<textarea v-text="value" class="form-textarea w-full" :id="name" :name="name" v-on="listeners" v-bind="$attrs"></textarea>
		<span class="error-message"></span>
		<!--<span class="error-message" v-if="form.errors.has(name)" v-text="form.errors.get(name)"></span>-->
	</div>
</template>
<script>
	export default {
		name: 'a-textarea',
		inheritAttrs: false,
		props: {
			label:{
				type: String,
				required: true
			},
			name: {
				type: String,
				required: true
			},
			value: {
				type: String
			}
		},
		computed: {
			listeners() {
				return {
					...this.$listeners,
					input: event => this.$emit('input', event.target.value)
				};
			}
		}
	}
</script>
