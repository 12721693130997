<template>
  <transition name="fade" mode="out-in">
    <div
        v-if="$stores.slots.state.slotsLoaded===1||$stores.app.state.loaded===1"
        :style="$breakpoint.val==='xs'?'bottom:4rem':'bottom:0'"
        class="fixed text-center text-base p-1 px-2 font-bold uppercase text-shade-2 bg-darken-4 rounded-full flex items-center">
      <div style="padding-left:2px;top:.5px;" class="relative mr-2">Loading</div>
      <div class="mx-auto xs-loader"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name:'a-loader'
}
</script>