export class User {
	static createGuest() {
		return new User(undefined, undefined, undefined, false, false);
	}

	constructor(email, firstname, lastname, is_admin, is_logged_in) {
		this.email = email;
		this.firstname = firstname;
		this.lastname = lastname;
		this.is_admin = is_admin;

		this.is_logged_in = is_logged_in || false;
	}

	get fullName() {
		if(this.firstname.trim()==='' && this.lastname.trim() === '') {
			return '';
		}
		if(this.firstname.trim()==='') {
			return this.lastname;
		}
		
		return this.firstname + " " + this.lastname;
	}

	get isAdmin() {
		return this.is_logged_in && this.is_admin;
	}

	get isLoggedIn() {
		return this.is_logged_in;
	}

	get isUserOrAdmin() {
		return this.is_logged_in || this.is_admin;
	}

	get isGuest() {
		return !this.is_logged_in && !this.is_admin;
	}

	reset(){
		this.email = undefined;
		this.firstname = undefined;
		this.lastname = undefined;

		this.is_logged_in = false;
		this.is_admin = false;
	}
}