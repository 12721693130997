import Vue from 'vue';
import {SlotBooking} from '../models/SlotBooking';
import {Slot} from "../models/Slot";
import Datetime from "../Datetime";
import {NonBlockingQueue} from "../NonBlockingQueue";
import Calendar from "./Calendar";


let Slots = {

	state: {
		slotsLoaded: 0,
		slotIds: [],
		slots: {}
	},
	
	empty() {
		this.state.slots = {};
		this.state.slotIds = [];
	},
	
	get slotKeys() {
		return Object.keys(Slots.state.slots);
	},

	findIdBySlotTimestamp(timestamp, id) {
		let result = Slots.find(timestamp);
		if( !Slots.find(timestamp) || result.bookings.length <= 0 ) return false;

		let r = result.bookings.find((book)=>book.id === id);

		if (!r) return false;
		return r;
	},

	find(timeStamp) {
		let slot = Slots.state.slots[timeStamp];
		if(slot) return slot;
		else return false;
	},


	findOrCreate(timestamp) {
		let slot = this.find(timestamp);
		if (!slot) return Vue.set(Slots.state.slots, timestamp, Slot.createEmptySlot(timestamp));

		return slot;
	},


	getFromServer(start_date, end_date, beforeParseCallback) {
		clearTimeout(this.timer);
		this.state.slotsLoaded = 1;

		// Make sure we don't get any events that are out of calendar scope bounds
		let scope_start_date = Datetime.createFromEpoch(window.store.calendar.scopeStartDate);
		let scope_end_date   = Datetime.createFromEpoch(window.store.calendar.scopeEndDate);

		if(Datetime.createFromServerString(start_date).isBefore(scope_start_date)) {
			start_date = scope_start_date.formatToDatetimeString();
		}
		if(Datetime.createFromServerString(end_date).isAfter(scope_end_date)) {
			end_date = scope_end_date.formatToDatetimeString();
		}

		console.log('getSlotFormServer', start_date, end_date);
		return new Promise((resolve, reject)=>{
			window.apiFetcher.get('/events', {
				start_time: start_date,
				end_time: end_date
			})
			.then((data)=>{
				if(beforeParseCallback) beforeParseCallback();

				this.removeTimeStampBookingsAndAttributesBetween(start_date, end_date);

				this.timer = setTimeout(()=>{
					this.state.slotsLoaded = 2;
				}, 1000);

				if(data) Slots.parseEventsToTimeStamp(data);

				// TODO work out if this is needed or if this should be in an else statement with the above statement. notice removeTimeStampBookingsAndAttributesBetween and parseEventsToTimeStamp
				Calendar.event.$emit('force-redraw');
				resolve();
			}).catch((error)=>{
				console.log(error);
				this.state.slotsLoaded = -1;
				reject(error);
			});
		});
	},


	parseEventsToTimeStamp(data) {
		console.log('parseEventsToTimeStamp',data);
		console.time('process-slot-data');
		for(let eventIndex=0; eventIndex < data.length; eventIndex++) {
			let event = data[eventIndex];

			// check if event already exists
			if(Slots.state.slotIds.indexOf(event.id) === -1) Slots.state.slotIds.push(event.id);

			Slots.slotsBetween(event.start_time, event.end_time, (slot)=>{

				// add event or slot attribute to timestamp
				if(event.is_slotattributes) {
					
					// Unavailability should always "trump" any other slot setting events.
					// BE CAREFUL with EDITING an existing slot attribute if is_unavailable is already true
					// as any change will not work due to the IF statement below.
					// To change an existing slot that is_unavailable you need to at least set is_unavailable=false first.
					if(slot.slotattributes.is_unavailable === false || event.is_unavailable === true) {
						slot.slotattributes.id                  = event.id;
						slot.slotattributes.start_time          = event.start_time;
						slot.slotattributes.end_time            = event.end_time;
						slot.slotattributes.is_exclusivable     = event.is_exclusivable;
						slot.slotattributes.theme               = event.theme;
						slot.slotattributes.slot_id             = event.slot_id;
						slot.slotattributes.recur_count         = event.recur_count;
						slot.slotattributes.recur_type          = event.recur_type;
						slot.slotattributes.is_unavailable      = event.is_unavailable;
					}
				}
				else {
					//delete existing event and add new event
					slot.removeBookingById(event.id);

					slot.bookings.push(
						new SlotBooking(
							event.id,
							event.start_time,
							event.end_time,
							event.is_owner,
							event.name,
							event.is_anonymous,
							event.is_exclusive,

							event.slot_id,
							event.recur_type,
							event.recur_count,
							event.email,
							event.mobile
						)
					);
				}
			});
		}
		console.timeEnd('process-slot-data');

		new NonBlockingQueue(()=>Calendar.event.$emit('force-redraw'));
	},


	removeBookingAttributesByIds(ids) {
		Slots.slotKeys
			.filter((a)=>ids.find((b)=>b === Slots.state.slots[a].slotattributes.id))
			.map(c=>Slots.state.slots[c].resetAttributes());
	},


	removeTimeStampBookingsAndAttributesBetween(start_date, end_date) {

		start_date = Datetime.createFromServerString(start_date).toNearestMinute(30).formatToNumber();
		end_date = Datetime.createFromServerString(end_date).toNearestMinute(30).formatToNumber();

		let sorted = Slots.slotKeys
			.map(timestamp=>Number(timestamp.replace(/[:|-]/g,'')))
			.sort((a,b)=> a - b)
			.filter((timestamp)=>{
				return timestamp >= start_date && timestamp <= end_date;
			});

		sorted.forEach((timestamp)=>{
			let slot = Slots.find(timestamp);
			if(slot) slot.reset();

			if(Slots.state.slotIds.indexOf(slot.id) !== -1) {
				Slots.state.slotIds.splice(Slots.state.slotIds.indexOf(slot.id), 1);
			}
		});

	},


	bookingCreate(data) {
		return new Promise((resolve, reject)=>{
			window.apiFetcher.post('book', data)
				.then((data)=>{
					this.parseEventsToTimeStamp(data);
					resolve(data);
				})
				.catch((err)=>{
					console.log('err',err);
					reject(err);
				})
		});
	},


	bookingUpdate(booking) {
		return new Promise((resolve, reject)=>{
			window.apiFetcher.post('/edit', booking)
				.then((data)=>{
					// remove booking first as the duration maybe shorter which would result in slots with deleted bookings left in them.
					Slots.removeBookingsByIds([booking.id]);
					
					this.parseEventsToTimeStamp(data.events);
					resolve(data);
				})
				.catch((err)=>{
					console.log('err',err);
					reject(err);
				});
		});
	},


	bookingDelete(booking, deleteType) {
		return new Promise((resolve, reject)=>{
			if(deleteType && !(deleteType==='this'||deleteType==='following'||deleteType==='all')) {
				console.error('onSubmitDelete requires a valid delete type.');
				reject('onSubmitDelete requires a valid delete type.');
				return;
			}

			let obj = { id: booking.id };
			if(deleteType!=='this') obj.with = deleteType;

			window.apiFetcher.post('remove', obj)
				.then((data)=>{
					Slots.removeBookingsByIds(data.removed);
					resolve();
				})
				.catch((err)=>{
					console.log('err',err);
					reject(err);
				})
		});
	},


	removeBookingsByIds(ids) {
		console.time('removeBookingById');

		Slots.slotKeys.forEach((timestamp)=>{
			let slot = this.find(timestamp);
			slot.removeBookingsByIdArray(ids);
		});
		new NonBlockingQueue(()=>window.store.calendar.event.$emit('force-redraw'));

		console.timeEnd('removeBookingById');
	},



	slotsBetween(start, end, cb) {
		// when we are parsing events we want to put it inside a queue so that it doesn't block page rendering.
		// this method does slow down computation but it means we have faster page loading times AND better performance on slow devices.
		// which mean less lag/jankiness.
		new NonBlockingQueue(()=>{
			let interval = window.store.app.state.slotlengthmins_number;
			let startTimestamp = Datetime.createFromServerString(start).toNearestMinute(interval);
			let endTimestamp = Datetime.createFromServerString(end).toNearestMinute(interval);
			let currentTimestamp = startTimestamp.clone();
			
			let slots = (startTimestamp.diffMinutes(endTimestamp) / interval);
			
			for (let i = 0; i < slots; i++) {
				let timestamp = currentTimestamp.formatToTimestampString();
				cb(Slots.findOrCreate(timestamp), timestamp);
				currentTimestamp.addMinutes(interval);
			}
		});
	}
};


export default Slots;
