<template>
	<div class="container-inner py-6 mb-6">
		<div>
			<div v-if="userRepoForm.notificationLoaded===-1">
				<h3 class="text-h3 mt-2" v-t="'notifications.error'"></h3>
			</div>

			<div v-else-if="userRepoForm.notificationLoaded===1">
				<h4 class="text-h3 mt-2" v-t="'notifications.loading'"></h4>
			</div>

			<form v-else-if="userRepoForm.notificationLoaded===2" @submit.prevent="saveToServer" class="space-y-1">
				<a-checkbox v-for="notification in notifications"
				            :key="notification.legend"
				            :name="notification.legend"
				            :label="notification.description"
				            v-model="notification.subscribed"
				            >
				</a-checkbox>

				<br />

				<button class="btn-primary w-full" type="submit"
				        :disabled="userRepoForm.notificationSaveLoaded===1"
				        v-text="btnText">
				</button>
			</form>
		</div>
	</div>
</template>


<script>
	import Page from '@/stores/Page';
	import ACheckbox from "../components/inputs/ACheckbox";
	import UserRepo from "../stores/UserRepo";

	export default {
		components: {ACheckbox},
		name: 'user-notifications',
		data(){
			return {
				page: Page,
				title: this.$t('notifications.title'),
				notifications: [],
				userRepoForm: UserRepo.form
			}
		},

		mounted() {
			this.page.title=this.title;
			UserRepo.form.notificationSaveLoaded = 0;

			UserRepo.getNotifications().then((result)=>{
				console.log('result.notifications',result.notifications);
				this.notifications = result.notifications.filter((notification)=>{
					return (UserRepo.user.is_admin === notification.is_admin) || UserRepo.user.is_admin;
				});
			});

			console.log('this.notifications',this.notifications);
		},

		computed: {
			btnText() {
				let txt = '';
				switch(UserRepo.form.notificationSaveLoaded) {
					case (-1): txt = this.$t('notifications.submit_state.error'); break;
					case (0): txt = this.$t('notifications.submit_state.default'); break;
					case (1): txt = this.$t('notifications.submit_state.loading'); break;
					case (2): txt = this.$t('notifications.submit_state.loaded'); break;
				}
				return txt;
			}
		},

		methods: {
			saveToServer() {
				let notificationsToSave = {};
				this.notifications.forEach(n=>notificationsToSave[n.legend] = n.subscribed?1:0);

				UserRepo.setNotifications(notificationsToSave)
					.then(()=>{
						this.$router.push('/');
						this.$toast.good({
							body: this.$t('notifications.success')
						})
					});
			}
		}
	}
</script>
