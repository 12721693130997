import Datetime from "../Datetime";

export class SlotAttribute {
	static createEmpty() {
		return new SlotAttribute(undefined, undefined, undefined, undefined, false, undefined);
	}

	constructor(id, start_time, end_time, is_exclusivable, is_unavailable, theme, slot_id, recur_count, recur_type) {
		this.end_time = end_time;
		this.id = id;
		this.is_exclusivable = is_exclusivable || false;

		this.is_unavailable_private = false;
		this.is_unavailable = is_unavailable || false;

		this.start_time = start_time;
		this.theme = Number(theme) || 0;

		this.recur_type_private = 0;
		this.recur_type = Number(recur_type) || 0;

		this.recur_count = Number(recur_count) || 0;
		this.duration = window.store.app.state.slotlengthmins_number;
		this.slot_id = slot_id;

		if(this.start_time && this.end_time) this.setDuration();

		this.savedState = 0;
		this.deleteState = 0;
	}

	get is_unavailable() {
		return this.is_unavailable_private;
	}

	set is_unavailable(val) {
		if(val===true) {
			this.is_exclusivable = false;
			this.theme = 0;
		}
		this.is_unavailable_private = val;
	}

	get recur_type() {
		return this.recur_type_private;
	}

	set recur_type(val) {
		if(val === 0) this.recur_count = 0;
		this.recur_type_private = Number(val);
	}

	get is_recurring() {
		console.log('is_recurring',this.slot_id, this.recur_type);
		return this.recur_type === 1
			|| this.recur_type === 2
			|| this.slot_id !== undefined;
	}

	setDuration() {
		if(!this.end_time || !this.start_time) {
			console.error('SlotAttribute cant setDuration due to missing end_time or start_time');
			return;
		}

		this.duration = Datetime.createFromServerString(this.start_time).diffMinutes(Datetime.createFromServerString(this.end_time));
	}

	get toObject() {
		let obj = {
			id: this.id || false,
			duration: this.duration,
			start_time: this.start_time,
			end_time: this.end_time,
			unavailable: this.is_unavailable?1:0,
			exclusivable: this.is_exclusivable ? 1 : 0
		};

		if(!this.is_unavailable) {
			if(this.theme) obj.theme = Number(this.theme);
		}

		if(Number(this.recur_type) > 0) {
			obj.recur_type = this.recur_type;
			if(Number(this.recur_count) > 0) obj.recur_count = this.recur_count;
		}

		return JSON.parse(JSON.stringify(obj));
	}


	safeClear() {
		// mainly for Slot settings where the user can clear the form
		this.is_unavailable = false;
		this.is_exclusivable = false;
		this.theme = 0;
		this.duration = window.store.app.state.slotlengthmins_number;
		this.recur_type = 0;
		this.recur_count = 0;
	}


	get is_cleared() {
		// mainly to check if the Slot settings form is cleared
		return this.is_unavailable === false
			&& this.is_exclusivable === false
			&& this.theme === 0;
	}


	remove(deleteType) {
		return new Promise((resolve, reject)=>{
			if(deleteType && !(deleteType==='this'||deleteType==='following'||deleteType==='all')) {
				console.error('onSubmitDelete requires a valid delete type.');
				reject('onSubmitDelete requires a valid delete type.');
				return;
			}

			let obj = { id: this.id };
			if(deleteType!=='this') obj.with = deleteType;

			this.deleteState = 1;

			window.apiFetcher.post('/remove', obj)
				.then((data) => {
					window.store.slots.removeBookingAttributesByIds(data.removed);

					console.log("deleteSlotAttribute", data);
					this.deleteState = 2;
					resolve(data);
				})
				.catch((error) => {
					console.log("setSlotAttributesERROR", error);
					this.deleteState = -1;
					reject(error);
				});
		});
	}



	saveToServer() {
		// check if values are cleared and exists
		if(this.is_cleared) {
			if(this.id) return this.remove();
			return new Promise((resolve) => resolve());
		}

		let slot = this.toObject;
		console.log("SAVE", this.is_cleared, this.id, this, slot);

		this.savedState = 1;
		let url, data;

		// check if slot has existing attributes if so update else then book
		if (!slot.id) {
			url = '/book';
			data = {
				attributes: 1,
				start_time: slot.start_time,
				duration: slot.duration,
				unavailable: slot.unavailable,
				exclusivable: slot.exclusivable,
				theme: slot.theme,
			};
			if(slot.recur_type > 0) {
				data.recur_type = slot.recur_type;
				if(Number(slot.recur_count) > 0)
					data.recur_count = Math.round(slot.recur_count);
			}
		}
		else {
			url = '/edit';
			data = {
				id: slot.id,
				duration: slot.duration,
				unavailable: slot.unavailable,
				exclusivable: slot.exclusivable,
				theme: slot.theme,
			};
		}

		return new Promise((resolve, reject) => {
			window.apiFetcher.post(url, data)
				.then((result) => {
					
					// If a slot is already unavailable then setting the slot to something else will not work unless we
					// at least set is_unavailable=false.
					// Below we are simply removing the slot(s) before the new slot(s) gets updated via parseEventsToTimeStamp.
					if(url==='/edit') {
						window.store.slots.removeBookingAttributesByIds(result.events.map((slot)=>slot.id));
					}
					
					window.store.slots.parseEventsToTimeStamp(url==='/edit'?result.events:result);

					this.savedState = 2;
					console.log("setSlotAttributes", result);
					resolve(result);
				})
				.catch((error) => {
					this.savedState = -1;
					console.log("setSlotAttributesERROR", error);
					reject(error);
				});
		});
	}
}